import {
  Button,
  HStack,
  Icon,
  Spacer,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import copy from 'copy-to-clipboard';

import type { ServerOrganizationEnvironment } from '../../types/Api';
import { Icons } from '../../components/Icons';
import { useShowMessage } from '../../components/useShowMessage';
import { useCreateOrganizationAPIKey } from '../../api/organizationApi';
import { EnvironmentBadge } from '../../components/EnvironmentBadge';

export function OrganizationAPIKeyEdit(props: {
  orgApiKey: ServerOrganizationEnvironment;
  onChange: () => void;
}) {
  const showMessage = useShowMessage();
  const { orgApiKey, onChange } = props;

  const [createOrganizationApiKey] = useCreateOrganizationAPIKey();
  // Organization API keys.
  const [showAPIKey, setShowAPIKey] = useState(false);
  const toggleShowAPIKey = () => setShowAPIKey(!showAPIKey);

  const { id, environment, org_id, api_key } = orgApiKey;
  const showHideLabel = t(`Show/Hide "{environment}" API key`, {
    environment,
  });
  const copyToClipboardLabel = t(`Copy "{environment}" API key to clipboard`, {
    environment,
  });
  const copiedToClipboardLabel = t(
    `Copied "{environment}" API key to clipboard!`,
    {
      environment,
    },
  );
  const rotateAPIKeyLabel = t(`Rotate "{environment}" API key`, {
    environment,
  });

  const rotatedAPIKeyLabel = t(`Rotated "{environment}" API key!`, {
    environment,
  });
  return (
    <Tr key={id}>
      <Td>
        <EnvironmentBadge environment={environment ?? 'Development'} />
      </Td>
      <Td textAlign="end">
        <HStack>
          <Spacer />
          <Text>{showAPIKey ? api_key : '﹡'.repeat(60)}</Text>
          <Tooltip label={showHideLabel} placement="top" hasArrow={true}>
            <Button variant="link">
              <Icon
                as={showAPIKey ? Icons.EyeSlash : Icons.Eye}
                w={5}
                h={5}
                color="gray.500"
                onClick={toggleShowAPIKey}
              />
            </Button>
          </Tooltip>
          <Tooltip label={copyToClipboardLabel} placement="top" hasArrow={true}>
            <Button
              variant="link"
              onClick={() => {
                copy(api_key);
                showMessage({
                  type: 'success',
                  message: copiedToClipboardLabel,
                });
              }}
            >
              <Icon as={Icons.DocumentDuplicate} w={5} h={5} color="gray.500" />
            </Button>
          </Tooltip>
          <Tooltip label={rotateAPIKeyLabel} placement="top" hasArrow={true}>
            <Button
              variant="link"
              onClick={async () => {
                await createOrganizationApiKey(org_id, {
                  environment: environment ?? '',
                });
                onChange();
                showMessage({
                  type: 'success',
                  message: rotatedAPIKeyLabel,
                });
              }}
            >
              <Icon as={Icons.ArrowPath} w={5} h={5} color="gray.500" />
            </Button>
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
