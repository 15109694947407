import { toHeaderFieldArray, HeaderDictRuntype } from '../../support/schema';

// Maybe not needed?
export function parseHeaders(input: string | undefined) {
  const result: unknown = input ? parseJson(input) : null;
  return HeaderDictRuntype.guard(result) ? toHeaderFieldArray(result) : [];
}

export function parseJson<T = JSONValue>(input: string): T {
  try {
    return JSON.parse(input);
  } catch (e) {
    return null as any;
  }
}
