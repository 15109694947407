import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spacer,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Tab, TabView } from '../../components/TabView';
import {
  useFetchOrganization,
  useUpdateOrganization,
  useDeleteOrganization,
  useDeleteOrganizationRole,
  useUpdateOrganizationRole,
} from '../../api/organizationApi';
import type {
  ServerOrganizationEnvironment,
  ServerOrganization,
  ServerOrganizationRole,
  ServerUser,
} from '../../types/Api';
import { Icons } from '../../components/Icons';
import { MainLayout } from '../../components/MainLayout';
import { formatTooltip } from '../../support/formatTooltip';
import { useShowMessage } from '../../components/useShowMessage';
import { useShowConfirmation } from '../../components/ConfirmationDialog';
import { NotFound } from '../NotFound';
import { EmptyTableView } from '../../components/EmptyTableView';

import { OrganizationEditDrawer } from './OrganizationEditDrawer';
import { OrganizationRoleEditDrawer } from './OrganizationRoleEditDrawer';
import { OrganizationAPIKeyEdit } from './OrganizationAPIKeyEdit';
import { OrganizationMembersEdit } from './OrganizationMembersEdit';
import { OrganizationRoleMemberNewModal } from './OrganizationRoleMemberNewModal';
import { OrganizationEnvironmentPermissionEdit } from './OrganizationEnvironmentPermissionEdit';
import { OrganizationRoleNewModal } from './OrganizationRoleNewModal';

export function OrganizationEdit() {
  const [editOrganization, setEditOrganization] =
    useState<ServerOrganization | null>(null);
  const [newOrganizationRole, setNewOrganizationRole] =
    useState<ServerOrganization | null>(null);
  const [editOrganizationRole, setEditOrganizationRole] =
    useState<ServerOrganizationRole | null>(null);
  const [addOrganizationRoleMember, setAddOrganizationRoleMember] =
    useState<ServerOrganizationRole | null>(null);
  const { id } = useParams();
  const [activeId, setActiveId] = useState(id);
  const navigate = useNavigate();
  const showMessage = useShowMessage();
  const showConfirmationDialog = useShowConfirmation();

  // Organization.
  const [updateOrganization] = useUpdateOrganization();
  const [deleteOrganization] = useDeleteOrganization();

  // Organization Roles.
  const [updateOrganizationRole] = useUpdateOrganizationRole();
  const [deleteOrganizationRole] = useDeleteOrganizationRole();

  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.500', 'gray.200');

  let [data, { isLoading, refetch }] = useFetchOrganization(activeId ?? '');

  useEffect(() => {
    if (activeId !== id) {
      const destination = `/organizations/${id}`;
      setActiveId(id);
      navigate(destination, { replace: true });
    } else {
      // TODO(RUL-352): No need for this refetch.
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, activeId]);

  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (!data) {
    return <NotFound />;
  }
  const { organization, orgRoles, orgMembers, roleMembers, orgApiKeys } = data;
  const editOrganizationLabel = t(`Edit "{name}"`, { name: organization.name });
  const deleteOrganizationLabel = t(`Delete "{name}"`, {
    name: organization.name,
  });

  const renderRoles = (roles: Array<ServerOrganizationRole>) => {
    return roles.map((role) => {
      const { id, name, description } = role;
      const orgRoleName = name ?? '';
      const editOrgRoleLabel = t(`Edit "{name}" role`, { name: orgRoleName });
      const deleteOrgRoleLabel = t(`Delete "{name}" role`, {
        name: orgRoleName,
      });
      const addMemberToOrgRoleLabel = t(
        `Add organization member to "{name}" role`,
        {
          name: orgRoleName,
        },
      );
      return (
        <GridItem bg={bg} shadow="sm" borderRadius="lg" py={5} key={id}>
          <VStack align="stretch">
            <HStack px={6}>
              <Heading fontWeight="medium" size="xs" noOfLines={1}>
                <Tooltip
                  label={formatTooltip(description)}
                  placement="top"
                  hasArrow={true}
                >
                  {orgRoleName}
                </Tooltip>
              </Heading>
              <Spacer />
              <Tooltip label={editOrgRoleLabel} placement="top" hasArrow={true}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    setEditOrganizationRole(role);
                  }}
                >
                  <Icon as={Icons.Pencil} w={6} h={6} color={color} />
                </Button>
              </Tooltip>
              <Tooltip
                label={deleteOrgRoleLabel}
                placement="top"
                hasArrow={true}
              >
                <Button
                  variant="ghost"
                  onClick={async () => {
                    showConfirmationDialog({
                      title: deleteOrgRoleLabel + '?',
                      body: t(
                        `Are you sure you would like to delete the "{name}" organization role? This cannot be recovered.`,
                        { name: orgRoleName },
                      ),
                      onConfirm: async () => {
                        await deleteOrganizationRole(id);
                        refetch();
                      },
                    });
                  }}
                >
                  <Icon as={Icons.Trash} w={6} h={6} color={color} />
                </Button>
              </Tooltip>
            </HStack>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="center">{t('Name')}</Th>
                    <Th textAlign="center">{t('Email')}</Th>
                    <Th textAlign="center">{t('Actions')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {renderRoleMembers(role, roleMembers.get(id) ?? [])}
                </Tbody>
                <TableCaption textAlign="right">
                  <Tooltip
                    label={addMemberToOrgRoleLabel}
                    placement="top"
                    hasArrow={true}
                  >
                    <Button
                      variant="solid"
                      onClick={() => {
                        setAddOrganizationRoleMember(role);
                      }}
                    >
                      <Icon as={Icons.UserPlus} w={6} h={6} color={color} />
                    </Button>
                  </Tooltip>
                </TableCaption>
              </Table>
            </TableContainer>
          </VStack>
        </GridItem>
      );
    });
  };
  const renderRoleMembers = (
    role: ServerOrganizationRole,
    roleMembers: Array<ServerUser>,
  ) => {
    return roleMembers.map((roleMember) => {
      const { id, full_name, email } = roleMember;
      const roleMemberName = full_name ? full_name : email ?? 'unknown';
      const removeOrgMemberFromRoleLabel = t(
        `Remove "{name}" from organization role`,
        { name: roleMemberName },
      );
      return (
        <Tr key={id}>
          <Td textAlign="center">{roleMemberName}</Td>
          <Td textAlign="center">{email}</Td>
          <Td textAlign="center">
            <Tooltip
              label={removeOrgMemberFromRoleLabel}
              placement="top"
              hasArrow={true}
            >
              <Button variant="link">
                <Icon
                  as={Icons.UserMinus}
                  color={color}
                  onClick={async () => {
                    showConfirmationDialog({
                      title: t(`Remove "{name}"?`, {
                        name: roleMemberName,
                      }),
                      body: t(
                        `Are you sure you would like to remove "{name}" from the organization role? This cannot be recovered.`,
                        { name: roleMemberName },
                      ),
                      onConfirm: async () => {
                        const response = await updateOrganizationRole({
                          id: role.id,
                          updates: {
                            members: role.members.filter(
                              (member) => member !== id,
                            ),
                          },
                        });
                        if (!response.ok || !response.data) {
                          if (
                            response.status === 404 ||
                            response.status === 422
                          ) {
                            showMessage({
                              type: 'error',
                              message: t(
                                `Invalid user - unable to remove from the "{orgRoleName}" role`,
                                {
                                  orgRoleName: role.name,
                                },
                              ),
                            });
                          } else {
                            showMessage({
                              type: 'error',
                              message: t(
                                'Unexpected response status: {response}',
                                {
                                  response,
                                },
                              ),
                            });
                          }
                        } else {
                          refetch();
                          showMessage({
                            type: 'success',
                            message: t(
                              `Successfully removed from the "{orgRoleName}" role!`,
                              {
                                orgRoleName: role.name,
                              },
                            ),
                          });
                        }
                      },
                    });
                  }}
                />
              </Button>
            </Tooltip>
          </Td>
        </Tr>
      );
    });
  };

  const renderAPIKeys = (orgApiKeys: Array<ServerOrganizationEnvironment>) => {
    return orgApiKeys.map((orgApiKey) => {
      return (
        <OrganizationAPIKeyEdit
          key={orgApiKey.id}
          orgApiKey={orgApiKey}
          onChange={() => {
            refetch();
          }}
        />
      );
    });
  };

  const renderApiKeysSection = (
    orgApiKeys: Array<ServerOrganizationEnvironment>,
  ) => {
    return (
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textAlign="center">{t('Environment')}</Th>
                <Th textAlign="center">{t('API Key')}</Th>
              </Tr>
            </Thead>
            <Tbody>{renderAPIKeys(orgApiKeys)}</Tbody>
          </Table>
        </TableContainer>
      </VStack>
    );
  };

  const renderEnvironmentPermissionsSection = (
    environmentOrgRoles: Array<ServerOrganizationEnvironment>,
  ) => {
    return (
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textAlign="center">{t('Environment')}</Th>
                <Th textAlign="center">
                  {t('Organization Roles with Update Permissions')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {environmentOrgRoles.map((environmentOrgRoles) => {
                return (
                  <OrganizationEnvironmentPermissionEdit
                    key={environmentOrgRoles.id}
                    organization={organization}
                    environmentOrgRoles={environmentOrgRoles}
                    onChange={() => {
                      refetch();
                    }}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    );
  };
  return (
    <MainLayout scrollable={true}>
      <VStack align="stretch" spacing={6}>
        <HStack>
          <Heading fontWeight="medium" size="sm">
            {organization.name}
          </Heading>
          <Tooltip
            label={editOrganizationLabel}
            placement="top"
            hasArrow={true}
          >
            <Button
              variant="ghost"
              onClick={() => {
                setEditOrganization(organization);
              }}
            >
              <Icon as={Icons.Pencil} w={6} h={6} color={color} />
            </Button>
          </Tooltip>
          <Tooltip
            label={deleteOrganizationLabel}
            placement="top"
            hasArrow={true}
          >
            <Button
              variant="ghost"
              onClick={async () => {
                showConfirmationDialog({
                  title: deleteOrganizationLabel + '?',
                  body: t(
                    `Are you sure you would like to delete the "{name}" organization? This cannot be recovered.`,
                    { name: organization.name },
                  ),
                  onConfirm: async () => {
                    await deleteOrganization(organization);
                    refetch();
                    navigate('/organizations');
                  },
                });
              }}
            >
              <Icon as={Icons.Trash} w={6} h={6} color={color} />
            </Button>
          </Tooltip>
        </HStack>
        <TabView tabListProps={{ px: 6 }}>
          <Tab title="Roles">
            <Box>
              {orgRoles.size > 0 ? (
                <Grid templateColumns="repeat(2, 1fr)" gap={6} py={6}>
                  {renderRoles(Array.from(orgRoles.values()))}
                </Grid>
              ) : (
                <EmptyTableView
                  primaryMsg="There are no Roles associated with this organization"
                  secondaryMsg="Create your first Role by clicking the button below"
                />
              )}
              <HStack>
                <Spacer />
                <Button
                  variant="solid"
                  colorScheme="teal"
                  onClick={async () => {
                    setNewOrganizationRole(organization);
                  }}
                >
                  {t('+ Add Role')}
                </Button>
              </HStack>
            </Box>
          </Tab>
          <Tab title="Members">
            <OrganizationMembersEdit
              organization={organization}
              orgMembers={orgMembers}
              onChange={() => {
                refetch();
              }}
            />
          </Tab>
          <Tab title="API Keys">
            <Box border="thin">{renderApiKeysSection(orgApiKeys)}</Box>
          </Tab>
          <Tab title="Settings">
            <Box border="thin">
              {renderEnvironmentPermissionsSection(orgApiKeys)}
            </Box>
          </Tab>
        </TabView>
        <OrganizationEditDrawer
          organization={editOrganization}
          onSave={async (organization) => {
            const { id, name, description } = organization;
            await updateOrganization({
              id: String(id),
              updates: { name: name ?? '', description: description ?? '' },
            });
            setEditOrganization(null);
            refetch();
          }}
          onClose={() => setEditOrganization(null)}
        />
        <OrganizationRoleEditDrawer
          orgRole={editOrganizationRole}
          onSave={async (role) => {
            const { id, name, description, can_read, can_update, can_run } =
              role;
            await updateOrganizationRole({
              id: String(id),
              updates: {
                name: name ?? '',
                description: description ?? '',
                can_read: can_read ?? false,
                can_update: can_update ?? false,
                can_run: can_run ?? false,
              },
            });
            setEditOrganizationRole(null);
            refetch();
          }}
          onClose={() => setEditOrganizationRole(null)}
        />
        <OrganizationRoleNewModal
          organization={newOrganizationRole}
          onClose={() => {
            setNewOrganizationRole(null);
            refetch();
          }}
        />
        <OrganizationRoleMemberNewModal
          orgRole={addOrganizationRoleMember}
          orgMembers={orgMembers}
          onClose={() => {
            setAddOrganizationRoleMember(null);
            refetch();
          }}
        />
      </VStack>
    </MainLayout>
  );
}
