import {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
  useMemo,
  type ReactFragment,
  type ReactElement,
  type ReactNode,
  type RefObject,
} from 'react';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type ModalContentProps,
  type ModalProps,
} from '@chakra-ui/react';
import { type FocusableElement } from '@chakra-ui/utils';

import { EventEmitter } from '../support/EventEmitter';

type Events = {
  submit: [];
};

type FormEmitter = EventEmitter<Events>;

type FormContextType = {
  onSubmit: (listener: () => void) => void;
  initialFocusRef: RefObject<any>;
};

type Props = {
  title: string;
  isOpen: boolean;
  isSubmitting?: boolean;
  contentProps?: ModalContentProps;
  size?: ModalProps['size'];
  submitButtonText?: string;
  onSubmit?: () => void;
  showCancelButton?: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
  closeOnEsc?: boolean;
  children:
    | ((formContext: FormContextType) => ReactNode)
    | ReactElement
    | ReactFragment
    | null;
};

const FormContext = createContext<FormContextType | null>(null);

export function ModalForm(props: Props) {
  const {
    title,
    isOpen,
    isSubmitting,
    contentProps,
    submitButtonText,
    onSubmit,
    showCancelButton = true,
    onClose,
    children,
    ...otherProps
  } = props;

  const [formEmitter] = useState(() => new EventEmitter<Events>());
  const initialFocusRef: RefObject<FocusableElement> = useRef(null);
  const formContext = useInitializeContext(formEmitter, initialFocusRef);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      initialFocusRef={initialFocusRef}
      {...otherProps}
    >
      <ModalOverlay />
      <ModalContent
        {...contentProps}
        as="form"
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (!isSubmitting) {
            formEmitter?.emit('submit');
            onSubmit?.();
          }
        }}
      >
        <ModalHeader>
          <Text>{title}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {isOpen ? (
            <FormContext.Provider value={formContext}>
              {typeof children === 'function'
                ? children(formContext)
                : children}
            </FormContext.Provider>
          ) : null}
        </ModalBody>
        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <HStack>
            {showCancelButton ? (
              <Button variant="outline" onClick={() => onClose()}>
                {t('Cancel')}
              </Button>
            ) : null}
            <Button
              type="submit"
              variant="solid"
              colorScheme="teal"
              isLoading={isSubmitting ?? false}
            >
              {submitButtonText ?? t('Save')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function useInitializeContext(
  formEmitter: FormEmitter,
  initialFocusRef: RefObject<FocusableElement>,
) {
  const listenerRef = useRef<() => void>();
  useEffect(() => {
    return formEmitter.addListener('submit', () => {
      listenerRef.current?.();
    });
  }, [formEmitter]);
  return useMemo<FormContextType>(
    () => ({
      onSubmit: (listener: () => void) => {
        listenerRef.current = listener;
      },
      initialFocusRef,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useFormContext() {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext can only be used within ModalForm');
  }
  return context;
}
