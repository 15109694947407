import { useToast } from '@chakra-ui/react';
import { useMemo } from 'react';

type Options = {
  type: 'error' | 'success';
  message: string;
};

export function useShowMessage() {
  const toast = useToast();
  return useMemo(() => {
    const showMessage = (opts: Options) => {
      return toast({
        title: opts.message,
        status: opts.type,
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    };
    // Copy over some static methods
    showMessage.close = toast.close;
    showMessage.closeAll = toast.closeAll;
    return showMessage;
  }, [toast]);
}
