export const endpoints = {
  add_organization_role_to_environment:
    '/v1/organizations/{org_id}/apikeys/{environment}/add/',
  create_checkout_session: '/v1/billing/checkout/',
  create_customer_portal_session: '/v1/billing/customer-portal/sessions/',
  create_onnx_graph_initializer: '/v1/models/onnx/{model_id}/initializers/',
  create_onnx_graph_node: '/v1/models/onnx/{model_id}/nodes/',
  create_onnx_model: '/v1/models/onnx/',
  create_organization: '/v1/organizations/',
  create_organization_api_key: '/v1/organizations/{org_id}/apikeys/',
  create_organization_role: '/v1/organizations/{org_id}/roles/',
  create_plan: '/v1/plans/',
  create_user: '/v1/users/',
  create_user_personal_api_key: '/v1/users/me/apikeys/',
  create_user_usage: '/v1/billing/usages/',
  delete_a_plan: '/v1/plans/{user_id}',
  delete_onnx_graph_initializer: '/v1/models/onnx/{model_id}/initializers/{id}',
  delete_onnx_graph_node: '/v1/models/onnx/{model_id}/nodes/{id}',
  delete_onnx_model: '/v1/models/onnx/{model_id}',
  delete_organization: '/v1/organizations/{org_id}',
  delete_organization_api_keys: '/v1/organizations/{org_id}/apikeys/',
  delete_organization_role: '/v1/organizations/roles/{id}',
  delete_user_personal_api_key: '/v1/users/me/apikeys/{id}',
  delete_user_usage: '/v1/billing/usages/{user_id}',
  disable_user: '/v1/users/{id}',
  forgot_password: '/v1/forgotpassword/',
  get_a_plan: '/v1/plans/{user_id}',
  get_a_user_usage: '/v1/billing/usages/{user_id}',
  get_all_models: '/v1/models/',
  get_my_plan: '/v1/plans/me/',
  get_onnx_graph_initializers: '/v1/models/onnx/{model_id}/initializers/',
  get_onnx_graph_nodes: '/v1/models/onnx/{model_id}/nodes/',
  get_onnx_model: '/v1/models/onnx/{model_id}',
  get_onnx_models: '/v1/models/onnx/',
  get_organization: '/v1/organizations/{org_id}',
  get_organization_api_keys: '/v1/organizations/{org_id}/apikeys/',
  get_organization_members: '/v1/organizations/{org_id}/members/',
  get_organization_role: '/v1/organizations/roles/{id}',
  get_organization_roles: '/v1/organizations/{org_id}/roles/',
  get_organizations: '/v1/organizations/',
  get_plans: '/v1/plans/',
  get_pytorch_models: '/v1/models/pytorch/',
  get_usage_summary: '/v1/billing/usages/{user_id}/summary/',
  get_user: '/v1/users/{id}',
  get_user_me: '/v1/users/me/',
  get_user_personal_api_keys: '/v1/users/me/apikeys/',
  get_user_usage_me: '/v1/billing/usages/me/',
  get_user_usages: '/v1/billing/usages/',
  get_users: '/v1/users/',
  invite_member_to_organization_by_email:
    '/v1/organizations/{org_id}/members/add/',
  login_for_access_token: '/v1/login/token',
  reenable_user: '/v1/users/{id}/reenable/',
  refresh: '/v1/login/refresh',
  remove_member_from_organization:
    '/v1/organizations/{org_id}/members/{user_id}',
  remove_organization_roles_from_environment:
    '/v1/organizations/{org_id}/apikeys/{environment}/remove/',
  report_user_usage: '/v1/billing/usages/{user_id}/report/',
  resend_email_verify_email: '/v1/emails/verify/resend/',
  reset_password: '/v1/resetpassword/',
  stripe_webhook: '/v1/billing/stripe/webhooks/',
  update_onnx_graph_initializer: '/v1/models/onnx/{model_id}/initializers/{id}',
  update_onnx_graph_node: '/v1/models/onnx/{model_id}/nodes/{id}',
  update_onnx_model: '/v1/models/onnx/{model_id}',
  update_organization: '/v1/organizations/{org_id}',
  update_organization_role: '/v1/organizations/roles/{id}',
  update_plan_by_user_id: '/v1/plans/{user_id}',
  update_superuser_privileges: '/v1/users/{id}/superuser/',
  update_user: '/v1/users/{id}',
  update_user_me: '/v1/users/me/',
  update_user_me_password: '/v1/users/me/password/',
  update_user_usage: '/v1/billing/usages/{user_id}',
  upload_onnx_model_file: '/v1/models/onnx/upload/',
  upload_onnx_model_file_sdk: '/v1/models/onnx/upload/sdk/',
  verify_and_add_org_members: '/v1/organizations/members/add/',
  verify_email_address: '/v1/emails/verify/',
  verify_total_model_uploads_within_billing_quota: '/v1/models/create/verify/',
} as const;
