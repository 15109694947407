import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  VStack,
  Text,
  useColorMode,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useUpdateUserMe } from '../../api/settingsApi';
import { Icons } from '../../components/Icons';
import { SettingsLayout } from '../../components/SettingsLayout';
import { useShowMessage } from '../../components/useShowMessage';
import { useAuth, type User } from '../../support/Auth';

type ColorThemeProps = {
  onClick: () => void;
};

function ColorTheme(props: ColorThemeProps) {
  const { onClick } = props;

  const color = useColorModeValue('gray.600', 'gray.300');
  const buttonColor = useColorModeValue('black', 'white');
  const tooltip = 'Set to ' + useColorModeValue('dark', 'light') + ' mode';

  const currentModeIcon = useColorModeValue(Icons.Moon, Icons.Sun);

  return (
    <Tooltip label={tooltip} placement="top" hasArrow={true}>
      <Button
        variant="outline"
        mr={3}
        _hover={{ color: buttonColor, transform: 'scale(1.01)' }}
        _active={{ color: buttonColor }}
        onClick={onClick}
      >
        <HStack padding={3}>
          <Text>{tooltip}</Text>
          <Icon as={currentModeIcon} w={6} h={6} color={color} />
        </HStack>
      </Button>
    </Tooltip>
  );
}

function AppearanceSettingsEditForm(props: {
  user: User;
  onSave: (user: User) => void;
}) {
  const { user: initialUser, onSave } = props;

  const [user] = useState(initialUser);

  // const [theme, setTheme] = useState('Use system setting');
  const { toggleColorMode } = useColorMode();
  return (
    <VStack
      as="form"
      px={6}
      align="flex-end"
      onSubmit={(event) => {
        event.preventDefault();
        onSave(user);
      }}
    >
      <FormControl>
        <FormLabel>{t('Color Scheme')}</FormLabel>
        <ColorTheme onClick={toggleColorMode} />
      </FormControl>
      <Button type="submit" colorScheme="teal">
        {t('Save Changes')}
      </Button>
    </VStack>
  );
}

export function AppearanceSettings() {
  const { user, setCurrentUser } = useAuth();
  const [updateUser] = useUpdateUserMe();
  const showMessage = useShowMessage();
  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.600', 'gray.300');

  if (!user) {
    return null;
  }

  return (
    <SettingsLayout scrollable={true}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="sm">
            <HStack>
              <Icon as={Icons.PaintBrush} w={6} h={6} color={color} />
              <Text>{t('Appearance')}</Text>
            </HStack>
          </Heading>
        </HStack>
        <AppearanceSettingsEditForm
          user={user}
          onSave={async (user) => {
            const result = await updateUser(user);
            if (result.isError || !result.ok || !result.data) {
              const message = result.error
                ? result.error.message
                : 'Unable to save user';
              showMessage({ type: 'error', message });
            } else {
              const newUser = result.data;
              setCurrentUser(newUser);
              showMessage({
                type: 'success',
                message: t('Appearance settings updated successfully!'),
              });
            }
          }}
        />
      </VStack>
    </SettingsLayout>
  );
}
