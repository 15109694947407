import { useEffect, useState } from 'react';

import { logError, useFetchCore, useMutation } from '../support/Fetch';
import type { ServerPlan, ServerUserBillingInfo } from '../types/Api';
import { endpoints } from '../generated/endpoints';

import type { FetchState } from './helpers/types';

/**
 * Fetches all available plans.
 */
export function useFetchPlans() {
  type Result = {
    userBillingInfo: ServerUserBillingInfo;
    availablePlans: Array<ServerPlan>;
    checkedPlans: Array<boolean>;
    selectedPlan: ServerPlan;
  };
  const [fetchState, setFetchState] = useState<FetchState<Result>>({
    isLoading: true,
    data: null,
    error: null,
  });

  const fetchAvailablePlans = useFetchCore({
    url: endpoints.get_plans,
    responseTransform: (data) => fromServerPlans(data as Array<ServerPlan>),
  });

  const fetchUserBillingInfo = useFetchCore({
    url: endpoints.get_my_plan,
    responseTransform: (data) =>
      fromServerUserBillingInfo(data as ServerUserBillingInfo),
  });

  const fetchAll = async () => {
    const result = await fetchAvailablePlans();
    if (result.isError || !result.ok || !result.data) {
      throw result.error || new Error(`Status: ${result.status}`);
    }

    const userBillingInfoResult = await fetchUserBillingInfo();
    if (
      userBillingInfoResult.isError ||
      !userBillingInfoResult.ok ||
      !userBillingInfoResult.data
    ) {
      throw (
        userBillingInfoResult.error ||
        new Error(`Status: ${userBillingInfoResult.status}`)
      );
    }

    const availablePlans = result.data;
    const userBillingInfo = userBillingInfoResult.data;
    let checkedPlans = Array<boolean>(availablePlans.length).fill(false);
    let selectedPlan = availablePlans[0] as ServerPlan;

    // Iterate over all available plans to find / track the current user's plan.
    availablePlans.forEach((availablePlan, index) => {
      const { product } = availablePlan;
      const { name } = product;
      const planType: string = String(name).toLowerCase().replace('plan', '');

      // If we find that "this" index contains the current user's
      // plan, we'll set it to selected and make sure the checkedPlans
      // are also correct.
      if (planType.includes(userBillingInfo.plan)) {
        selectedPlan = availablePlan;
        checkedPlans[index] = true;
      }
    });

    return { userBillingInfo, availablePlans, checkedPlans, selectedPlan };
  };
  const fetchAndUpdateState = async () => {
    try {
      const data = await fetchAll();
      setFetchState({ isLoading: false, data, error: null });
    } catch (error) {
      logError(error);
      setFetchState({ isLoading: false, data: null, error: String(error) });
    }
  };
  useEffect(
    () => {
      fetchAndUpdateState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { data, isLoading, error } = fetchState;
  // Returning in this way to match the way we do it in useFetch()
  return [data, { isLoading, error, refetch: fetchAndUpdateState }] as const;
}

/**
 * Fetches the current user's billing info.
 * @returns userBillingInfo
 */
export function useFetchMyUserBillingInfo() {
  type Result = {
    userBillingInfo: ServerUserBillingInfo;
  };
  const [fetchState, setFetchState] = useState<FetchState<Result>>({
    isLoading: true,
    data: null,
    error: null,
  });

  const fetchUserBillingInfo = useFetchCore({
    url: endpoints.get_my_plan,
    responseTransform: (data) =>
      fromServerUserBillingInfo(data as ServerUserBillingInfo),
  });

  const fetchAll = async () => {
    const result = await fetchUserBillingInfo();
    if (result.isError || !result.ok || !result.data) {
      throw result.error || new Error(`Status: ${result.status}`);
    }

    const userBillingInfo = result.data;

    return { userBillingInfo };
  };
  const fetchAndUpdateState = async () => {
    try {
      const data = await fetchAll();
      setFetchState({ isLoading: false, data, error: null });
    } catch (error) {
      logError(error);
      setFetchState({ isLoading: false, data: null, error: String(error) });
    }
  };
  useEffect(
    () => {
      fetchAndUpdateState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { data, isLoading, error } = fetchState;
  // Returning in this way to match the way we do it in useFetch()
  return [data, { isLoading, error, refetch: fetchAndUpdateState }] as const;
}

export function useCreateCheckoutSession() {
  return useMutation((plan: string) => {
    return {
      url: endpoints.create_checkout_session,
      method: 'post',
      body: {
        plan,
      },
      // responseTransform: (data) => fromServerDataset(data as ServerDataset),
    };
  });
}

export function useCreateCustomerPortalSession() {
  return useMutation(() => {
    return {
      url: endpoints.create_customer_portal_session,
      method: 'post',
      //   responseTransform: (data) => fromServerDataset(data as ServerDataset),
    };
  });
}

function fromServerPlans(serverPlans: Array<ServerPlan>) {
  return serverPlans;
}

function fromServerUserBillingInfo(userBillingInfo: ServerUserBillingInfo) {
  return userBillingInfo;
}
