import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

export function FlexFill(props: FlexProps) {
  return (
    <Flex
      flex="1"
      alignSelf="stretch"
      justifySelf="stretch"
      overflow="hidden"
      position="relative"
      {...props}
    />
  );
}
