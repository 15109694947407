import { useState } from 'react';
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  type InputGroupProps,
  type InputProps,
} from '@chakra-ui/react';

import { Icons } from './Icons';

type Props = InputProps & {
  inputGroupProps?: InputGroupProps;
};

export function PasswordInput(props: Props) {
  const { inputGroupProps, ...inputProps } = props;
  const [showPassword, setShowPassword] = useState(false);
  const color = useColorModeValue('gray.500', 'gray.200');
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <InputGroup {...inputGroupProps}>
      <Input {...inputProps} type={showPassword ? 'text' : 'password'} />
      <InputRightElement width="4.5rem">
        <Button variant="link">
          <Icon
            as={showPassword ? Icons.EyeSlash : Icons.Eye}
            w={5}
            h={5}
            color={color}
            onClick={toggleShowPassword}
          />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
