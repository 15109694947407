import {
  Badge,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds,
  ReactFlowProvider,
} from 'reactflow';
import { toPng } from 'html-to-image';

import { MainLayout } from '../../components/MainLayout';
import { ErrorView } from '../../components/ErrorView';
import { Icons } from '../../components/Icons';
import { GitHubBadge } from '../../components/Integrations/GitHubBadge';
import {
  ModelTypeBadge,
  ModelTypeBadgeColors,
} from '../../components/ModelTypeBadge';
import {
  useDeleteONNXModel,
  useFetchONNXModel,
  useUpdateONNXModel,
} from '../../api/onnxModelApi';
import type { ServerModelType, ServerONNXModel } from '../../types/Api';
import { useShowConfirmation } from '../../components/ConfirmationDialog';
import { NotFound } from '../NotFound';
import { RuntimeDropdown } from '../../components/Integrations/RuntimeDropdown';
import type {
  RuntimePrecisionType,
  RuntimeType,
} from '../../types/Integrations/Runtimes';
import { useFetchMyUserBillingInfo } from '../../api/billingApi';
import { isPaidUser } from '../../support/billing/isPaidUser';
import { downloadImage } from '../../support/downloadImage';

import { ModelEditor } from './ModelEditor';
import { ModelEditDrawer } from './ModelEditDrawer';

function ModelEditLoader() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, { isLoading, error, refetch }] = useFetchONNXModel(id ?? '');
  let [
    myBillingInfoData,
    { isLoading: myBillingInfoIsLoading, error: myBillingInfoError },
  ] = useFetchMyUserBillingInfo();

  const showConfirmationDialog = useShowConfirmation();
  const [updateONNXModel] = useUpdateONNXModel();
  const [deleteONNXModel] = useDeleteONNXModel();

  // for exporting as PNG.
  const { getNodes } = useReactFlow();

  // usestate for runtimedropdown
  const [runtime, setRuntime] = useState<RuntimeType>(null);
  const [precision, setPrecision] = useState<RuntimePrecisionType>(null);

  // Search
  const [searchNameQuery, setSearchNameQuery] = useState<string>('');
  const [searchOpTypeQuery, setSearchOpTypeQuery] = useState<string>('');

  // ONNX Model
  const [editModel, setEditModel] = useState<ServerONNXModel | null>(null);

  const mockedPRLink = 'https://github.com/celluloseai/cellulose/pull/123';
  const bg = useColorModeValue('gray.200', 'gray.700');
  const color = useColorModeValue('gray.500', 'gray.200');

  if (isLoading || myBillingInfoIsLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (error || myBillingInfoError) {
    return <ErrorView error={error} />;
  }
  if (!data || !myBillingInfoData) {
    return <NotFound />;
  }
  const { model, graphNodes, supportedTRTPrecisionTypes } = data;
  const { userBillingInfo } = myBillingInfoData;

  const displaySupportedRuntimes = isPaidUser(userBillingInfo);

  const {
    name,
    type,
    description,
    ir_version,
    producer_name,
    producer_version,
  } = model;
  const colorScheme =
    ModelTypeBadgeColors[(model.type as ServerModelType) ?? ''] ?? 'gray';

  const editModelLabel = t(`Edit "{name}"`, { name });
  const exportPngLabel = t('Export / download as .png');
  const deleteModelLabel = t(`Delete "{name}"`, { name });

  return (
    <>
      <HStack bg={bg} align="flex-start" px={6} py={3}>
        <VStack flex={1} align="flex-start">
          {/* First row: Name, Edit, Export and Delete */}
          <HStack>
            <Heading fontWeight="medium" size="xs">
              {name}
            </Heading>
            <Spacer />
            <Tooltip label={editModelLabel} placement="top" hasArrow={true}>
              <Button
                variant="ghost"
                onClick={() => {
                  setEditModel(model);
                }}
              >
                <Icon as={Icons.Pencil} w={6} h={6} color={color} />
              </Button>
            </Tooltip>
            <Tooltip label={exportPngLabel} placement="top" hasArrow={true}>
              <Button
                variant="ghost"
                onClick={async () => {
                  const imageWidth = 768 * 1000;
                  const imageHeight = 1024 * 2000;
                  const minZoom = 0.001;
                  const maxZoom = 1000.0;

                  // we calculate a transform for the nodes so that all nodes are visible
                  // we then overwrite the transform of the `.react-flow__viewport` element
                  // with the style option of the html-to-image library
                  const nodesBounds = getRectOfNodes(getNodes());
                  const transform = getTransformForBounds(
                    nodesBounds,
                    imageWidth,
                    imageHeight,
                    minZoom,
                    maxZoom,
                  );

                  toPng(
                    document.querySelector(
                      '.react-flow__viewport',
                    ) as HTMLElement,
                    {
                      width: imageWidth,
                      height: imageHeight,
                      style: {
                        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
                      },
                    },
                  ).then((dataUrl: string) => {
                    downloadImage(dataUrl, name ?? 'model');
                  });
                }}
              >
                <Icon as={Icons.Printer} w={6} h={6} color={color} />
              </Button>
            </Tooltip>
            <Tooltip label={deleteModelLabel} placement="top" hasArrow={true}>
              <Button
                variant="ghost"
                onClick={() => {
                  showConfirmationDialog({
                    title: t(`Delete "{name}"?`, {
                      name: model.name ?? 'unknown',
                    }),
                    body: t(
                      `Are you sure you would like to delete "{name}"? This cannot be recovered.`,
                      { name: model.name ?? 'unknown' },
                    ),
                    onConfirm: async () => {
                      await deleteONNXModel(model);
                      navigate('/models');
                    },
                  });
                }}
              >
                <Icon as={Icons.Trash} w={6} h={6} color={color} />
              </Button>
            </Tooltip>
          </HStack>
          {/* Second row: Description */}
          <Text>{description}</Text>
          {/* Third row: Badges */}
          <HStack>
            <ModelTypeBadge type={type ?? ''} />
            <Badge
              colorScheme={colorScheme}
            >{`IR version: ${ir_version}`}</Badge>
            {producer_name ? (
              <Badge
                colorScheme={colorScheme}
              >{`Producer name: ${producer_name}`}</Badge>
            ) : null}
            {producer_version ? (
              <Badge
                colorScheme={colorScheme}
              >{`Producer version: ${producer_version}`}</Badge>
            ) : null}
            <GitHubBadge
              prState="open"
              prId="123"
              prLink={mockedPRLink}
              prefix="PR #"
            />
          </HStack>
          {/* Fifth row: Search bar */}
          <VStack align="flex-end" w="100%">
            {/* Fourth row: Runtime engine and precision */}
            <RuntimeDropdown
              enabled={displaySupportedRuntimes}
              runtime={runtime}
              precision={precision}
              onRuntimeChange={(newRuntime) => {
                setRuntime(newRuntime);
              }}
              onPrecisionChange={(newPrecision) => {
                setPrecision(newPrecision);
              }}
            />
            <InputGroup w={320}>
              <InputLeftElement pointerEvents="none">
                <Icon as={Icons.MagnifyingGlass} w={5} h={5} color={color} />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search by name / output"
                value={searchNameQuery}
                onChange={(event) => setSearchNameQuery(event.target.value)}
              />
            </InputGroup>
          </VStack>
        </VStack>
      </HStack>
      <ModelEditor
        model={model}
        graphNodes={graphNodes}
        supportedTRTPrecisionTypes={supportedTRTPrecisionTypes}
        showSourceTray={true}
        isCompare={false}
        searchNameQuery={searchNameQuery}
        searchOpTypeQuery={searchOpTypeQuery}
        selectedRuntime={runtime}
        selectedPrecision={precision}
        onSearchBarChange={(newSearchQuery: string) =>
          setSearchNameQuery(newSearchQuery)
        }
        onGraphNodeSourceClick={(newSearchQuery: string) =>
          setSearchOpTypeQuery(newSearchQuery)
        }
      />
      <ModelEditDrawer
        model={editModel}
        onSave={async (model) => {
          const { id, name, description } = model;
          await updateONNXModel({
            id,
            updates: { name: name ?? '', description: description ?? '' },
          });
          setEditModel(null);
          refetch();
        }}
        onClose={() => setEditModel(null)}
      />
    </>
  );
}

export function ModelEdit() {
  return (
    <MainLayout contentPadding={0}>
      {/* Need to wrap ReactFlowProvider here so that we can
      call useReactFlow() in ModelEditLoader component */}
      <ReactFlowProvider>
        <ModelEditLoader />
      </ReactFlowProvider>
    </MainLayout>
  );
}
