import { Flex, VStack, Icon, Text, Spacer } from '@chakra-ui/react';

import { Icons } from '../../components/Icons';
import {
  ONNXGraphNodeColors,
  ONNXGraphNodeIcons,
} from '../../constants/GraphNodes';
import type { ServerONNXGraphNodeOpType } from '../../types/Models/ONNX';

type GraphNodeTrayProps = {
  onGraphNodeClick: (graphNode: string) => void;
};

type NodeItemProps = {
  type: string;
  onClick: (type: string) => void;
};

export function GraphNodeTray(props: GraphNodeTrayProps) {
  const { onGraphNodeClick } = props;
  return (
    <Flex flexDirection="row">
      <VStack spacing={1}>
        {Object.keys(ONNXGraphNodeColors).map((type, i) => (
          <NodeItem
            key={i}
            type={type}
            onClick={() => onGraphNodeClick(type)}
          />
        ))}
      </VStack>
    </Flex>
  );
}

function NodeItem(props: NodeItemProps) {
  const { type, onClick } = props;

  return (
    <Flex
      bg={
        ONNXGraphNodeColors[type as ServerONNXGraphNodeOpType] ?? 'transparent'
      }
      w="125px"
      direction="row"
      padding={2}
      borderRadius={3}
      opacity={1}
      onClick={() => onClick(type)}
    >
      <Icon
        as={
          ONNXGraphNodeIcons[type as ServerONNXGraphNodeOpType] ??
          Icons.Settings
        }
        w={4}
        h={4}
        color="blackAlpha.500"
      />
      <Spacer />
      <Text color="gray.700" fontSize="2xs" fontWeight="medium">
        {type}
      </Text>
      <Spacer />
    </Flex>
  );
}
