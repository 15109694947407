import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { ScrollView } from '../components/ScrollView';
import { useShowMessage } from '../components/useShowMessage';
import { fetch } from '../support/Fetch';
import { endpoints } from '../generated/endpoints';
import type { ServerVerifyPasswordReset } from '../types/Api';
import { HeaderBar } from '../components/HeaderBar/HeaderBar';
import { Button as NavButton } from '../components/Button';
import { PasswordInput } from '../components/PasswordInput';

async function sendResetPasswordRequest(token: string, password: string) {
  const body: ServerVerifyPasswordReset = {
    token,
    new_password: password,
  };
  return await fetch(endpoints.reset_password, { body });
}

export function ResetPassword() {
  const showMessage = useShowMessage();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [password, setPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const passwordResetToken = params.get('token') ?? '';
  const loginPage = '/login';
  const bg = useColorModeValue('gray.50', 'gray.700');

  const isPasswordMatching = password === reenterPassword;

  const doResetPassword = async () => {
    showMessage.closeAll();
    setLoading(true);
    const { isError, status, data } = await sendResetPasswordRequest(
      passwordResetToken,
      password,
    );
    setLoading(false);
    if (isError) {
      showMessage({ type: 'error', message: t('Network error') });
    } else if (status === 200 || status === 201) {
      showMessage({
        type: 'success',
        message: t('Password Reset successful!'),
      });
      // Navigate back to login page.
      navigate(loginPage);
    } else if (status === 400 || status === 422 || status === 403) {
      showMessage({
        type: 'error',
        message: data ? String(data.detail) : 'Invalid password reset token',
      });
    } else {
      showMessage({
        type: 'error',
        message: t('Unexpected response status: {status}', { status }),
      });
    }
  };

  return (
    <>
      <HeaderBar>
        <NavButton to={loginPage} variant="outline">
          {t('Back to Login')}
        </NavButton>
      </HeaderBar>
      <ScrollView
        h="100vh"
        bg={bg}
        contentContainerProps={{
          flexGrow: 1,
          justify: 'center',
          align: 'center',
        }}
      >
        <VStack
          w="80vw"
          maxW="md"
          spacing={6}
          my="116px"
          sx={{
            // Offset from center just enough to make the _box_ look centered
            transform: `translateY(-92px)`,
          }}
        >
          <Heading fontWeight="medium" size="sm">
            {t('Reset Your Cellulose Password')}
          </Heading>
          <VStack
            as="form"
            alignSelf="stretch"
            px={10}
            py={8}
            spacing={6}
            bg="white"
            boxShadow="md"
            borderRadius="xl"
            onSubmit={(event) => {
              event.preventDefault();
              doResetPassword();
            }}
          >
            <FormControl isRequired>
              <FormLabel>{t('New Password')}</FormLabel>
              <PasswordInput
                isDisabled={isLoading}
                placeholder={t('Enter your new password')}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <FormControl isRequired isInvalid={!isPasswordMatching}>
              <FormLabel>{t('Verify New Password')}</FormLabel>
              <Input
                type="password"
                isDisabled={isLoading}
                placeholder={t('Enter your new password again')}
                value={reenterPassword}
                onChange={(event) => setReenterPassword(event.target.value)}
              />
              {isPasswordMatching ? null : (
                <FormErrorMessage>
                  {t('The passwords do not match. Please try again.')}
                </FormErrorMessage>
              )}
            </FormControl>
            <HStack alignSelf="stretch" justify="flex-end">
              <Button
                type="submit"
                variant="solid"
                colorScheme="teal"
                isLoading={isLoading}
              >
                {t('Reset Password')}
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </ScrollView>
    </>
  );
}
