import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icons, type IconName } from './Icons';
import { NavLink } from './NavLink';

type Props = {
  title: string;
  icon: IconName;
};

export function IntegrationsNav(props: Props) {
  const { title, icon } = props;
  // const [organizations, { refetch }] = useFetchOrganizations();
  // TODO: Just a hack.
  const integrations = [
    { id: 1, type: 'GitHub', icon: 'Github', enabled: false },
    { id: 2, type: 'GitLab', icon: 'Gitlab', enabled: false },
    { id: 3, type: 'Jira', icon: 'Jira', enabled: false },
    { id: 4, type: 'Linear', icon: 'Linear', enabled: false },
  ];
  const basePath = '/settings/integrations';
  const location = useLocation();
  const navigate = useNavigate();
  const isActive =
    location.pathname === basePath ||
    location.pathname.startsWith(basePath + '/');

  const color = useColorModeValue('gray.700', 'gray.300');
  return (
    <Accordion flex={1} allowToggle defaultIndex={isActive ? 0 : -1}>
      <AccordionItem borderY="none">
        {/* TODO(RUL-331): This brute force approach refetches all organizations just to render
            updated names. */}
        <AccordionButton
          onClick={() => {
            navigate(basePath);
            // refetch();
          }}
        >
          <Tooltip
            label="List of all available integrations"
            placement="top"
            hasArrow={true}
          >
            <HStack flex={1}>
              <Icon as={Icons[icon]} w={6} h={6} color={color} />
              <Text fontWeight="medium" color={color}>
                {title}
              </Text>
            </HStack>
          </Tooltip>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <VStack align="stretch">
            {(integrations ?? []).map((integration) => {
              const { id, type, icon, enabled } = integration;
              const tooltip = enabled
                ? `List all ${type} integrations`
                : `${type} support coming soon!`;
              return (
                <NavLink
                  key={id}
                  to={`${basePath}/${type.toLowerCase()}`}
                  title={type}
                  icon={icon as IconName}
                  tooltip={tooltip}
                />
              );
            })}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
