import type { NodeProps } from 'reactflow';

import type { ServerONNXGraphNode } from '../../../types/Api';

import { GraphNodeConnectionHandle } from './GraphNodeConnectionHandle';
import { GraphNode } from './GraphNode';

export function ONNXGraphNodeWithHandles(
  props: NodeProps<ServerONNXGraphNode>,
) {
  const node = props.data;
  const inputsLength = 1;
  const outputsLength = 1;

  return (
    <>
      {Array.from({ length: inputsLength }, (_, i) => (
        <GraphNodeConnectionHandle
          type="source"
          node={node}
          key={i}
          index={i}
          total={inputsLength}
        />
      ))}
      <GraphNode node={node} />
      {Array.from({ length: outputsLength }, (_, i) => {
        return (
          <GraphNodeConnectionHandle
            type="target"
            node={node}
            key={i}
            index={i}
            total={outputsLength}
            tooltip={undefined}
          />
        );
      })}
    </>
  );
}
