export class ResponseStatusError extends Error {
  status: number;

  constructor(status: number) {
    const message = t('Unexpected response status: {status}', { status });
    super(message);
    this.status = status;
  }

  get name() {
    return this.constructor.name;
  }

  get [Symbol.toStringTag]() {
    return this.constructor.name;
  }
}
