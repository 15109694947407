const storageKeys = {
  authToken: 'auth-token',
};

type StorageKey = keyof typeof storageKeys;

// TODO: Use a more secure storage method
export const tokenStorage = {
  get: (key: StorageKey) => {
    return localStorage.getItem(storageKeys[key]);
  },
  set: (key: StorageKey, value: string) => {
    localStorage.setItem(storageKeys[key], value);
  },
  remove: (key: StorageKey) => {
    localStorage.removeItem(storageKeys[key]);
  },
};
