import {
  Button,
  Code,
  FormControl,
  FormLabel,
  Icon,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';

// import { LinkButton } from '../../../components/LinkButton';
// import { ModalForm } from '../../../components/ModalForm';
// import { run } from '../../../support/wasm/json_typegen';
import { Icons } from '../../../components/Icons';
import { useShowMessage } from '../../../components/useShowMessage';
import {
  copiedToClipboardLabel,
  copyToClipboardLabel,
} from '../../../constants/clipboard';

function toString(value: JSONValue | undefined) {
  return JSON.stringify(value, null, 2) ?? '';
}

type GraphNodeInitializerDimsEditorProps = {
  dims: Array<number> | undefined;
};

export function GraphNodeInitializerDimsEditor(
  props: GraphNodeInitializerDimsEditorProps,
) {
  const { dims } = props;
  const color = useColorModeValue('gray.700', 'gray.300');
  const showMessage = useShowMessage();

  return (
    <FormControl>
      <FormLabel>{t('Dimensions: ')}</FormLabel>
      <Code fontFamily="mono">{toString(dims)}</Code>
      <Tooltip label={copyToClipboardLabel} placement="top" hasArrow={true}>
        <Button
          variant="link"
          onClick={() => {
            copy(toString(dims));
            showMessage({
              type: 'success',
              message: copiedToClipboardLabel,
            });
          }}
        >
          <Icon as={Icons.DocumentDuplicate} w={4} h={4} color={color} />
        </Button>
      </Tooltip>
    </FormControl>
  );
}
