import { useState } from 'react';
import {
  Button,
  ModalCloseButton,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormControl,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

import type { ServerOrganizationRole, ServerUser } from '../../types/Api';
import { useUpdateOrganizationRole } from '../../api/organizationApi';
import { useShowMessage } from '../../components/useShowMessage';
import { Dropdown } from '../../components/Dropdown';

const emptyOrgMemberId = '';

type NewOrgRoleMemberFormProps = {
  formId: string;
  orgRole: ServerOrganizationRole;
  orgMembers: Array<ServerUser>;
  onClose: () => void;
  onSave: (orgRoleMemberId: string) => void;
};

function OrganizationRoleMemberNewForm(props: NewOrgRoleMemberFormProps) {
  const { formId, orgRole, orgMembers: allOrgMembers, onSave } = props;

  const [newOrgMemberId, setNewOrgMemberId] = useState(emptyOrgMemberId);

  // Filter available set of org members to just ones not already in
  // the org role.
  const orgRoleMemberSet = new Set(orgRole.members);
  const availableMembers = [];

  for (const orgMember of allOrgMembers) {
    if (!orgRoleMemberSet.has(orgMember.id)) {
      availableMembers.push(orgMember);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [availableOrgMembers, setAvailableOrgMembers] =
    useState(availableMembers);

  // This is just a hack to make sure the "first" option of the dropdown is "invalid".
  const emptyOptionArray = [{ value: emptyOrgMemberId, label: '' }];
  const availableOrgMemberOptions = (orgMembers: Array<ServerUser>) => {
    return orgMembers.map((orgMember) => {
      return { value: orgMember.id, label: orgMember.email ?? '' };
    });
  };
  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(newOrgMemberId);
      }}
    >
      <FormControl isRequired>
        <FormLabel>
          {t(`Add member to {orgRoleName}`, {
            orgRoleName: orgRole.name ?? 'organization role',
          })}
        </FormLabel>
        <Dropdown
          options={emptyOptionArray.concat(
            availableOrgMemberOptions(availableOrgMembers),
          )}
          getLabel={({ label }) => label}
          selected={(item) => item.value === newOrgMemberId}
          onChange={(newMember) => {
            setNewOrgMemberId(newMember.value);
          }}
        />
      </FormControl>
    </VStack>
  );
}

type OrgRoleMemberNewModalProps = {
  orgRole: ServerOrganizationRole | null;
  orgMembers: Array<ServerUser>;
  onClose: () => void;
};

export function OrganizationRoleMemberNewModal(
  props: OrgRoleMemberNewModalProps,
) {
  const showMessage = useShowMessage();

  const { orgRole, orgMembers, onClose } = props;

  const [updateOrganizationRole] = useUpdateOrganizationRole();

  return (
    <Modal isOpen={orgRole !== null} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {t(`Add new member to {name}`, {
            name: orgRole !== null ? orgRole.name : 'organization role',
          })}
        </ModalHeader>

        <ModalBody>
          {orgRole ? (
            <OrganizationRoleMemberNewForm
              formId="newOrganizationRoleMemberForm"
              orgRole={orgRole}
              orgMembers={orgMembers}
              onSave={async (newMemberId) => {
                if (newMemberId === emptyOrgMemberId) {
                  showMessage({
                    type: 'error',
                    message: t(
                      'Please select a user to add to the organization role',
                    ),
                  });
                  return;
                }
                const response = await updateOrganizationRole({
                  id: String(orgRole.id),
                  updates: {
                    members: [...orgRole.members, newMemberId],
                  },
                });
                if (!response.ok || !response.data) {
                  if (response.status === 404 || response.status === 422) {
                    showMessage({
                      type: 'error',
                      message: t(
                        'Invalid user - unable to add to the organization role',
                      ),
                    });
                  } else {
                    showMessage({
                      type: 'error',
                      message: t('Unexpected response status: {response}', {
                        response,
                      }),
                    });
                  }
                } else {
                  onClose();
                  showMessage({
                    type: 'success',
                    message: t(
                      `Successfully added to the "{orgRoleName}" role!`,
                      {
                        orgRoleName: orgRole.name,
                      },
                    ),
                  });
                }
              }}
              onClose={onClose}
            />
          ) : null}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="newOrganizationRoleMemberForm"
            colorScheme="teal"
          >
            {t('Add Member')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
