import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Text, VStack } from '@chakra-ui/react';

import { ScrollView } from '../../components/ScrollView';
import { fetch } from '../../support/Fetch';
import { endpoints } from '../../generated/endpoints';
import type { ServerVerifyOrganizationMemberCreate } from '../../types/Api';
import { HeaderBar } from '../../components/HeaderBar/HeaderBar';

type ValidationState =
  | { state: 'validating' }
  | { state: 'success' }
  | { state: 'error'; message: string };

async function verifyAndAddOrganizationMember(token: string) {
  const body: ServerVerifyOrganizationMemberCreate = {
    token,
  };
  return await fetch(endpoints.verify_and_add_org_members, { body });
}

export function OrganizationMemberInvite() {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [state, setState] = useState<ValidationState>({ state: 'validating' });

  useEffect(() => {
    const doVerifyAndAddOrganizationMember = async () => {
      const orgMemberInviteToken = params.get('token') ?? '';
      const { isError, ok, status, data } =
        await verifyAndAddOrganizationMember(orgMemberInviteToken);
      if (ok) {
        setState({ state: 'success' });
        navigate('/organizations');
      } else if (isError) {
        setState({ state: 'error', message: t('Network error') });
      } else if (status === 400 || status === 422 || status === 403) {
        setState({
          state: 'error',
          message:
            typeof data?.detail === 'string'
              ? data.detail
              : t('Invalid organization invite token'),
        });
      } else {
        setState({
          state: 'error',
          message: t('Unexpected response status: {status}', { status }),
        });
      }
    };
    doVerifyAndAddOrganizationMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderBar />
      <ScrollView
        h="100vh"
        bg="gray.50"
        contentContainerProps={{
          flexGrow: 1,
          justify: 'center',
          align: 'center',
        }}
      >
        <VStack
          w="80vw"
          maxW="md"
          spacing={6}
          my="116px"
          sx={{
            // Offset from center just enough to make the _box_ look centered
            transform: `translateY(-92px)`,
          }}
        >
          <VStack
            alignSelf="stretch"
            px={10}
            py={8}
            spacing={6}
            bg="white"
            boxShadow="md"
            borderRadius="xl"
          >
            {state.state === 'validating' ? (
              <Text>{t('Validating...')}</Text>
            ) : state.state === 'error' ? (
              <>
                <Text>{t('Unable to validate token.')}</Text>
                <Text>{state.message}</Text>
              </>
            ) : (
              <Text>{t('Success. Redirecting...')}</Text>
            )}
          </VStack>
        </VStack>
      </ScrollView>
    </>
  );
}
