import { Badge } from '@chakra-ui/react';

import type { ServerONNXTensor } from '../../types/Api';
import type { ServerONNXTensorDataType } from '../../types/Models/ONNX';
import { ServerONNXTensorDataTypeEnum } from '../../types/Models/ONNX';

/**
 * This should match the TensorProto DataType enum in:
 * https://github.com/onnx/onnx/blob/main/onnx/onnx.proto3
 **/
const TensorDataTypeColors: Record<ServerONNXTensorDataType, string> = {
  UNDEFINED: 'gray',
  FLOAT: 'orange',
  UINT8: 'blue',
  INT8: 'blue',
  UINT16: 'blue',
  INT16: 'blue',
  INT32: 'blue',
  INT64: 'blue',
  STRING: 'gray',
  BOOL: 'gray',
  FLOAT16: 'orange',
  DOUBLE: 'orange',
  UINT32: 'blue',
  UINT64: 'blue',
  COMPLEX64: 'violet',
  COMPLEX128: 'violet',
  BFLOAT16: 'orange',
  FLOAT8E4M3FN: 'orange',
  FLOAT8E4M3FNUZ: 'orange',
  FLOAT8E5M2: 'orange',
  FLOAT8E5M2FNUZ: 'orange',
};

export function TensorDataTypeBadge(props: { tensor: ServerONNXTensor }) {
  const { tensor } = props;
  const { data_type } = tensor;

  const dataTypeStr: ServerONNXTensorDataType = data_type
    ? ServerONNXTensorDataTypeEnum[data_type] ?? 'UNDEFINED'
    : 'UNDEFINED';

  return (
    <Badge
      colorScheme={
        TensorDataTypeColors[dataTypeStr as ServerONNXTensorDataType] ?? 'gray'
      }
    >
      {dataTypeStr}
    </Badge>
  );
}
