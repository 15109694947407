import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { ScrollView } from '../components/ScrollView';
import { useShowMessage } from '../components/useShowMessage';
import { fetch } from '../support/Fetch';
import { endpoints } from '../generated/endpoints';
import { HeaderBar } from '../components/HeaderBar/HeaderBar';
import { FooterBar } from '../components/FooterBar';

async function sendForgotPasswordRequest(email: string) {
  return await fetch(endpoints.forgot_password, { body: { email } });
}

function EmailSent(props: { email: string; onDismiss: () => void }) {
  const { email, onDismiss } = props;
  return (
    <>
      <Heading fontWeight="medium" size="sm">
        {t('Email Sent!')}
      </Heading>
      <VStack>
        <Text textAlign="center">
          {t(`An email with further instructions was sent to {email}`, {
            email,
          })}
        </Text>
      </VStack>
      <Button onClick={() => onDismiss()}>{t('Back to Login')}</Button>
    </>
  );
}

export function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const showMessage = useShowMessage();

  const state: Record<string, unknown> = Object(location.state);
  const initialEmail = typeof state.email === 'string' ? state.email : null;
  const [email, setEmail] = useState(initialEmail || null);
  const isEmailError = email === '';

  const [isLoading, setLoading] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const next = params.get('next') ?? '/';
  const loginPage = `/login?next=${next}`;
  const bg = useColorModeValue('gray.50', 'gray.700');
  const innerBg = useColorModeValue('white', 'black');

  const doForgotPassword = async () => {
    showMessage.closeAll();
    setLoading(true);
    const { isError, status, data } = await sendForgotPasswordRequest(
      email ?? '',
    );
    setLoading(false);
    if (isError) {
      showMessage({ type: 'error', message: t('Network error') });
    } else if (status === 200 || status === 202) {
      setEmailSent(true);
    } else if (status === 400 || status === 422) {
      showMessage({
        type: 'error',
        message: data ? String(data.detail) : 'Invalid email',
      });
    } else {
      showMessage({
        type: 'error',
        message: t('Unexpected response status: {status}', { status }),
      });
    }
  };

  return (
    <>
      <HeaderBar />
      <ScrollView
        h="100vh"
        bg={bg}
        contentContainerProps={{
          flexGrow: 1,
          justify: 'center',
          align: 'center',
        }}
      >
        <VStack
          w="80vw"
          maxW="md"
          spacing={6}
          my="56px"
          sx={{
            // Offset from center just enough to make the _box_ look centered
            transform: `translateY(-32px)`,
          }}
        >
          {emailSent ? (
            <EmailSent
              email={email ?? ''}
              onDismiss={() => {
                navigate(loginPage, { state: { email } });
              }}
            />
          ) : (
            <>
              <Heading fontWeight="medium" size="sm">
                {t('Forgot Your Password?')}
              </Heading>
              <VStack
                as="form"
                alignSelf="stretch"
                px={10}
                py={8}
                spacing={6}
                bg={innerBg}
                boxShadow="md"
                borderRadius="xl"
                onSubmit={(event) => {
                  event.preventDefault();
                  doForgotPassword();
                }}
              >
                <FormControl isRequired isInvalid={isEmailError}>
                  <FormLabel>{t('Email')}</FormLabel>
                  <Input
                    type="email"
                    autoFocus={true}
                    disabled={isLoading}
                    placeholder={t('Enter the email you registered with')}
                    value={email ?? ''}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  {isEmailError ? (
                    <FormErrorMessage>
                      {t('Email is required.')}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
                <HStack alignSelf="stretch" justify="flex-end">
                  <Button
                    variant="link"
                    onClick={() => {
                      navigate(loginPage, { state: { email } });
                    }}
                  >
                    {t('Back')}
                  </Button>
                  <Spacer />
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="teal"
                    isLoading={isLoading}
                  >
                    {t('Next')}
                  </Button>
                </HStack>
              </VStack>
            </>
          )}
        </VStack>
      </ScrollView>
      <FooterBar />
    </>
  );
}
