import { useRef } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

import type { ServerOrganizationRole } from '../../types/Api';

import { OrganizationRoleEditForm } from './OrganizationRoleEditForm';

type Props = {
  orgRole: ServerOrganizationRole | null;
  onClose: () => void;
  onSave: (orgRole: ServerOrganizationRole) => void;
};

export function OrganizationRoleEditDrawer(props: Props) {
  const { orgRole, onClose, onSave } = props;
  const isOpen = orgRole !== null;
  const focusElRef = useRef<HTMLInputElement>(null);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t('Edit Organization Role')}</DrawerHeader>

        <DrawerBody>
          {orgRole ? (
            <OrganizationRoleEditForm
              formId="editOrganizationRoleForm"
              orgRole={orgRole}
              focusElRef={focusElRef}
              onSave={onSave}
            />
          ) : null}
        </DrawerBody>

        <DrawerFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="editOrganizationRoleForm"
            colorScheme="teal"
          >
            {t('Save')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
