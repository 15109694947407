import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  VStack,
  Button,
  FormLabel,
  Input,
  HStack,
  Icon,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import type { RefObject } from 'react';
import { useRef, useState } from 'react';

import { useCreatePersonalAPIKey } from '../../../api/apiKeyApi';
import { Icons } from '../../../components/Icons';
import { useShowMessage } from '../../../components/useShowMessage';
import type { ServerPersonalAPIKeyCreate } from '../../../types/Api';

type PersonalAPIKeyNewFormProps = {
  formId: string;
  personalAPIKey: ServerPersonalAPIKeyCreate;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onClose: () => void;
  onSave: (personalAPIKey: ServerPersonalAPIKeyCreate) => void;
};

function PersonalAPIKeyNewForm(props: PersonalAPIKeyNewFormProps) {
  const { formId, personalAPIKey: initialPersonalAPIKey, onSave } = props;

  const namePlaceholder = t('Example: My local workstation etc.');
  const nameErrorMsg = t('API Key name is required.');
  const [nameInput, setNameInput] = useState<string | null>(null);
  const isNameError = nameInput === '';
  const [newPersonalAPIKeyCreate, setNewPersonalAPIKeyCreate] = useState(
    initialPersonalAPIKey,
  );
  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(newPersonalAPIKeyCreate);
      }}
    >
      <FormControl isRequired isInvalid={isNameError}>
        <FormLabel>{t('Name')}</FormLabel>
        <Input
          type="text"
          placeholder={namePlaceholder}
          onChange={(event) => {
            const name = event.target.value;
            setNameInput(name);
            setNewPersonalAPIKeyCreate((personalAPIKeyCreate) => ({
              ...personalAPIKeyCreate,
              name,
            }));
          }}
        />
        {isNameError ? (
          <FormErrorMessage>{nameErrorMsg}</FormErrorMessage>
        ) : null}
      </FormControl>
    </VStack>
  );
}

type PersonalAPIKeyNewModalProps = {
  newPersonalAPIKey: ServerPersonalAPIKeyCreate | null;
  onClose: () => void;
  onSave: () => void;
};

export function PersonalAPIKeyNewModal(props: PersonalAPIKeyNewModalProps) {
  const { newPersonalAPIKey, onClose, onSave } = props;
  const showMessage = useShowMessage();
  const focusElRef = useRef<HTMLInputElement>(null);

  const [createPersonalAPIKey] = useCreatePersonalAPIKey();
  const [isNewAPIKeyProcessing, setIsNewAPIKeyProcessing] = useState(false);
  return (
    <Modal
      isOpen={newPersonalAPIKey !== null}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Create new Personal API Key')}</ModalHeader>

        <ModalBody>
          {newPersonalAPIKey && (
            <PersonalAPIKeyNewForm
              formId="newPersonalAPIKeyForm"
              personalAPIKey={newPersonalAPIKey}
              focusElRef={focusElRef}
              onSave={async (personalAPIKey) => {
                setIsNewAPIKeyProcessing(true);
                const response = await createPersonalAPIKey(personalAPIKey);
                if (!response.ok) {
                  showMessage({
                    type: 'error',
                    message: t('Unexpected response status: {response}', {
                      response,
                    }),
                  });
                } else {
                  setIsNewAPIKeyProcessing(false);
                  onSave();
                  showMessage({
                    type: 'success',
                    message: t('API key created successfully!'),
                  });
                }
              }}
              onClose={onClose}
            />
          )}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="newPersonalAPIKeyForm"
            colorScheme="teal"
            isLoading={isNewAPIKeyProcessing}
            loadingText="Creating API key"
            onSubmit={async () => {}}
          >
            <HStack>
              <Icon as={Icons.Plus} w={6} h={6} />
              <Text>{t('Create API Key')}</Text>
            </HStack>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
