import {
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '../support/Auth';
import { RedirectToLogin } from '../components/RedirectToLogin';
import { FlexFill } from '../components/FlexFill';
import { Button } from '../components/Button';
import { Icons } from '../components/Icons';
import { MainLayout } from '../components/MainLayout';
import { SettingsLayout } from '../components/SettingsLayout';

type ComingSoonCommonProps = {
  redirectUrl?: string | undefined;
  redirectLabel?: string | undefined;
};

function ComingSoonCommon(props: ComingSoonCommonProps) {
  const { redirectUrl, redirectLabel } = props;
  const bg = useColorModeValue('gray.50', 'gray.700');
  return (
    <>
      <Spacer bg={bg} />
      <FlexFill direction="row" align="stretch" bg={bg}>
        <Spacer />
        <VStack>
          <HStack py={5}>
            <Icon as={Icons.ExclamationTriangle} w={9} h={9} />
            <Heading fontWeight="medium">{t('Coming Soon')}</Heading>
          </HStack>
          <Text>
            {t(
              "Oh no, this feature isn't available yet! Stay tuned for more updates",
            )}
          </Text>
          {redirectUrl !== undefined && redirectLabel !== undefined ? (
            <Button to={redirectUrl}>{redirectLabel}</Button>
          ) : null}
        </VStack>
        <Spacer />
      </FlexFill>
      <Spacer bg={bg} />
    </>
  );
}

export function ComingSoonWithHeaderAndFooterMain() {
  const { user } = useAuth();
  if (!user) {
    return <RedirectToLogin />;
  }
  return (
    <MainLayout scrollable={true}>
      <Flex direction="column" h="75vh" align="stretch">
        <ComingSoonCommon
          redirectUrl="/models"
          redirectLabel="Go Back to your Models"
        />
      </Flex>
    </MainLayout>
  );
}

export function ComingSoonWithHeaderAndFooterSettings() {
  const { user } = useAuth();
  if (!user) {
    return <RedirectToLogin />;
  }
  return (
    <SettingsLayout scrollable={true}>
      <Flex direction="column" h="75vh" align="stretch">
        <ComingSoonCommon />
      </Flex>
    </SettingsLayout>
  );
}
