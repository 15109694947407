/**
 * This is a hook that provides a fetch that includes auth headers and parses
 * the response body as JSON. The resulting fetch() method will throw (reject)
 * if anything goes wrong.
 */

import { useAuth } from '../../support/Auth';

import { ResponseStatusError } from './ResponseStatusError';

type Method = 'get' | 'post' | 'put' | 'delete';

type Init = Omit<RequestInit, 'method' | 'body'> & {
  method?: Method;
  body?: unknown;
};

const defaultOptions: RequestInit = {
  redirect: 'manual',
};

export function useAuthFetch() {
  const { getAuthToken } = useAuth();
  return async <T = any>(url: string, init: Init = {}) => {
    const { method, body } = init;
    const headers = new Headers(init.headers);
    const token = getAuthToken();
    if (token && !headers.has('Authorization') && isLocal(url)) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    const response = await fetch(url, {
      ...defaultOptions,
      ...normalizeRequest({ method, headers, body }),
    });
    if (!response.ok) {
      throw new ResponseStatusError(response.status);
    }
    const contentTypeHeader = response.headers.get('Content-Type') ?? '';
    const contentType = contentTypeHeader.toLowerCase().split(';')[0];
    if (contentType !== 'application/json') {
      throw new Error(
        t('Unexpected Content-Type: {contentType}', { contentType }),
      );
    }
    const data = await response.json();
    return data as T;
  };
}

function normalizeRequest(opts: {
  method: Method | undefined;
  headers: HeadersInit | undefined;
  body: unknown;
}): RequestInit {
  const defaultMethod = opts.body === undefined ? 'get' : 'post';
  const method = opts.method || defaultMethod;
  const headers = new Headers(opts.headers);
  if (method === 'get' || method === 'delete') {
    return { method, headers };
  }
  if (opts.body instanceof URLSearchParams) {
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return { method, headers, body: opts.body.toString() };
  }
  headers.append('Content-Type', 'application/json');
  return { method, headers, body: JSON.stringify(opts.body) ?? null };
}

// TODO: Find a better way to determine if we should send auth header or not.
function isLocal(url: string) {
  return url.startsWith('/');
}
