// Given inputs runtime and precision, check that the runtime has "TensorRT"
// in it and precision is present in supportedTRTPrecisionTypesSet.

import type {
  RuntimePrecisionType,
  RuntimeType,
} from '../../types/Integrations/Runtimes';

export const getTensorRTCompatibilityMode = (
  runtime: RuntimeType,
  precision: RuntimePrecisionType,
  supportedTRTPrecisionTypesSet: Set<RuntimePrecisionType>,
) => {
  if (runtime === null || precision === null) {
    return 'partial'; // partial because this if case should never trigger
  } else if (
    runtime?.includes('TensorRT') &&
    supportedTRTPrecisionTypesSet.has(precision)
  ) {
    return 'full';
  } else {
    return 'none';
  }
};
