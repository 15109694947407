import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { FlexFill } from './FlexFill';
import { FooterBar } from './FooterBar';
import { HeaderBarWithMenu } from './HeaderBar/HeaderBar';
import { Icons } from './Icons';
import { ScrollView } from './ScrollView';
import { SettingsNav } from './SettingsNav';

type Props = {
  children: ReactNode;
  scrollable?: boolean;
  contentPadding?: number;
};

export function SettingsLayout(props: Props) {
  const { children, scrollable, contentPadding = 8 } = props;

  const bg = useColorModeValue('gray.50', 'gray.500');
  const innerBg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.500', 'gray.200');

  const enableSearchBar = false;
  return (
    <Flex direction="column" h="100vh" align="stretch">
      <HeaderBarWithMenu />
      <FlexFill direction="row" align="stretch" bg={bg}>
        <Flex direction="column" w="2xs" bg={innerBg} shadow="sm" px={6} py={8}>
          <VStack align="stretch" spacing={6}>
            {enableSearchBar && (
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={Icons.MagnifyingGlass} w={5} h={5} color={color} />
                </InputLeftElement>
                <Input type="search" placeholder="Search" />
              </InputGroup>
            )}
            <SettingsNav />
          </VStack>
        </Flex>
        <FlexFill direction="column" p={scrollable ? 0 : contentPadding}>
          {scrollable ? (
            <ScrollView
              contentContainerProps={{ flexGrow: 1, p: contentPadding }}
            >
              {children}
            </ScrollView>
          ) : (
            children
          )}
        </FlexFill>
      </FlexFill>
      <FooterBar />
    </Flex>
  );
}
