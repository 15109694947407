import {
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '../support/Auth';
import { RedirectToLogin } from '../components/RedirectToLogin';
import { HeaderBar } from '../components/HeaderBar/HeaderBar';
import { FooterBar } from '../components/FooterBar';
import { FlexFill } from '../components/FlexFill';
import { Button } from '../components/Button';
import { Icons } from '../components/Icons';

function NotFoundCommon() {
  const bg = useColorModeValue('gray.50', 'gray.700');
  return (
    <>
      <Spacer bg={bg} />
      <FlexFill direction="row" align="stretch" bg={bg}>
        <Spacer />
        <VStack>
          <HStack py={5}>
            <Icon as={Icons.FaceFrown} w={9} h={9} />
            <Heading fontWeight="medium">{t('404 Not Found')}</Heading>
          </HStack>
          <Text paddingBottom={4}>
            {t("Oh no, we can't find the page you're looking for!")}
          </Text>
          <Button to="/">{t('Go Back Home')}</Button>
        </VStack>
        <Spacer />
      </FlexFill>
      <Spacer bg={bg} />
    </>
  );
}
export function NotFound() {
  const { user } = useAuth();
  if (!user) {
    return <RedirectToLogin />;
  }
  return (
    <Flex direction="column" h="100vh" align="stretch">
      <NotFoundCommon />
    </Flex>
  );
}

export function NotFoundWithHeaderAndFooter() {
  const { user } = useAuth();
  if (!user) {
    return <RedirectToLogin />;
  }
  return (
    <Flex direction="column" h="100vh" align="stretch">
      <HeaderBar />
      <NotFoundCommon />
      <FooterBar />
    </Flex>
  );
}
