import { Badge } from '@chakra-ui/react';

import type { ServerONNXAttributeProto } from '../../types/Api';
import type { ServerONNXGraphNodeAttributeType } from '../../types/Models/ONNX';

/**
 * This should match the AttributeProto AttributeType enum in:
 * https://github.com/onnx/onnx/blob/main/onnx/onnx.proto3
 **/
const GraphNodeAttributeTypeColors: Record<
  ServerONNXGraphNodeAttributeType,
  string
> = {
  UNDEFINED: 'gray',
  INT: 'blue',
  FLOAT: 'orange',
  STRING: 'green',
  TENSOR: 'red',
  GRAPH: 'purple',
  SPARSE_TENSOR: 'yellow',
  TYPE_PROTO: 'violet',
  FLOATS: 'orange',
  INTS: 'blue',
  STRINGS: 'green',
  TENSORS: 'red',
  GRAPHS: 'purple',
  SPARSE_TENSORS: 'yellow',
  TYPE_PROTOS: 'violet',
};

export function GraphNodeAttributeTypeBadge(props: {
  attribute: ServerONNXAttributeProto;
}) {
  const { attribute } = props;
  const { type } = attribute;
  return (
    <Badge
      colorScheme={
        GraphNodeAttributeTypeColors[
          type as ServerONNXGraphNodeAttributeType
        ] ?? 'gray'
      }
    >
      {type}
    </Badge>
  );
}
