import type { Edge, Node } from 'reactflow';
import dagre, { graphlib } from 'dagre';

import type { ServerONNXGraphNode } from '../../../types/Api';

const { Graph } = graphlib;

const WIDTH = 384;
const HEIGHT = 82;

/**
 * This function generates and returns the "nodes" and "edges"
 * used by React Flow.
 * @param graphNodes "our" deifnition of graph nodes.
 * @returns nodes and edges type that are compatible with React Flow.
 */
export function getGraphNodeLayout(graphNodes: Array<ServerONNXGraphNode>) {
  const graph = new Graph();
  graph.setGraph({});

  // TODO: I don't really know if we need this, but it was in the docs.
  graph.setDefaultEdgeLabel(() => ({}));

  const edges: Array<Edge> = [];
  for (let graphNode of graphNodes) {
    const inputArr = graphNode.inputs ?? [];
    const outputArr = graphNode.outputs ?? [];

    outputArr.forEach((output) => {
      graph.setNode(output, { width: WIDTH, height: HEIGHT });
      inputArr.forEach((input) => {
        edges.push({
          id: `edges-[${input}]->[${output}]`,
          source: input,
          target: output,
          label: input,
          labelBgPadding: [8, 4],
          labelBgBorderRadius: 4,
          labelBgStyle: { fill: 'gray.700', fontWeight: 700, fillOpacity: 0.9 },
          animated: true,
          type: 'smoothstep',
          style: { stroke: 'gray.700' },
        });
      });
    });
  }

  for (let edge of edges) {
    graph.setEdge(edge.source, edge.target);
  }

  dagre.layout(graph);

  const nodes: Array<Node<ServerONNXGraphNode>> = [];

  graphNodes.forEach((node) => {
    const outputArr = node.outputs ?? [];
    outputArr.forEach((output) => {
      const { x, y } = graph.node(output);
      nodes.push({
        id: output,
        type: 'graphNode',
        position: { x, y },
        data: node,
      });
    });
  });

  return [nodes, edges] as const;
}
