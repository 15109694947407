import {
  FormControl,
  FormLabel,
  HStack,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import { subscribeToPaidPlanLabel } from '../../constants/paidPlanMessages';
import type {
  RuntimePrecisionType,
  RuntimeType,
} from '../../types/Integrations/Runtimes';
import { TensorRTRuntimes } from '../../types/Integrations/TensorRT';
import { Dropdown } from '../Dropdown';

type Props = {
  enabled: boolean;
  runtime: RuntimeType;
  precision: RuntimePrecisionType;
  onRuntimeChange: (runtime: RuntimeType) => void;
  onPrecisionChange: (precision: RuntimePrecisionType) => void;
};

/**
 * Runtime selection dropdown - typically used at the top right of a model edit page.
 * @param props
 * @returns
 */
export function RuntimeDropdown(props: Props) {
  const { enabled, runtime, precision, onRuntimeChange, onPrecisionChange } =
    props;
  const trtPrecisionOptions = [
    { type: null as RuntimePrecisionType, label: 'None selected' },
    { type: 'FP32' as RuntimePrecisionType, label: 'FP32' },
    { type: 'FP16' as RuntimePrecisionType, label: 'FP16' },
    { type: 'INT8' as RuntimePrecisionType, label: 'INT8' },
    { type: 'INT32' as RuntimePrecisionType, label: 'INT32' },
    { type: 'BOOL' as RuntimePrecisionType, label: 'BOOL' },
  ];
  const runtimeTypeTooltipLabel = enabled
    ? 'Select a runtime type to view compatibility of this model with the selected runtime.'
    : subscribeToPaidPlanLabel;
  const precisionTooltipLabel = enabled
    ? 'Select a precision type to view compatibility of this model with the selected precision.'
    : subscribeToPaidPlanLabel;

  return (
    <VStack align="stretch" w={320}>
      <Tooltip label={runtimeTypeTooltipLabel} placement="top" hasArrow={true}>
        <HStack align="flex-end">
          <FormLabel w="100%">{t('Runtime Type')}</FormLabel>
          <FormControl isDisabled={!enabled}>
            <Dropdown
              size="sm"
              options={[
                { type: null, label: 'None selected' },
                {
                  type: TensorRTRuntimes['TensorRT 8.6.1'] as RuntimeType,
                  label: TensorRTRuntimes['TensorRT 8.6.1'],
                },
              ]}
              getLabel={({ label }) => label}
              selected={(item) => item.type === runtime}
              onChange={(event) => {
                onRuntimeChange(event.type);
              }}
            />
          </FormControl>
        </HStack>
      </Tooltip>
      <Tooltip label={precisionTooltipLabel} placement="bottom" hasArrow={true}>
        <HStack>
          <FormLabel w="100%">{t('Desired Precision')}</FormLabel>
          <FormControl isDisabled={!enabled}>
            <Dropdown
              size="sm"
              alignContent="center"
              options={trtPrecisionOptions}
              getLabel={({ label }) => label}
              selected={(item) => item.type === precision}
              onChange={(event) => {
                onPrecisionChange(event.type);
              }}
            />
          </FormControl>
        </HStack>
      </Tooltip>
    </VStack>
  );
}
