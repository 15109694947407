import { useRef } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

import type { ServerOrganization } from '../../types/Api';

import { OrganizationEditForm } from './OrganizationEditForm';

type Props = {
  organization: ServerOrganization | null;
  onClose: () => void;
  onSave: (organization: ServerOrganization) => void;
};

export function OrganizationEditDrawer(props: Props) {
  const { organization, onClose, onSave } = props;
  const isOpen = organization !== null;
  const focusElRef = useRef<HTMLInputElement>(null);
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t('Edit Organization')}</DrawerHeader>

        <DrawerBody>
          {organization ? (
            <OrganizationEditForm
              formId="editOrganizationForm"
              organization={organization}
              focusElRef={focusElRef}
              onSave={onSave}
            />
          ) : null}
        </DrawerBody>

        <DrawerFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" form="editOrganizationForm" colorScheme="teal">
            {t('Save')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
