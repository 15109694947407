import { type ReactNode } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Icon,
  Image,
  Spacer,
  Avatar,
  HStack,
  Text,
  Alert,
  AlertIcon,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';

import { useAuth } from '../../support/Auth';
import { useResendVerificationEmail } from '../../api/settingsApi';
import { Icons } from '../Icons';
import { Link } from '../Link';
import { useShowMessage } from '../useShowMessage';

import { NotificationHeader } from './NotificationHeader';
import { TipsHeader } from './TipsHeader';

export function HeaderBar(props: { children?: ReactNode }) {
  return (
    <Flex direction="row" align="stretch" h="60px" bg="gray.700">
      <Flex flex={1} direction="row" px={6} align="center">
        <Link to="/">
          <Image
            src="/Cellulose_Dark_Horizontal.png"
            alt="Cellulose logo"
            h="35px"
            w="auto"
            px={4}
          />
        </Link>
        <Spacer />
        {props.children}
      </Flex>
    </Flex>
  );
}

export function HeaderBarWithMenu() {
  const showMessage = useShowMessage();
  const { user, logoutUser } = useAuth();

  const [resendVerificationEmail] = useResendVerificationEmail();
  const name = user?.name ?? user?.email ?? '';
  const emailSubmittedMsg = t(`Verification email sent to {email}!`, {
    email: user?.email,
  });
  const color = useColorModeValue('gray.500', 'gray.200');

  const renderEmailVerificationWarning = () => {
    return (
      <Alert status="warning">
        <HStack>
          <AlertIcon />
          <Text>
            {t('It seems like your email address has not been verified.')}
          </Text>
          <Button
            variant="link"
            onClick={async () => {
              await resendVerificationEmail();
              showMessage({
                type: 'success',
                message: emailSubmittedMsg,
              });
            }}
          >
            {t('Resend verification email now')}
          </Button>
        </HStack>
      </Alert>
    );
  };
  return (
    <>
      {user?.emailVerified ? null : renderEmailVerificationWarning()}
      <HeaderBar>
        <TipsHeader tooltip="Tips support coming soon!" onClick={() => {}} />
        <NotificationHeader
          tooltip="Notifications support coming soon!"
          onClick={() => {}}
        />
        {/* Empty box to "pad" some spacing between header bar icons and
        the dropdown (profile) menu */}
        <Box p={2} />
        <Menu>
          <MenuButton
            as={Button}
            variant="link"
            color="whiteAlpha.800"
            _hover={{ color: 'white', transform: 'scale(1.01)' }}
            _active={{ color: 'white' }}
          >
            <HStack>
              <Text>{name}</Text>
              <Avatar name={name} src="blob:broken-link" />
            </HStack>
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuItem
                as={Link}
                style={{ boxShadow: 'none', textDecoration: 'none' }}
                to="/settings"
                justifyContent="center"
              >
                <HStack>
                  <Icon as={Icons.Settings} w={5} h={5} color={color} />
                  <Text>{t('Settings')}</Text>
                </HStack>
              </MenuItem>
              <MenuItem
                justifyContent="center"
                onClick={() => {
                  logoutUser();
                }}
              >
                <HStack>
                  <Icon
                    as={Icons.ArrowRightOnRectangle}
                    w={5}
                    h={5}
                    color={color}
                  />
                  <Spacer />
                  <Text>{t('Logout')}</Text>
                </HStack>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </HeaderBar>
    </>
  );
}
