import { Badge, Tooltip, Link, Icon, Text, HStack } from '@chakra-ui/react';

import type { JiraTicketStateType } from '../../types/Jira';
import { Icons } from '../Icons';

export const JiraBadgeColors: Record<JiraTicketStateType, string> = {
  backlog: 'gray',
  open: 'gray',
  closed: 'red',
  in_progress: 'blue',
  done: 'green',
};

export function JiraBadge(props: {
  ticketState: JiraTicketStateType;
  ticketId: string;
  ticketLink?: string;
  prefix?: string;
}) {
  const { ticketState, ticketId, ticketLink, prefix } = props;

  const navigateToTicketLinkLabel = 'Navigate to ticket - coming soon!';

  const text = (prefix ?? '') + ticketId;
  return (
    <Tooltip label={navigateToTicketLinkLabel} placement="top" hasArrow={true}>
      <Link href={ticketLink} isExternal>
        <Badge
          colorScheme={
            JiraBadgeColors[ticketState as JiraTicketStateType] ?? 'gray'
          }
        >
          <HStack>
            <Text>{text}</Text>
            <Icon as={Icons.ArrowTopRightOnSquare} />
          </HStack>
        </Badge>
      </Link>
    </Tooltip>
  );
}
