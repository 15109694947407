import {
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  Link as RouterLink,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';

import { Icons } from './Icons';
import type { IconName } from './Icons';

type Props = {
  title: string;
  icon?: IconName;
  to: string;
  tooltip?: string | undefined;
};

export function NavLink(props: Props) {
  const { title, icon, to, tooltip } = props;
  const path = useResolvedPath(to);
  const location = useLocation();
  const isActive =
    location.pathname === path.pathname ||
    location.pathname.startsWith(path.pathname + '/');

  const bg = useColorModeValue('gray.100', 'gray.700');
  const color = useColorModeValue('gray.700', 'gray.300');

  const renderIcon = (icon: IconName) => {
    return <Icon as={Icons[icon]} w={6} h={6} color={color} />;
  };

  let innerContent = (
    <Flex
      as={RouterLink}
      to={to}
      px={4}
      borderRadius="lg"
      h={10}
      direction="row"
      align="center"
      bg={isActive ? bg : 'transparent'}
      _hover={{ bg: bg, transform: 'scale(1.01)' }}
    >
      <HStack overflow="hidden">
        {icon && renderIcon(icon)}
        <Text
          fontWeight="medium"
          color={color}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Text>
      </HStack>
    </Flex>
  );

  if (tooltip) {
    innerContent = (
      <Tooltip label={tooltip} placement="right" hasArrow={true}>
        {innerContent}
      </Tooltip>
    );
  }
  return innerContent;
}
