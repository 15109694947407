import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Spacer,
  Stack,
  Td,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import type {
  ServerOrganizationRole,
  ServerOrganization,
  ServerOrganizationEnvironment,
} from '../../types/Api';
import { Icons } from '../../components/Icons';
import { useShowMessage } from '../../components/useShowMessage';
import {
  useAddEnvironmentOrganizationRoles,
  useRemoveEnvironmentOrganizationRoles,
} from '../../api/organizationApi';
import { EnvironmentBadge } from '../../components/EnvironmentBadge';
import { formatTooltip } from '../../support/formatTooltip';
import { ModalForm, useFormContext } from '../../components/ModalForm';

export function OrganizationEnvironmentPermissionEdit(props: {
  organization: ServerOrganization;
  environmentOrgRoles: ServerOrganizationEnvironment;
  onChange: () => void;
}) {
  const { organization, environmentOrgRoles, onChange } = props;

  const [editOrganizationRoles, setEditOrganizationRoles] =
    useState<Array<ServerOrganizationRole> | null>(null);
  const { id, environment, roles } = environmentOrgRoles;

  const editEnvironmentOrgRolesLabel = organization.is_admin
    ? t(`Edit Organization Role(s) for {environment}`, {
        environment,
      })
    : `Only administrators are allowed to modify roles associated with this environment`;

  return (
    <Tr key={id}>
      <Td>
        <EnvironmentBadge environment={environment ?? 'Development'} />
      </Td>
      <Td textAlign="end">
        <HStack>
          <Spacer />
          <HStack>
            {roles.map((role) => {
              const { id, name, description } = role;
              const orgRoleName = name ?? '';
              return (
                <Tooltip
                  label={formatTooltip(description)}
                  placement="top"
                  hasArrow={true}
                  key={id}
                >
                  {orgRoleName}
                </Tooltip>
              );
            })}
          </HStack>
          <Tooltip
            label={editEnvironmentOrgRolesLabel}
            placement="top"
            hasArrow={true}
          >
            <Button
              variant="ghost"
              onClick={() => {
                if (organization.is_admin) {
                  setEditOrganizationRoles(roles);
                }
              }}
            >
              <Icon as={Icons.Pencil} w={6} h={6} color="gray.500" />
            </Button>
          </Tooltip>
          <EnvironmentOrganizationRolesEditModal
            organization={organization}
            orgRoles={editOrganizationRoles}
            environment={environment ?? 'Development'}
            onSave={() => {
              onChange();
              setEditOrganizationRoles(null);
            }}
            onClose={() => setEditOrganizationRoles(null)}
          />
        </HStack>
      </Td>
    </Tr>
  );
}

type Props = {
  organization: ServerOrganization;
  orgRoles: Array<ServerOrganizationRole> | null;
  environment: string;
  onClose: () => void;
  onSave: (orgRoles: Array<ServerOrganizationRole>) => void;
};

function EnvironmentOrganizationRolesEditModal(props: Props) {
  const { organization, orgRoles, environment, onClose, onSave } = props;
  const showMessage = useShowMessage();

  const [addOrganizationRoles] = useAddEnvironmentOrganizationRoles();
  const [removeOrganizationRoles] = useRemoveEnvironmentOrganizationRoles();

  return (
    <ModalForm
      title={t(`Edit Organization Role(s) for {environment}`, { environment })}
      isOpen={orgRoles !== null}
      onClose={onClose}
      size="xl"
    >
      {orgRoles ? (
        <EnvironmentOrganizationRolesEditForm
          organization={organization}
          orgRoles={orgRoles}
          onSave={async (addOrgRoles, removeOrgRoles) => {
            // Remove organization roles first.
            const responseRemove = await removeOrganizationRoles(
              organization.id,
              environment,
              {
                org_role_ids: removeOrgRoles,
              },
            );
            if (!responseRemove.ok || !responseRemove.data) {
              showMessage({
                type: 'error',
                message: t(
                  'Unexpected responseRemove status: {responseRemove}',
                  {
                    responseRemove,
                  },
                ),
              });
            } else {
              onSave(orgRoles);
            }

            // Finally, add new organization roles.
            const responseAdd = await addOrganizationRoles(
              organization.id,
              environment,
              { org_role_ids: addOrgRoles },
            );
            if (!responseAdd.ok || !responseAdd.data) {
              showMessage({
                type: 'error',
                message: t('Unexpected responseAdd status: {responseAdd}', {
                  responseAdd,
                }),
              });
            } else {
              onSave(orgRoles);
            }
          }}
        />
      ) : null}
    </ModalForm>
  );
}

function EnvironmentOrganizationRolesEditForm(props: {
  organization: ServerOrganization;
  orgRoles: Array<ServerOrganizationRole>;
  onSave: (addOrgRoles: Array<string>, removeOrgRoles: Array<string>) => void;
}) {
  const { organization, orgRoles, onSave } = props;
  const { onSubmit } = useFormContext();
  onSubmit(() => {
    const newAddOrgRoles = new Set<string>();
    for (const currentCheckedOrgRole of currentCheckedOrgRoles) {
      if (!initialCheckedOrgRolesSet.has(currentCheckedOrgRole)) {
        newAddOrgRoles.add(currentCheckedOrgRole);
      }
    }

    const newRemoveOrgRoles = new Set<string>();
    for (const initialCheckedOrgRole of initialCheckedOrgRolesSet) {
      if (!currentCheckedOrgRoles.has(initialCheckedOrgRole)) {
        newRemoveOrgRoles.add(initialCheckedOrgRole);
      }
    }
    onSave(Array.from(newAddOrgRoles), Array.from(newRemoveOrgRoles));
  });

  const initialCheckedOrgRolesSet = new Set(orgRoles.map((role) => role.id));

  const [currentCheckedOrgRoles, setCurrentCheckedOrgRoles] = useState<
    Set<string>
  >(initialCheckedOrgRolesSet);

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('Organization Role(s)')}</FormLabel>
        <Stack spacing={[1, 5]}>
          {organization.roles.map(({ id, name, description }) => (
            <Checkbox
              key={id}
              size="md"
              colorScheme="teal"
              isChecked={currentCheckedOrgRoles.has(id)}
              onChange={(event) => {
                const newCheckedOrgRoles = new Set(currentCheckedOrgRoles);
                if (event.target.checked) {
                  newCheckedOrgRoles.add(id);
                } else {
                  newCheckedOrgRoles.delete(id);
                }
                setCurrentCheckedOrgRoles(newCheckedOrgRoles);
              }}
            >
              <Tooltip
                label={formatTooltip(description)}
                placement="top"
                hasArrow={true}
              >
                {name ?? ''}
              </Tooltip>
            </Checkbox>
          ))}
        </Stack>
      </FormControl>
    </VStack>
  );
}
