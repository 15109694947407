import {
  Checkbox,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
  Tooltip,
  Badge,
  TableCaption,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFetchONNXModelVersions } from '../../api/onnxModelApi';
import {
  GitHubBadge,
  GitHubBadgeColors,
} from '../../components/Integrations/GitHubBadge';
import { Icons } from '../../components/Icons';
import { JiraBadge } from '../../components/Integrations/JiraBadge';
import { MainLayout } from '../../components/MainLayout';
import { formatDateTime } from '../../support/formatDateTime';
import { formatTooltip } from '../../support/formatTooltip';
import type { PRStateType } from '../../types/Pr';
import { NotFound } from '../NotFound';

export function ModelVersionList() {
  const { id } = useParams();

  const navigate = useNavigate();
  const bg = useColorModeValue('white', 'gray.600');

  const initialCheckedModelVersionSet = new Set<number>();

  const [currentCheckedModelVersions, setCurrentCheckedModelVersions] =
    useState<Set<number>>(initialCheckedModelVersionSet);

  let [data, { isLoading }] = useFetchONNXModelVersions(id ?? '');

  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (!data) {
    return <NotFound />;
  }
  const { name, description, modelVersions, users } = data;

  // TODO: Make this Array<any> type explicit.
  const renderModelVersions = (modelVersions: Array<any>) => {
    return modelVersions.map((modelVersion) => {
      const { id, status, prId, ticketId, ticketState, owner_id, created_at } =
        modelVersion;

      const user = users.get(owner_id);
      return (
        <Tr key={id}>
          <Td textAlign="center">
            <Checkbox
              key={id}
              size="md"
              colorScheme="teal"
              isChecked={currentCheckedModelVersions.has(id)}
              onChange={(event) => {
                const newCheckedModelVersions = new Set(
                  currentCheckedModelVersions,
                );
                if (event.target.checked) {
                  newCheckedModelVersions.add(id);
                } else {
                  newCheckedModelVersions.delete(id);
                }
                setCurrentCheckedModelVersions(newCheckedModelVersions);
              }}
            />
          </Td>
          <Td textAlign="center">
            <GitHubBadge
              prState={status as PRStateType}
              prId={prId}
              // prLink={mockedPRLink}
              prefix="PR #"
            />
          </Td>
          <Td textAlign="center">
            <Badge
              colorScheme={GitHubBadgeColors[status as PRStateType] ?? 'gray'}
            >
              <Text>{status}</Text>
            </Badge>
          </Td>
          <Td textAlign="center">
            <JiraBadge
              ticketState={ticketState}
              ticketId={ticketId}
              // ticketLink={mockedTicketLink}
            />
          </Td>
          <Td textAlign="center">
            <Tooltip
              label={formatDateTime(created_at)}
              placement="top"
              hasArrow={true}
            >
              {user?.full_name ?? user?.email ?? 'unknown'}
            </Tooltip>
          </Td>
        </Tr>
      );
    });
  };

  return (
    <MainLayout scrollable={true}>
      <VStack align="stretch" spacing={6}>
        <VStack
          bg={bg}
          shadow="sm"
          borderRadius="lg"
          py={5}
          spacing={5}
          align="stretch"
        >
          <HStack px={6}>
            <Tooltip
              label={formatTooltip(description)}
              placement="top"
              hasArrow={true}
            >
              <Heading fontWeight="medium" size="xs">
                {t('{modelName} Releases', { modelName: name })}
              </Heading>
            </Tooltip>
            <Spacer />
            <InputGroup w={300}>
              <InputLeftElement pointerEvents="none">
                <Icon as={Icons.MagnifyingGlass} w={5} h={5} color="gray.500" />
              </InputLeftElement>
              <Input type="search" placeholder="Search" />
            </InputGroup>
          </HStack>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center">{t('Select')}</Th>
                  <Th textAlign="center">{t('PR #')}</Th>
                  <Th textAlign="center">{t('PR Status')}</Th>
                  <Th textAlign="center">{t('Ticket')}</Th>
                  <Th textAlign="center">{t('Created By')}</Th>
                </Tr>
              </Thead>
              <Tbody>{renderModelVersions(modelVersions ?? [])}</Tbody>
              <TableCaption textAlign="right">
                <Button
                  isDisabled={currentCheckedModelVersions.size !== 2}
                  onClick={() => {
                    const modelVersionArr = Array.from(
                      currentCheckedModelVersions.values(),
                    );
                    navigate(
                      `/models/${id}/versions/${modelVersionArr[0]}/${modelVersionArr[1]}/compare`,
                    );
                  }}
                  variant="solid"
                  colorScheme="teal"
                >
                  {t('Compare models')}
                </Button>
              </TableCaption>
            </Table>
          </TableContainer>
        </VStack>
      </VStack>
    </MainLayout>
  );
}
