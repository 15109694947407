import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';

import type { ServerONNXGraphNode } from '../../types/Api';
import type { TensorRTPrecisionType } from '../../types/Integrations/TensorRT';

import { GraphNodeEditor } from './GraphNodeEditor/GraphNodeEditor';

type Props = {
  modelId: string;
  node: ServerONNXGraphNode | null;
  supportedTRTPrecisionTypes: Set<TensorRTPrecisionType>;
  isOpen: boolean;
  onClose: () => void;
  onSave: (oldNode: ServerONNXGraphNode, newNode: ServerONNXGraphNode) => void;
};

export function ModelEditorDrawer(props: Props) {
  const { modelId, node, supportedTRTPrecisionTypes, isOpen, onClose, onSave } =
    props;
  const isEditDisabled = true;
  const nodeTitle = node ? node.op_type : 'Operation';
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontWeight="medium">{nodeTitle}</DrawerHeader>

        <DrawerBody>
          {node ? (
            <GraphNodeEditor
              formId="createEditGraphNodeForm"
              modelId={modelId}
              node={node}
              supportedTRTPrecisionTypes={supportedTRTPrecisionTypes}
              onSave={onSave}
            />
          ) : null}
        </DrawerBody>

        <DrawerFooter borderTopWidth={1} borderTopStyle="solid">
          <HStack>
            <Button variant="outline" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              isDisabled={isEditDisabled}
              type="submit"
              form="createEditGraphNodeForm"
              colorScheme="teal"
            >
              {t('Save')}
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
