import '@fontsource/inter/variable.css';
// import 'focus-visible/dist/focus-visible';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import * as components from './components';
import * as foundations from './foundations';

export const theme = extendTheme({
  ...foundations,
  initialColorMode: 'system',
  useSystemColorMode: true,
  components: { ...components },
  colors: { ...baseTheme.colors, brand: baseTheme.colors.teal },
  styles: {
    global: {
      body: {
        overflow: 'hidden',
      },
      'input[type="search"]::-webkit-search-cancel-button': {
        cursor: 'pointer',
      },
    },
  },
  space: {
    '4.5': '1.125rem',
  },
});
