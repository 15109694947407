import { useFetch, useMutation } from '../support/Fetch';
import type {
  ServerPersonalAPIKey,
  ServerPersonalAPIKeyCreate,
} from '../types/Api';
import { endpoints } from '../generated/endpoints';

import { resolve } from './helpers/resolve';

export function useFetchPersonalAPIKeys() {
  return useFetch<Array<ServerPersonalAPIKey>>(
    endpoints.get_user_personal_api_keys,
  );
}

export function useCreatePersonalAPIKey() {
  return useMutation((apiKey: ServerPersonalAPIKeyCreate) => {
    return {
      url: endpoints.create_user_personal_api_key,
      method: 'post',
      body: {
        name: apiKey.name,
      },
      responseTransform: (data) => fromServerPersonalAPIKey(data as any),
    };
  });
}

export function useDeletePersonalAPIKey() {
  return useMutation((apiKey: ServerPersonalAPIKey) => {
    return {
      url: resolve(endpoints.delete_user_personal_api_key, { id: apiKey.id }),
      method: 'delete',
    };
  });
}

function fromServerPersonalAPIKey(apiKey: ServerPersonalAPIKey) {
  return apiKey;
}
