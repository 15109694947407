import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Heading,
  Icon,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { ScrollView } from '../components/ScrollView';
import { useShowMessage } from '../components/useShowMessage';
import { fetch } from '../support/Fetch';
import { endpoints } from '../generated/endpoints';
import { Icons } from '../components/Icons';

async function sendVerifyEmailRequest(token: string) {
  return await fetch(endpoints.verify_email_address, { body: { token } });
}

export function VerifyEmail() {
  const showMessage = useShowMessage();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const verifyEmailToken = params.get('token') ?? '';
  const loginPage = '/login';
  const bg = useColorModeValue('gray.50', 'gray.700');

  const doVerifyEmailRequest = async () => {
    showMessage.closeAll();
    setLoading(true);
    const { isError, status } = await sendVerifyEmailRequest(verifyEmailToken);
    setLoading(false);
    if (isError) {
      setIsEmailVerified(false);
      showMessage({ type: 'error', message: t('Network error') });
    } else if (status === 200 || status === 201) {
      setIsEmailVerified(true);
    } else {
      setIsEmailVerified(false);
      if (!(status === 400 || status === 422 || status === 403)) {
        showMessage({
          type: 'error',
          message: t('Unexpected response status: {status}', { status }),
        });
      }
    }
  };

  useEffect(() => {
    doVerifyEmailRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollView
      h="100vh"
      bg={bg}
      contentContainerProps={{
        flexGrow: 1,
        justify: 'center',
        align: 'center',
      }}
    >
      <VStack
        w="80vw"
        maxW="md"
        spacing={6}
        my="116px"
        sx={{
          // Offset from center just enough to make the _box_ look centered
          transform: `translateY(-92px)`,
        }}
      >
        {isEmailVerified ? (
          <EmailVerified
            onDismiss={() => {
              navigate(loginPage);
            }}
          />
        ) : (
          <FailedVerification
            onDismiss={() => {
              navigate(loginPage);
            }}
          />
        )}
      </VStack>
    </ScrollView>
  );
}

function EmailVerified(props: { onDismiss: () => void }) {
  const { onDismiss } = props;
  return (
    <>
      <Heading fontWeight="medium" size="xs">
        {t('Your email has been verified!')}
      </Heading>
      <Icon as={Icons.CheckBadge} w={7} h={7} color="green.500" />
      <Button onClick={() => onDismiss()}>{t('Back to Login')}</Button>
    </>
  );
}

function FailedVerification(props: { onDismiss: () => void }) {
  const { onDismiss } = props;
  return (
    <>
      <Heading fontWeight="medium" size="xs">
        {t('Uh oh!')}
      </Heading>
      <Heading fontWeight="medium" size="xs" textAlign="center">
        {t('This link is invalid / expired')}
      </Heading>
      <Icon as={Icons.FaceFrown} w={7} h={7} color="red.500" />
      <Button onClick={() => onDismiss()}>{t('Back to Login')}</Button>
    </>
  );
}
