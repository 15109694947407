import { type RefObject, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
} from '@chakra-ui/react';

import type { ServerOrganizationRole } from '../../types/Api';
import { Dropdown } from '../../components/Dropdown';

export function OrganizationRoleEditForm(props: {
  formId: string;
  orgRole: ServerOrganizationRole;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onSave: (orgRole: ServerOrganizationRole) => void;
}) {
  const { formId, orgRole: initialOrgRole, focusElRef, onSave } = props;

  const [orgRole, setOrganizationRole] = useState(initialOrgRole);

  const { can_read, can_update, can_run } = orgRole;

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(orgRole);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('Organization Role Name')}</FormLabel>
        <Input
          type="text"
          ref={focusElRef}
          placeholder={t('Enter a name for this organization role')}
          value={orgRole.name}
          onChange={(event) => {
            const name = event.target.value;
            setOrganizationRole((orgRole) => ({ ...orgRole, name }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Organization Role Description')}</FormLabel>
        <Textarea
          placeholder={t('Enter a description for this organization role')}
          value={orgRole.description}
          onChange={(event) => {
            const description = event.target.value;
            setOrganizationRole((orgRole) => ({
              ...orgRole,
              description,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Read Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_read}
          onChange={(item) => {
            setOrganizationRole((orgRole) => ({
              ...orgRole,
              can_read: item.value,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Update Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_update}
          onChange={(item) => {
            setOrganizationRole((orgRole) => ({
              ...orgRole,
              can_update: item.value,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Run Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_run}
          onChange={(item) => {
            setOrganizationRole((orgRole) => ({
              ...orgRole,
              can_run: item.value,
            }));
          }}
        />
      </FormControl>
    </VStack>
  );
}
