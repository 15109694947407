import { VStack } from '@chakra-ui/react';

import { IntegrationsNav } from './IntegrationsNav';
import { NavLink } from './NavLink';

export function SettingsNav() {
  return (
    <VStack as="nav" align="stretch">
      <NavLink to="/settings/profile" title="Profile" icon="User" />
      <NavLink to="/settings/appearance" title="Appearance" icon="PaintBrush" />
      <NavLink to="/settings/notifications" title="Notifications" icon="Bell" />
      <NavLink to="/settings/personalapikeys" title="API Keys" icon="Key" />
      <IntegrationsNav title="Integrations" icon="PuzzlePiece" />
      <NavLink to="/settings/security" title="Security" icon="ShieldCheck" />
      <NavLink to="/settings/billing" title="Billing" icon="Banknotes" />
    </VStack>
  );
}
