import { Icons } from '../components/Icons';
import type { ServerONNXGraphNodeOpType } from '../types/Models/ONNX';
import { ServerONNXGraphNodeOps } from '../types/Models/ONNX';

export const ONNXGraphNodeColors: Partial<
  Record<ServerONNXGraphNodeOpType, string>
> = {};

// Iterate over all ServerONNXGraphNodeOpType, Assign a random color to
// ONNXGraphNodeColors[op]
// TODO: One of them is missing a color from ServerONNXGraphNodeOpType
ONNXGraphNodeColors.Abs = 'purple.100';
ONNXGraphNodeColors.Acos = 'green.100';
ONNXGraphNodeColors.Acosh = 'blue.100';
ONNXGraphNodeColors.Add = 'red.100';
ONNXGraphNodeColors.And = 'yellow.100';
ONNXGraphNodeColors.Asin = 'pink.100';
ONNXGraphNodeColors.Asinh = 'cyan.100';
ONNXGraphNodeColors.Atan = 'teal.100';
ONNXGraphNodeColors.Atanh = 'gray.100';
ONNXGraphNodeColors.AveragePool = 'purple.100';
ONNXGraphNodeColors.BatchNormalization = 'green.100';
ONNXGraphNodeColors.BitShift = 'blue.100';
ONNXGraphNodeColors.BitwiseAnd = 'red.100';
ONNXGraphNodeColors.BitwiseNot = 'yellow.100';
ONNXGraphNodeColors.BitwiseOr = 'pink.100';
ONNXGraphNodeColors.BitwiseXor = 'cyan.100';
ONNXGraphNodeColors.Cast = 'teal.100';
ONNXGraphNodeColors.Ceil = 'gray.100';
ONNXGraphNodeColors.Col2Im = 'purple.100';
ONNXGraphNodeColors.Compress = 'green.100';
ONNXGraphNodeColors.Concat = 'blue.100';
ONNXGraphNodeColors.ConcatFromSequence = 'red.100';
ONNXGraphNodeColors.Constant = 'yellow.100';
ONNXGraphNodeColors.ConstantOfShape = 'pink.100';
ONNXGraphNodeColors.Conv = 'cyan.100';
ONNXGraphNodeColors.ConvInteger = 'teal.100';
ONNXGraphNodeColors.ConvTranspose = 'gray.100';
ONNXGraphNodeColors.Cos = 'purple.100';
ONNXGraphNodeColors.Cosh = 'green.100';
ONNXGraphNodeColors.CumSum = 'blue.100';
ONNXGraphNodeColors.DFT = 'red.100';
ONNXGraphNodeColors.DeformConv = 'yellow.100';
ONNXGraphNodeColors.DepthToSpace = 'pink.100';
ONNXGraphNodeColors.DequantizeLinear = 'cyan.100';
ONNXGraphNodeColors.Det = 'teal.100';
ONNXGraphNodeColors.Div = 'gray.100';
ONNXGraphNodeColors.Dropout = 'purple.100';
ONNXGraphNodeColors.Einsum = 'green.100';
ONNXGraphNodeColors.Equal = 'blue.100';
ONNXGraphNodeColors.Erf = 'red.100';
ONNXGraphNodeColors.Exp = 'yellow.100';
ONNXGraphNodeColors.Expand = 'pink.100';
ONNXGraphNodeColors.EyeLike = 'cyan.100';
ONNXGraphNodeColors.Flatten = 'teal.100';
ONNXGraphNodeColors.Floor = 'gray.100';
ONNXGraphNodeColors.GRU = 'purple.100';
ONNXGraphNodeColors.Gather = 'green.100';
ONNXGraphNodeColors.GatherElements = 'blue.100';
ONNXGraphNodeColors.GatherND = 'red.100';
ONNXGraphNodeColors.Gemm = 'yellow.100';
ONNXGraphNodeColors.GlobalAveragePool = 'pink.100';
ONNXGraphNodeColors.GlobalLpPool = 'cyan.100';
ONNXGraphNodeColors.GlobalMaxPool = 'teal.100';
ONNXGraphNodeColors.Greater = 'gray.100';
ONNXGraphNodeColors.GridSample = 'purple.100';
ONNXGraphNodeColors.Hardmax = 'red.100';
ONNXGraphNodeColors.Identity = 'yellow.100';
ONNXGraphNodeColors.If = 'pink.100';
ONNXGraphNodeColors.InstanceNormalization = 'cyan.100';
ONNXGraphNodeColors.IsInf = 'teal.100';
ONNXGraphNodeColors.IsNaN = 'gray.100';
ONNXGraphNodeColors.LRN = 'purple.100';
ONNXGraphNodeColors.LSTM = 'green.100';
ONNXGraphNodeColors.LeakyRelu = 'blue.100';
ONNXGraphNodeColors.Less = 'red.100';
ONNXGraphNodeColors.Log = 'yellow.100';
ONNXGraphNodeColors.LogSoftmax = 'pink.100';
ONNXGraphNodeColors.Loop = 'cyan.100';
ONNXGraphNodeColors.LpNormalization = 'teal.100';
ONNXGraphNodeColors.LpPool = 'gray.100';
ONNXGraphNodeColors.MatMul = 'purple.100';
ONNXGraphNodeColors.MatMulInteger = 'green.100';
ONNXGraphNodeColors.Max = 'blue.100';
ONNXGraphNodeColors.MaxPool = 'red.100';
ONNXGraphNodeColors.MaxRoiPool = 'yellow.100';
ONNXGraphNodeColors.MaxUnpool = 'pink.100';
ONNXGraphNodeColors.Mean = 'cyan.100';
ONNXGraphNodeColors.Min = 'teal.100';
ONNXGraphNodeColors.Mod = 'gray.100';
ONNXGraphNodeColors.Mul = 'purple.100';
ONNXGraphNodeColors.Multinomial = 'green.100';
ONNXGraphNodeColors.Neg = 'blue.100';
ONNXGraphNodeColors.NonMaxSuppression = 'red.100';
ONNXGraphNodeColors.NonZero = 'yellow.100';
ONNXGraphNodeColors.Not = 'pink.100';
ONNXGraphNodeColors.OneHot = 'cyan.100';
ONNXGraphNodeColors.Or = 'teal.100';
ONNXGraphNodeColors.PRelu = 'gray.100';
ONNXGraphNodeColors.Pad = 'purple.100';
ONNXGraphNodeColors.Pow = 'green.100';
ONNXGraphNodeColors.QLinearConv = 'blue.100';
ONNXGraphNodeColors.QLinearMatMul = 'red.100';
ONNXGraphNodeColors.RNN = 'yellow.100';
ONNXGraphNodeColors.RandomNormal = 'pink.100';
ONNXGraphNodeColors.RandomNormalLike = 'cyan.100';
ONNXGraphNodeColors.RandomUniform = 'teal.100';
ONNXGraphNodeColors.RandomUniformLike = 'gray.100';
ONNXGraphNodeColors.Range = 'purple.100';
ONNXGraphNodeColors.Reciprocal = 'green.100';
ONNXGraphNodeColors.ReduceL1 = 'blue.100';
ONNXGraphNodeColors.ReduceL2 = 'red.100';
ONNXGraphNodeColors.ReduceLogSum = 'yellow.100';
ONNXGraphNodeColors.ReduceLogSumExp = 'pink.100';
ONNXGraphNodeColors.ReduceMax = 'cyan.100';
ONNXGraphNodeColors.ReduceMean = 'teal.100';
ONNXGraphNodeColors.ReduceMin = 'gray.100';
ONNXGraphNodeColors.ReduceProd = 'purple.100';
ONNXGraphNodeColors.ReduceSum = 'green.100';
ONNXGraphNodeColors.Reshape = 'yellow.100';
ONNXGraphNodeColors.Resize = 'pink.100';
ONNXGraphNodeColors.ReverseSequence = 'cyan.100';
ONNXGraphNodeColors.RoiAlign = 'teal.100';
ONNXGraphNodeColors.Round = 'gray.100';
ONNXGraphNodeColors.Scan = 'purple.100';
ONNXGraphNodeColors.Scatter = 'green.100';
ONNXGraphNodeColors.ScatterElements = 'blue.100';
ONNXGraphNodeColors.ScatterND = 'red.100';
ONNXGraphNodeColors.SequenceAt = 'yellow.100';
ONNXGraphNodeColors.SequenceConstruct = 'pink.100';
ONNXGraphNodeColors.SequenceEmpty = 'cyan.100';
ONNXGraphNodeColors.SequenceErase = 'teal.100';
ONNXGraphNodeColors.SequenceInsert = 'gray.100';
ONNXGraphNodeColors.SequenceLength = 'purple.100';
ONNXGraphNodeColors.Shape = 'green.100';
ONNXGraphNodeColors.Sigmoid = 'blue.100';
ONNXGraphNodeColors.Sign = 'red.100';
ONNXGraphNodeColors.Sin = 'yellow.100';
ONNXGraphNodeColors.Size = 'pink.100';
ONNXGraphNodeColors.Slice = 'cyan.100';
ONNXGraphNodeColors.SpaceToDepth = 'teal.100';
ONNXGraphNodeColors.Split = 'gray.100';
ONNXGraphNodeColors.SplitToSequence = 'purple.100';
ONNXGraphNodeColors.Sqrt = 'green.100';
ONNXGraphNodeColors.Squeeze = 'blue.100';
ONNXGraphNodeColors.StringNormalizer = 'red.100';
ONNXGraphNodeColors.Sub = 'yellow.100';
ONNXGraphNodeColors.Sum = 'pink.100';
ONNXGraphNodeColors.Tan = 'cyan.100';
ONNXGraphNodeColors.Tanh = 'teal.100';
ONNXGraphNodeColors.TfIdfVectorizer = 'gray.100';
ONNXGraphNodeColors.Tile = 'purple.100';
ONNXGraphNodeColors.TopK = 'green.100';
ONNXGraphNodeColors.Transpose = 'blue.100';
ONNXGraphNodeColors.Trilu = 'red.100';
ONNXGraphNodeColors.Unique = 'yellow.100';
ONNXGraphNodeColors.Unsqueeze = 'pink.100';
ONNXGraphNodeColors.Upsample = 'cyan.100';
ONNXGraphNodeColors.Where = 'teal.100';
ONNXGraphNodeColors.Xor = 'gray.100';
ONNXGraphNodeColors.Bernoulli = 'purple.100';
ONNXGraphNodeColors.BlackmanWindow = 'green.100';
ONNXGraphNodeColors.CastLike = 'blue.100';
ONNXGraphNodeColors.Celu = 'red.100';
ONNXGraphNodeColors.CenterCropPad = 'yellow.100';
ONNXGraphNodeColors.Clip = 'pink.100';
ONNXGraphNodeColors.DynamicQuantizeLinear = 'cyan.100';
ONNXGraphNodeColors.Elu = 'teal.100';
ONNXGraphNodeColors.GreaterOrEqual = 'gray.100';
ONNXGraphNodeColors.GroupNormalization = 'purple.100';
ONNXGraphNodeColors.HammingWindow = 'green.100';
ONNXGraphNodeColors.HannWindow = 'blue.100';
ONNXGraphNodeColors.HardSigmoid = 'red.100';
ONNXGraphNodeColors.HardSwish = 'yellow.100';
ONNXGraphNodeColors.LayerNormalization = 'pink.100';
ONNXGraphNodeColors.LeakyRelu = 'cyan.100';
ONNXGraphNodeColors.LessOrEqual = 'teal.100';
ONNXGraphNodeColors.LogSoftmax = 'gray.100';
ONNXGraphNodeColors.MeanVarianceNormalization = 'purple.100';
ONNXGraphNodeColors.Mish = 'green.100';
ONNXGraphNodeColors.NegativeLogLikelihoodLoss = 'blue.100';
ONNXGraphNodeColors.PRelu = 'red.100';
ONNXGraphNodeColors.Range = 'yellow.100';
ONNXGraphNodeColors.ReduceL1 = 'pink.100';
ONNXGraphNodeColors.ReduceL2 = 'cyan.100';
ONNXGraphNodeColors.ReduceLogSum = 'teal.100';
ONNXGraphNodeColors.ReduceLogSumExp = 'gray.100';
ONNXGraphNodeColors.ReduceSumSquare = 'purple.100';
ONNXGraphNodeColors.Relu = 'red.100';
ONNXGraphNodeColors.Selu = 'blue.100';
ONNXGraphNodeColors.SequenceMap = 'red.100';
ONNXGraphNodeColors.Shrink = 'yellow.100';
ONNXGraphNodeColors.Softmax = 'pink.100';
ONNXGraphNodeColors.SoftmaxCrossEntropyLoss = 'cyan.100';
ONNXGraphNodeColors.Softplus = 'teal.100';
ONNXGraphNodeColors.Softsign = 'teal.100';
ONNXGraphNodeColors.ThresholdedRelu = 'gray.100';

export const ONNXGraphNodeIcons: Partial<
  Record<ServerONNXGraphNodeOpType, any>
> = {};

ServerONNXGraphNodeOps.forEach((op) => {
  if (op.startsWith('Atan') || op.startsWith('Add')) {
    ONNXGraphNodeIcons[op] = Icons.Calculator;
  } else if (op.startsWith('Relu') || op.startsWith('Soft')) {
    ONNXGraphNodeIcons[op] = Icons.PaintBrush;
  } else if (op.startsWith('Batch') || op.startsWith('Max')) {
    ONNXGraphNodeIcons[op] = Icons.Plus;
  } else {
    ONNXGraphNodeIcons[op] = Icons.Settings;
  }
});
