import { useMemo, useState, type ReactNode } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { createContext } from '../support/createContext';

type DialogInit = {
  title: string;
  body: string;
  onConfirm: () => void;
};

type Props = {
  dialog: DialogInit | null;
  onClose: () => void;
};

function ConfirmationDialog(props: Props) {
  const { dialog, onClose } = props;

  const handleClick = () => {
    dialog?.onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={dialog !== null} onClose={() => onClose()} size="lg">
      <ModalOverlay />
      <ModalContent
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleClick();
          }
        }}
      >
        <ModalCloseButton />
        <ModalHeader>{dialog?.title}</ModalHeader>
        <ModalBody>{dialog?.body}</ModalBody>
        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={() => onClose()}>
            {t('Cancel')}
          </Button>
          <Button colorScheme="teal" onClick={handleClick}>
            {t('Proceed')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

type Context = {
  showDialog: (dialog: DialogInit) => void;
};

const [ContextProvider, useContext] =
  createContext<Context>('ConfirmationDialog');

export function ConfirmationDialogProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [dialog, setDialog] = useState<DialogInit | null>(null);
  const context = useMemo(
    () => ({
      showDialog: setDialog,
    }),
    [],
  );
  return (
    <>
      <ContextProvider value={context}>{children}</ContextProvider>
      <ConfirmationDialog dialog={dialog} onClose={() => setDialog(null)} />
    </>
  );
}

export function useShowConfirmation() {
  const { showDialog } = useContext();
  return showDialog;
}
