const MAX_LENGTH = 50;

export function formatTooltip(input: string | undefined) {
  if (!input) {
    return '';
  }
  return input.length > MAX_LENGTH
    ? input.slice(0, MAX_LENGTH - 1) + '…'
    : input;
}
