import { Badge, Tooltip, Icon, Text, HStack } from '@chakra-ui/react';

import type { TensorRTCompatibilityType } from '../../../types/Integrations/TensorRT';
import { Icons } from '../../Icons';

export const TensorRTBadgeColors: Record<TensorRTCompatibilityType, string> = {
  full: 'green',
  partial: 'yellow',
  none: 'red',
};

export const TensorRTBadgeIcons: Record<TensorRTCompatibilityType, any> = {
  full: Icons.CheckCircle,
  partial: Icons.ExclamationCircle,
  none: Icons.XCircle,
};

export function TensorRTBadge(props: {
  runtimeVersion: string;
  compatibility: TensorRTCompatibilityType;
  description?: string;
}) {
  const { runtimeVersion, compatibility } = props;

  return (
    <Tooltip label={runtimeVersion} placement="top" hasArrow={true}>
      <Badge
        colorScheme={
          TensorRTBadgeColors[compatibility as TensorRTCompatibilityType] ??
          'gray'
        }
      >
        <HStack>
          <Icon
            as={TensorRTBadgeIcons[compatibility as TensorRTCompatibilityType]}
          />
          <Text>{t('TRT')}</Text>
        </HStack>
      </Badge>
    </Tooltip>
  );
}
