import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '../../support/Auth';
import { ScrollView } from '../../components/ScrollView';
import { useShowMessage } from '../../components/useShowMessage';
import { fetch } from '../../support/Fetch';
import { endpoints } from '../../generated/endpoints';
import { HeaderBar } from '../../components/HeaderBar/HeaderBar';
import { Button as NavButton } from '../../components/Button';
import { FooterBar } from '../../components/FooterBar';

export async function sendAuthRequest(username: string, password: string) {
  const body = new URLSearchParams({ username, password });
  const tokenResult = await fetch(endpoints.login_for_access_token, { body });
  if (tokenResult.isError || tokenResult.status !== 200) {
    return tokenResult;
  }
  const token = String(tokenResult.data?.access_token);
  const oneSchemaToken = String(tokenResult.data?.one_schema_token);
  const userResult = await fetch(endpoints.get_user_me, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (userResult.isError || userResult.status !== 200) {
    return userResult;
  }
  const user = userResult.data ?? {};
  return {
    ...userResult,
    data: { token, oneSchemaToken, user },
  };
}

export function Login() {
  const showMessage = useShowMessage();
  const { loginUser } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const state: Record<string, unknown> = Object(location.state);
  const initialEmail = typeof state.email === 'string' ? state.email : '';
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const next = params.get('next') ?? '/';
  const signupPage = `/signup?next=${next}`;
  const forgotPasswordPage = `/forgot-password?next=${next}`;

  const bg = useColorModeValue('gray.50', 'gray.700');
  const innerBg = useColorModeValue('white', 'black');

  const doLogin = async () => {
    showMessage.closeAll();
    setLoading(true);
    const { isError, status, headers, data } = await sendAuthRequest(
      email,
      password,
    );
    setLoading(false);
    if (isError) {
      showMessage({ type: 'error', message: t('Network error') });
    } else if (status === 200) {
      const token = String(data?.token);
      const user = Object(data?.user) as JSONObject;
      loginUser(token, user, headers);
      navigate(next, { replace: true });
    } else if (status === 400 || status === 422) {
      showMessage({
        type: 'error',
        message: t('Invalid username or password'),
      });
    } else {
      showMessage({
        type: 'error',
        message: t('Unexpected response status: {status}', { status }),
      });
    }
  };

  return (
    <>
      <HeaderBar />
      <ScrollView
        h="100vh"
        bg={bg}
        contentContainerProps={{
          flexGrow: 1,
          justify: 'center',
          align: 'center',
        }}
      >
        <VStack
          w="80vw"
          maxW="md"
          spacing={6}
          my="116px"
          sx={{
            // Offset from center just enough to make the _box_ look centered
            transform: `translateY(-92px)`,
          }}
        >
          <Heading fontWeight="medium" size="sm">
            {t('Login to Cellulose')}
          </Heading>
          <VStack
            as="form"
            alignSelf="stretch"
            px={10}
            py={8}
            spacing={6}
            bg={innerBg}
            boxShadow="md"
            borderRadius="xl"
            onSubmit={(event) => {
              event.preventDefault();
              doLogin();
            }}
          >
            <FormControl>
              <FormLabel>{t('Email')}</FormLabel>
              <Input
                type="email"
                disabled={isLoading}
                placeholder={t('Enter your email')}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('Password')}</FormLabel>
              <Input
                type="password"
                disabled={isLoading}
                placeholder={t('Enter your password')}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <HStack alignSelf="stretch" justify="flex-end">
              <Button
                variant="link"
                onClick={() => {
                  navigate(forgotPasswordPage, { state: { email } });
                }}
              >
                {t('Forgot your password?')}
              </Button>
              <Spacer />
              <NavButton to={signupPage} variant="outline">
                {t('Sign up')}
              </NavButton>
              <Button
                type="submit"
                variant="solid"
                colorScheme="teal"
                isLoading={isLoading}
              >
                {t('Login')}
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </ScrollView>
      <FooterBar />
    </>
  );
}
