import {
  createContext as createContextReact,
  useContext as useContextReact,
} from 'react';

export function createContext<T>(
  /** A name for this context, used when displaying errors. */
  name: string,
) {
  const Context = createContextReact<T | null>(null);
  const useContext = () => {
    const value = useContextReact(Context);
    if (value === null) {
      throw new Error(`Cannot call use${name} outside of Provider`);
    }
    return value;
  };
  return [Context.Provider, useContext] as const;
}
