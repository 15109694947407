/**
 * TODO: Consider moving this file out of the schema folder.
 */
import { Dictionary, Record as Struct, String, type Static } from 'runtypes';

import { createId } from '../createId';

export const HeaderDictRuntype = Dictionary(String, String);

const HeaderFieldRuntype = Struct({
  id: String,
  key: String,
  value: String,
});

export type HeaderDict = Static<typeof HeaderDictRuntype>;
export type HeaderField = Static<typeof HeaderFieldRuntype>;

export function fromHeaderFieldArray(fields: Array<HeaderField>) {
  const properties: Record<string, string> = {};
  for (let field of fields) {
    properties[field.key] = field.value;
  }
  return properties;
}

export function toHeaderFieldArray(schema: HeaderDict | undefined) {
  const fields: Array<HeaderField> = [];
  if (schema) {
    for (let [key, value] of Object.entries(schema)) {
      const id = createId();
      fields.push({ id, key, value });
    }
  }
  return fields;
}

export function createEmptyHeaderField(): HeaderField {
  return {
    id: createId(),
    key: '',
    value: '',
  };
}
