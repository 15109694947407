import type { RefObject } from 'react';
import { useRef, useState } from 'react';
import {
  Button,
  ModalCloseButton,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormControl,
  FormLabel,
  VStack,
  Input,
} from '@chakra-ui/react';

import type {
  ServerOrganization,
  ServerOrganizationMemberCreate,
} from '../../types/Api';
import { useInviteOrganizationMemberByEmail } from '../../api/organizationApi';
import { useShowMessage } from '../../components/useShowMessage';

type NewOrgMemberFormProps = {
  formId: string;
  organization: ServerOrganization;
  organizationMember: ServerOrganizationMemberCreate;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onClose: () => void;
  onSave: (organizationMember: ServerOrganizationMemberCreate) => void;
};

function OrganizationMemberNewForm(props: NewOrgMemberFormProps) {
  const {
    formId,
    organizationMember: initialOrganizationMember,
    focusElRef,
    onSave,
  } = props;

  const [organizationMemberCreate, setOrganizationMemberCreate] = useState(
    initialOrganizationMember,
  );
  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(organizationMemberCreate);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('Email')}</FormLabel>
        <Input
          type="email"
          ref={focusElRef}
          placeholder={t('Enter the email address of the user')}
          value={organizationMemberCreate.email}
          onChange={(event) => {
            const email = event.target.value;
            setOrganizationMemberCreate((orgMember) => ({
              ...orgMember,
              email,
            }));
          }}
        />
      </FormControl>
    </VStack>
  );
}

type OrgMemberNewModalProps = {
  organization: ServerOrganization | null;
  newOrganizationMember: ServerOrganizationMemberCreate | null;
  onClose: () => void;
  onSave: (organizationMember: ServerOrganizationMemberCreate) => void;
};

export function OrganizationMemberNewModal(props: OrgMemberNewModalProps) {
  const { organization, newOrganizationMember, onClose, onSave } = props;
  const showMessage = useShowMessage();
  const focusElRef = useRef<HTMLInputElement>(null);

  const [inviteOrganizationMemberByEmail] =
    useInviteOrganizationMemberByEmail();
  return (
    <Modal
      isOpen={newOrganizationMember !== null}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('New Organization Member')}</ModalHeader>

        <ModalBody>
          {newOrganizationMember && organization ? (
            <OrganizationMemberNewForm
              formId="newOrganizationMemberForm"
              organization={organization}
              organizationMember={newOrganizationMember}
              focusElRef={focusElRef}
              onSave={async (orgMember) => {
                const { email } = orgMember;
                const response = await inviteOrganizationMemberByEmail(
                  organization.id,
                  { email },
                );
                if (!response.ok || !response.data) {
                  if (response.status === 404 || response.status === 422) {
                    showMessage({
                      type: 'error',
                      message:
                        'Could not find user with the provided email address',
                    });
                  } else if (response.status === 400) {
                    showMessage({
                      type: 'error',
                      message: 'User is already in this organization',
                    });
                  } else {
                    showMessage({
                      type: 'error',
                      message: t('Unexpected response status: {response}', {
                        response,
                      }),
                    });
                  }
                } else {
                  onSave(orgMember);
                  showMessage({
                    type: 'success',
                    message: t('Email invite sent!'),
                  });
                }
              }}
              onClose={onClose}
            />
          ) : null}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="newOrganizationMemberForm"
            colorScheme="teal"
            onSubmit={async () => {}}
          >
            {t('Add Organization Member')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
