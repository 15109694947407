import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ReactNode, MutableRefObject } from 'react';
import posthog from 'posthog-js';

import { endpoints } from '../generated/endpoints';

import { fetch } from './Fetch';
import { tokenStorage } from './tokenStorage';

export type User = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  isSuperUser: boolean;
  emailVerified: boolean;
  config: Record<string, string>;
};

type AuthState =
  | { state: 'initializing'; token: string }
  | { state: 'logged-in'; user: User }
  | { state: 'logged-out' };

type AuthContext = {
  user: User | null;
  getCurrentUser: () => User | null;
  setCurrentUser: (user: User) => void;
  getAuthToken: () => string | null;
  loginUser: (token: string, user: JSONObject, headers: Headers) => void;
  logoutUser: () => void;
};

// Refresh the token every 20 minutes; The TTL is 30m so we can increase this if we want.
const TOKEN_REFRESH_INTERVAL_MS = 20 * 60 * 1000;

const Context = createContext<AuthContext>({
  user: null,
  getCurrentUser: () => null,
  setCurrentUser: () => {},
  getAuthToken: () => null,
  loginUser: () => {},
  logoutUser: () => {},
});

export function AuthProvider(props: { children: ReactNode }) {
  // This is so we don't read from storage each time we render.
  const [tokenRef] = useState(() => {
    const token = tokenStorage.get('authToken');
    const ref: MutableRefObject<string | null> = { current: token };
    return ref;
  });
  const [authState, setAuthState] = useState<AuthState>(() => {
    return tokenRef.current === null
      ? { state: 'logged-out' }
      : { state: 'initializing', token: tokenRef.current };
  });
  // Make a stable ref for use within getCurrentUser below
  const authStateRef = useRef(authState);
  useEffect(() => {
    authStateRef.current = authState;
  }, [authState]);

  const [startScheduler, stopScheduler] = useRefreshScheduler(
    () => tokenRef.current ?? '',
    (newToken) => {
      tokenRef.current = newToken;
    },
  );

  // If there's an existing auth token, we'll validate it with the server.
  useEffect(
    () => {
      if (authState.state === 'initializing') {
        const { token } = authState;
        fetch(endpoints.get_user_me, {
          headers: { Authorization: `Bearer ${token}` },
        }).then(({ isError, status, headers, data }) => {
          // TODO: Add some retry logic if the network is down
          if (isError || status !== 200 || !data) {
            tokenRef.current = null;
            tokenStorage.remove('authToken');
            setAuthState({ state: 'logged-out' });
          } else {
            const config = new URLSearchParams(headers?.get('x-config') ?? '');
            const user = toUser(data, Object.fromEntries(config));
            // updateIntercom(user);
            updatePostHog(user);
            setAuthState({ state: 'logged-in', user });
            startScheduler();
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const user = authState.state === 'logged-in' ? authState.user : null;
  const getCurrentUser = useCallback(() => {
    const authState = authStateRef.current;
    return authState.state === 'logged-in' ? authState.user : null;
  }, []);
  const setCurrentUser = useCallback((user: User) => {
    setAuthState((authState) =>
      authState.state === 'logged-in' ? { ...authState, user } : authState,
    );
  }, []);
  const getAuthToken = useCallback(
    () => tokenRef.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const loginUser = useCallback(
    (token: string, userData: JSONObject, headers: Headers) => {
      tokenRef.current = token;
      tokenStorage.set('authToken', token);
      const config = new URLSearchParams(headers?.get('x-config') ?? '');
      const user = toUser(userData, Object.fromEntries(config));
      // updateIntercom(user);
      updatePostHog(user);
      setAuthState({ state: 'logged-in', user });
      startScheduler();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const logoutUser = useCallback(
    () => {
      stopScheduler();
      tokenRef.current = null;
      tokenStorage.remove('authToken');
      // updateIntercom(null);
      updatePostHog(null);
      setAuthState({ state: 'logged-out' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const contextValue = useMemo<AuthContext>(() => {
    return {
      user,
      getCurrentUser,
      setCurrentUser,
      getAuthToken,
      loginUser,
      logoutUser,
    };
  }, [
    user,
    getCurrentUser,
    setCurrentUser,
    getAuthToken,
    loginUser,
    logoutUser,
  ]);

  return authState.state === 'initializing' ? null : (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
}

function useRefreshScheduler(
  getToken: () => string,
  setToken: (token: string) => void,
) {
  const { startScheduler, stopScheduler } = useMemo(
    () => {
      let state: 'started' | 'stopped' = 'stopped';
      let timeout: Timeout | undefined;
      let abortController = new AbortController();
      const scheduleRefresh = () => {
        timeout = setTimeout(doRefresh, TOKEN_REFRESH_INTERVAL_MS);
      };
      const doRefresh = async () => {
        const token = getToken();
        abortController = new AbortController();
        // TODO: This endpoint should probably be using refresh_token
        const { isError, status, data, error } = await fetch(
          endpoints.refresh,
          {
            headers: { Authorization: `Bearer ${token}` },
            body: {},
            signal: abortController.signal,
          },
        );
        if (isError && error.name !== 'AbortError') {
          // Network error; retry later
          scheduleRefresh();
          return;
        }
        if (status === 200 && data) {
          const newToken = String(data.access_token);
          setToken(newToken);
          scheduleRefresh();
          return;
        }
        // Token is expired/invalid or the fetch was aborted; mark as stopped
        state = 'stopped';
      };
      return {
        startScheduler: () => {
          if (state !== 'started') {
            state = 'started';
            scheduleRefresh();
          }
        },
        stopScheduler: () => {
          if (state !== 'stopped') {
            state = 'stopped';
            clearTimeout(timeout);
            abortController.abort();
          }
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    // Be sure to stop the task scheduler when unmounting.
    return stopScheduler;
  }, [stopScheduler]);
  return [startScheduler, stopScheduler] as const;
}

export function useAuth() {
  return useContext(Context);
}

// TODO: Combine this with fromServerUser from ../api/organizationApi.ts
function toUser(userData: JSONObject, config: Record<string, string>): User {
  const {
    id,
    full_name,
    email,
    phone_number,
    address,
    is_superuser,
    email_verified,
  } = userData;
  return {
    id: String(id),
    name: typeof full_name === 'string' ? full_name : '',
    email: typeof email === 'string' ? email : '',
    phoneNumber: typeof phone_number === 'string' ? phone_number : '',
    address: typeof address === 'string' ? address : '',
    isSuperUser: Boolean(is_superuser),
    emailVerified: Boolean(email_verified),
    config,
  };
}

/**
 * Updates the PostHog user.
 */
function updatePostHog(user: User | null) {
  posthog.init('phc_93uc9JJWeah4jplVwTatgHygBSV9rSvcSEfeu64nzky', {
    api_host: 'https://app.posthog.com',
  });
  if (user === null) {
    posthog.reset();
  } else {
    posthog.identify(user.id, {
      ...user,
    });
  }
}

/**
 * Updates the Intercom user. - not used for now
 * @param user
 */
// function updateIntercom(user: User | null) {
//   if (user === null) {
//     window.Intercom?.('update', {
//       user_id: '',
//       name: '',
//       email: '',
//     });
//   } else {
//     window.Intercom?.('update', {
//       user_id: String(user.id),
//       name: user.name,
//       email: user.email,
//     });
//   }
// }
