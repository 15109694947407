import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  VStack,
  Button,
  FormLabel,
  Input,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import type { RefObject } from 'react';
import { useRef, useState } from 'react';

import { useUploadONNXModel } from '../../api/onnxModelApi';
import { Icons } from '../../components/Icons';
import { useShowMessage } from '../../components/useShowMessage';

type ModelNewModalFormProps = {
  formId: string;
  onnxModelFile: Blob;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onClose: () => void;
  onSave: (onnxModelFile: Blob) => void;
};

function ModelNewForm(props: ModelNewModalFormProps) {
  const {
    formId,
    onnxModelFile: initialONNXModelFile,
    focusElRef,
    onSave,
  } = props;

  const [onnxModelFileCreate, setONNXModelFileCreate] =
    useState(initialONNXModelFile);
  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(onnxModelFileCreate);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('ONNX model')}</FormLabel>
        <Input
          type="file"
          ref={focusElRef}
          placeholder={t('Enter the model file')}
          accept=".onnx"
          onChange={(event) => {
            const onnxModelFile = event.target.files
              ? event.target.files[0]
              : undefined;
            if (onnxModelFile !== undefined) {
              setONNXModelFileCreate(onnxModelFile);
            }
          }}
        />
      </FormControl>
    </VStack>
  );
}

type ModelNewModalProps = {
  newONNXModelFile: Blob | null;
  onClose: () => void;
  onSave: (newONNXModelFile: Blob) => void;
};

export function ModelNewModal(props: ModelNewModalProps) {
  const { newONNXModelFile, onClose, onSave } = props;
  const showMessage = useShowMessage();
  const focusElRef = useRef<HTMLInputElement>(null);

  const uploadONNXModel = useUploadONNXModel();

  const [isModelUploadProcessing, setIsModelUploadProcessing] = useState(false);
  return (
    <Modal
      isOpen={newONNXModelFile !== null}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Upload new ONNX model')}</ModalHeader>

        <ModalBody>
          {newONNXModelFile ? (
            <ModelNewForm
              formId="newModelForm"
              onnxModelFile={newONNXModelFile}
              focusElRef={focusElRef}
              onSave={async (onnxModelFile) => {
                setIsModelUploadProcessing(true);
                const response = await uploadONNXModel(onnxModelFile);
                if (!response.ok) {
                  showMessage({
                    type: 'error',
                    message: t('Unexpected response status: {response}', {
                      response,
                    }),
                  });
                } else {
                  setIsModelUploadProcessing(false);
                  onSave(onnxModelFile);
                  showMessage({
                    type: 'success',
                    message: t(
                      'Your model has been uploaded and is now processing! It will show up on your dashboard in a few moments',
                    ),
                  });
                }
              }}
              onClose={onClose}
            />
          ) : null}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="newModelForm"
            colorScheme="teal"
            isLoading={isModelUploadProcessing}
            loadingText="Uploading model"
            onSubmit={async () => {}}
          >
            <HStack>
              <Icon as={Icons.CloudArrowUp} w={6} h={6} />
              <Text>{t('Upload model')}</Text>
            </HStack>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
