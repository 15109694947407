/**
 * This function is used to download the image of the graph.
 * @param dataUrl
 */
export function downloadImage(dataUrl: string, name: string) {
  const a = document.createElement('a');

  // Check if there are no whitespace in the name
  // if there are whitespaces, replace them with underscore
  if (name.includes(' ')) {
    name = name.replace(/\s/g, '_');
  }
  const fileName = `${name}.png`;

  a.setAttribute('download', fileName);
  a.setAttribute('href', dataUrl);
  a.click();
}
