import { useState, type ReactElement, type ComponentProps } from 'react';
import {
  Tab as TabButton,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  type TabListProps,
} from '@chakra-ui/react';

type TabElement = ReactElement<TabProps>;

type OneOrMore<T> = T | Array<T>;

type Props = Omit<
  ComponentProps<typeof Tabs>,
  'children' | 'index' | 'onChange'
> & {
  children: OneOrMore<TabElement | null>;
  tabListProps?: TabListProps;
  initialIndex?: number;
  onTabChange?: (index: number) => void;
};

export function TabView(props: Props) {
  const {
    tabListProps,
    children,
    initialIndex,
    onTabChange,
    variant = 'enclosed',
    ...otherProps
  } = props;
  const [tabIndex, setTabIndex] = useState(initialIndex ?? 0);
  const childList = toArray(children).filter(isNotNull);
  if (childList.length === 0) {
    return null;
  }
  const tabButtons = childList.map(({ props }, i) => (
    <TabButton key={i}>{props.title}</TabButton>
  ));
  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index);
        onTabChange?.(index);
      }}
      variant={variant}
      {...otherProps}
    >
      <TabList {...tabListProps}>{tabButtons}</TabList>
      <TabPanels>{children}</TabPanels>
    </Tabs>
  );
}

type TabProps = Omit<ComponentProps<typeof TabPanel>, 'title'> & {
  title: string;
};

export function Tab(props: TabProps) {
  const { title, ...otherProps } = props;
  return <TabPanel {...otherProps} />;
}

function toArray<T>(input: T | Array<T>): Array<T> {
  return Array.isArray(input) ? input : [];
}

function isNotNull<T>(input: T | null): input is T {
  return input !== null;
}
