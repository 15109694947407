import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  VStack,
  Text,
  Icon,
  InputLeftElement,
  InputGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useUpdateUserMe } from '../../api/settingsApi';
import { Icons } from '../../components/Icons';
import { SettingsLayout } from '../../components/SettingsLayout';
import { useShowMessage } from '../../components/useShowMessage';
import { useAuth, type User } from '../../support/Auth';

function ProfileSettingsEditForm(props: {
  user: User;
  onSave: (user: User) => void;
}) {
  const { user: initialUser, onSave } = props;

  const [user, setUser] = useState(initialUser);
  const color = useColorModeValue('gray.600', 'gray.300');

  return (
    <VStack
      as="form"
      px={6}
      spacing={6}
      align="flex-end"
      onSubmit={(event) => {
        event.preventDefault();
        onSave(user);
      }}
    >
      <FormControl>
        <FormLabel>{t('Email')}</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={Icons.Envelope} w={6} h={6} color={color} />}
          />
          <Input
            type="email"
            placeholder={t('Enter your email here')}
            value={user.email}
            onChange={(event) => {
              const email = event.target.value;
              setUser((user) => ({ ...user, email }));
            }}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('Full Name')}</FormLabel>

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={Icons.User} w={6} h={6} color={color} />}
          />
          <Input
            type="text"
            placeholder={t('Enter your full name here')}
            value={user.name ?? ''}
            onChange={(event) => {
              const name = event.target.value;
              setUser((user) => ({ ...user, name }));
            }}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('Phone Number')}</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={Icons.Phone} w={6} h={6} color={color} />}
          />
          <Input
            type="tel"
            placeholder={t('Enter your phone number here')}
            value={user.phoneNumber}
            onChange={(event) => {
              const phoneNumber = event.target.value;
              setUser((user) => ({ ...user, phoneNumber }));
            }}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('Location')}</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={Icons.MapPin} w={6} h={6} color={color} />}
          />
          <Input
            type="text"
            placeholder={t('Enter your address here')}
            value={user.address}
            onChange={(event) => {
              const address = event.target.value;
              setUser((user) => ({ ...user, address }));
            }}
          />
        </InputGroup>
      </FormControl>
      <Button type="submit" colorScheme="teal">
        {t('Save Changes')}
      </Button>
    </VStack>
  );
}

export function ProfileSettings() {
  const { user, setCurrentUser } = useAuth();
  const [updateUser] = useUpdateUserMe();
  const showMessage = useShowMessage();
  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.600', 'gray.300');

  if (!user) {
    return null;
  }

  return (
    <SettingsLayout scrollable={true}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="sm">
            <HStack>
              <Icon as={Icons.User} w={6} h={6} color={color} />
              <Text>{t('Profile')}</Text>
            </HStack>
          </Heading>
        </HStack>
        <ProfileSettingsEditForm
          user={user}
          onSave={async (user) => {
            const result = await updateUser(user);
            if (result.isError || !result.ok || !result.data) {
              const message = result.error
                ? result.error.message
                : 'Unable to save user';
              showMessage({ type: 'error', message });
            } else {
              const newUser = result.data;
              setCurrentUser(newUser);
              showMessage({
                type: 'success',
                message: t('Profile updated successfully!'),
              });
            }
          }}
        />
      </VStack>
    </SettingsLayout>
  );
}
