import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Spacer,
  VStack,
  ModalCloseButton,
  ModalHeader,
  InputRightElement,
  FormErrorMessage,
  Text,
  Tooltip,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import type { RefObject } from 'react';
import { useRef, useState } from 'react';

import { useUpdateUserPassword } from '../../api/settingsApi';
import { Icons } from '../../components/Icons';
import { SettingsLayout } from '../../components/SettingsLayout';
import { useShowMessage } from '../../components/useShowMessage';
import { useAuth } from '../../support/Auth';

function ChangePasswordForm(props: {
  formId: string;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onSave: (currentPassword: string, newPassword: string) => void;
}) {
  const { formId, focusElRef, onSave } = props;
  const [currentPassword, setCurrentPassword] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');

  const isPasswordMatching = newPassword === reenterPassword;

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const showHideLabel = t('Show/Hide password');

  const color = useColorModeValue('gray.600', 'gray.300');

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(currentPassword, newPassword);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('Current Password')}</FormLabel>
        <InputGroup size="md">
          <Input
            type={showPassword ? 'text' : 'password'}
            ref={focusElRef}
            placeholder={t('Enter your current password')}
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Tooltip label={showHideLabel} placement="top" hasArrow={true}>
              <Button variant="link">
                <Icon
                  as={showPassword ? Icons.EyeSlash : Icons.Eye}
                  w={5}
                  h={5}
                  color={color}
                  onClick={toggleShowPassword}
                />
              </Button>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('New Password')}</FormLabel>
        <InputGroup size="md">
          <Input
            type={showPassword ? 'text' : 'password'}
            ref={focusElRef}
            placeholder={t('Enter a new password')}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Tooltip label={showHideLabel} placement="top" hasArrow={true}>
              <Button variant="link">
                <Icon
                  as={showPassword ? Icons.EyeSlash : Icons.Eye}
                  w={5}
                  h={5}
                  color={color}
                  onClick={toggleShowPassword}
                />
              </Button>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl isRequired isInvalid={!isPasswordMatching}>
        <FormLabel>{t('Enter new password again')}</FormLabel>
        <InputGroup size="md">
          <Input
            type={showPassword ? 'text' : 'password'}
            ref={focusElRef}
            placeholder={t('Enter your new password again')}
            value={reenterPassword}
            onChange={(event) => setReenterPassword(event.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Tooltip label={showHideLabel} placement="top" hasArrow={true}>
              <Button variant="link">
                <Icon
                  as={showPassword ? Icons.EyeSlash : Icons.Eye}
                  w={5}
                  h={5}
                  color={color}
                  onClick={toggleShowPassword}
                />
              </Button>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
        {isPasswordMatching ? null : (
          <FormErrorMessage>
            {t('The passwords provided do not match. Please try again.')}
          </FormErrorMessage>
        )}
      </FormControl>
    </VStack>
  );
}

function ChangePasswordModal(props: { isOpen: boolean; onClose: () => void }) {
  const { isOpen, onClose } = props;
  const { user } = useAuth();
  const showMessage = useShowMessage();
  const focusElRef = useRef<HTMLInputElement>(null);

  const [updateUserPassword] = useUpdateUserPassword();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Change Password')}</ModalHeader>

        <ModalBody>
          {isOpen && user !== null ? (
            <ChangePasswordForm
              formId="changeUserPasswordForm"
              focusElRef={focusElRef}
              onSave={async (currentPassword, newPassword) => {
                const response = await updateUserPassword(
                  currentPassword,
                  newPassword,
                );
                if (!response.ok || !response.data) {
                  if (
                    response.status === 400 ||
                    response.status === 404 ||
                    response.status === 422
                  ) {
                    showMessage({
                      type: 'error',
                      message:
                        'Failed to update password - current password is incorrect',
                    });
                  } else {
                    showMessage({
                      type: 'error',
                      message: t('Unexpected response status: {response}', {
                        response,
                      }),
                    });
                  }
                } else {
                  showMessage({
                    type: 'success',
                    message: t('Successfully changed password!'),
                  });
                  onClose();
                }
              }}
            />
          ) : null}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="changeUserPasswordForm"
            colorScheme="teal"
            onSubmit={async () => {}}
          >
            {t('Change Password')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function TwoFactorAuthModal(props: { isOpen: boolean; onClose: () => void }) {
  const { isOpen, onClose } = props;
  const { user } = useAuth();
  const focusElRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('Two-factor Authentication')}</ModalHeader>

        <ModalBody>
          <VStack spacing={3}>
            <Text as="i" fontSize="xs">
              {t(
                'Two-factor authentication is an additional layer of security by requiring more than just a password to sign in successfully.',
              )}
            </Text>
            <Divider />
            <Text>{t('Two-factor authentication support coming soon!')}</Text>
            {isOpen && user !== null
              ? // <ChangePasswordForm
                //   formId="changeUserPasswordForm"
                //   focusElRef={focusElRef}
                //   onSave={async (currentPassword, newPassword) => {
                //     const response = await updateUserPassword(
                //       currentPassword,
                //       newPassword,
                //     );
                //     if (!response.ok || !response.data) {
                //       if (
                //         response.status === 400 ||
                //         response.status === 404 ||
                //         response.status === 422
                //       ) {
                //         showMessage({
                //           type: 'error',
                //           message:
                //             'Failed to update password - current password is incorrect',
                //         });
                //       } else {
                //         showMessage({
                //           type: 'error',
                //           message: t('Unexpected response status: {response}', {
                //             response,
                //           }),
                //         });
                //       }
                //     } else {
                //       showMessage({
                //         type: 'success',
                //         message: t('Successfully changed password!'),
                //       });
                //       onClose();
                //     }
                //   }}
                // />
                null
              : null}
          </VStack>
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="twoFactorAuthForm"
            colorScheme="teal"
            onSubmit={async () => {}}
          >
            {t('Set Up Two-factor Authentication')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// function SecuritySettingsEditForm(props: {
//   user: User;
//   onSave: (user: User) => void;
// }) {
//   const { user: initialUser, onSave } = props;

//   const [user] = useState(initialUser);

//   return (
//     <VStack
//       as="form"
//       px={6}
//       align="flex-end"
//       onSubmit={(event) => {
//         event.preventDefault();
//         onSave(user);
//       }}
//     >
//       {/* <Button type="submit" colorScheme="teal">
//         {t('Save Changes')}
//       </Button> */}
//     </VStack>
//   );
// }

export function SecuritySettings() {
  const { user } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.600', 'gray.300');
  if (!user) {
    return null;
  }

  // TODO: Hardcoded for now.
  const isTwoFactorAuthEnabled = false;
  const twoFactorAuthButtonLabel =
    (isTwoFactorAuthEnabled ? 'Disable' : 'Enable') +
    ' two-factor authentication';
  return (
    <SettingsLayout scrollable={true}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="sm">
            <HStack>
              <Icon as={Icons.ShieldCheck} w={6} h={6} color={color} />
              <Text>{t('Security')}</Text>
            </HStack>
          </Heading>
        </HStack>
        {/* <SecuritySettingsEditForm
          user={user}
          onSave={async (user) => {
            const result = await updateUser(user);
            if (result.isError || !result.ok || !result.data) {
              const message = result.error
                ? result.error.message
                : 'Unable to save user';
              showMessage({ type: 'error', message });
            } else {
              const newUser = result.data;
              setCurrentUser(newUser);
            }
          }}
        /> */}
        <Heading fontWeight="medium" px={6} size="xs">
          {t('Update Password')}
        </Heading>
        <HStack px={6}>
          <Button
            variant="outline"
            colorScheme="teal"
            onClick={() => setShowChangePassword(true)}
          >
            {t('Change Your Password')}
          </Button>
          <Spacer />
        </HStack>
        <Divider />
        <Heading fontWeight="medium" px={6} size="xs">
          {t('Two-factor authentication')}
        </Heading>
        <HStack px={6}>
          <Button
            variant="outline"
            colorScheme="teal"
            onClick={() => setShowTwoFactorAuth(true)}
          >
            {twoFactorAuthButtonLabel}
          </Button>
          <Spacer />
        </HStack>
        <ChangePasswordModal
          isOpen={showChangePassword}
          onClose={() => setShowChangePassword(false)}
        />
        <TwoFactorAuthModal
          isOpen={showTwoFactorAuth}
          onClose={() => setShowTwoFactorAuth(false)}
        />
      </VStack>
    </SettingsLayout>
  );
}
