import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import posthog from 'posthog-js';

import { ScrollView } from '../components/ScrollView';
import { useShowMessage } from '../components/useShowMessage';
import { fetch } from '../support/Fetch';
import { endpoints } from '../generated/endpoints';
import type { ServerUser, ServerUserCreate } from '../types/Api';
import { HeaderBar } from '../components/HeaderBar/HeaderBar';
import { Button as NavButton } from '../components/Button';
import { PasswordInput } from '../components/PasswordInput';
import { fromServerUser } from '../api/userApi';

async function sendSignupRequest(
  username: string,
  password: string,
  fullName: string,
) {
  const body: ServerUserCreate = {
    email: username,
    password,
    full_name: fullName,
  };
  const response = await fetch(endpoints.create_user, { body });
  if (response.isError || response.status !== 200) {
    return response;
  }
  const user = response.data ?? {};
  return {
    ...response,
    data: { user },
  };
}

export function Signup() {
  const showMessage = useShowMessage();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const next = params.get('next') ?? '/';
  const loginPage = `/login?next=${next}`;

  const bg = useColorModeValue('gray.50', 'gray.700');
  const innerBg = useColorModeValue('white', 'black');

  const isPasswordMatching = password === reenterPassword;

  const doSignup = async () => {
    showMessage.closeAll();
    setLoading(true);
    const { isError, status, data } = await sendSignupRequest(
      username,
      password,
      fullName,
    );
    setLoading(false);
    if (isError) {
      showMessage({ type: 'error', message: t('Network error') });
    } else if (status === 200 || status === 201) {
      showMessage({
        type: 'success',
        message: t(
          'Account created! Please login to continue setting up your account.',
        ),
      });
      const user = fromServerUser(data as ServerUser);
      // In documentation: "Typically you'll want this to be the first call you
      // make to PostHog before sending any events with capture.
      posthog.init('phc_93uc9JJWeah4jplVwTatgHygBSV9rSvcSEfeu64nzky', {
        api_host: 'https://app.posthog.com',
      });
      posthog.identify(user.id, {
        ...user,
      });
      posthog.capture('User signed up', { email: username });
      navigate(loginPage);
    } else if (status === 400 || status === 422) {
      showMessage({
        type: 'error',
        message: data ? String(data.detail) : 'Invalid email',
      });
    } else {
      showMessage({
        type: 'error',
        message: t('Unexpected response status: {status}', { status }),
      });
    }
  };

  return (
    <>
      <HeaderBar>
        <NavButton to={loginPage} variant="outline">
          {t('Back to Login')}
        </NavButton>
      </HeaderBar>
      <ScrollView
        h="100vh"
        bg={bg}
        contentContainerProps={{
          flexGrow: 1,
          justify: 'center',
          align: 'center',
        }}
      >
        <VStack
          w="80vw"
          maxW="md"
          spacing={6}
          my="116px"
          sx={{
            // Offset from center just enough to make the _box_ look centered
            transform: `translateY(-92px)`,
          }}
        >
          <Heading fontWeight="medium" size="sm">
            {t('Sign Up to Cellulose')}
          </Heading>
          <VStack
            as="form"
            alignSelf="stretch"
            px={10}
            py={8}
            spacing={6}
            bg={innerBg}
            boxShadow="md"
            borderRadius="xl"
            onSubmit={(event) => {
              event.preventDefault();
              doSignup();
            }}
          >
            <FormControl isRequired>
              <FormLabel>{t('Email')}</FormLabel>
              <Input
                type="email"
                isDisabled={isLoading}
                placeholder={t('Enter your email')}
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('Full Name')}</FormLabel>
              <Input
                type="text"
                isDisabled={isLoading}
                placeholder={t('Enter your full name (optional)')}
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t('Password')}</FormLabel>
              <PasswordInput
                isDisabled={isLoading}
                placeholder={t('Enter your password')}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormControl>
            <FormControl isRequired isInvalid={!isPasswordMatching}>
              <FormLabel>{t('Verify Password')}</FormLabel>
              <Input
                type="password"
                isDisabled={isLoading}
                placeholder={t('Enter your password again')}
                value={reenterPassword}
                onChange={(event) => setReenterPassword(event.target.value)}
              />
              {isPasswordMatching ? null : (
                <FormErrorMessage>
                  {t('The passwords do not match. Please try again.')}
                </FormErrorMessage>
              )}
            </FormControl>
            <HStack alignSelf="stretch" justify="flex-end">
              <Button
                type="submit"
                variant="solid"
                colorScheme="teal"
                isLoading={isLoading}
              >
                {t('Sign Up')}
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </ScrollView>
    </>
  );
}
