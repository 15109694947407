import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useRef } from 'react';

import { formatTensorDataTypeVal } from '../../../support/models/formatTensorDataTypeVal';
import type { ServerONNXGraphInitializer } from '../../../types/Api';
import { TensorDataTypeBadge } from '../../../components/Models/TensorDataTypeBadge';

import { GraphNodeInitializerValueEditor } from './GraphNodeInitializerValueEditor';
import { GraphNodeInitializerDimsEditor } from './GraphNodeInitializerDimsEditor';

type InitializerModalContentHelperProps = {
  initializer: ServerONNXGraphInitializer;
};

function InitializerModalContentHelper(
  props: InitializerModalContentHelperProps,
) {
  const { initializer } = props;
  const { onnx_tensor } = initializer;
  const { dims, name } = onnx_tensor;

  const onnxTensorValue = formatTensorDataTypeVal(onnx_tensor);

  return (
    <>
      <ModalHeader fontWeight="medium">{name ?? 'N/A'}</ModalHeader>
      <ModalBody>
        <VStack align="stretch">
          <GraphNodeInitializerDimsEditor dims={dims} />
          <FormControl>
            <FormLabel>{t('Data Type: ')}</FormLabel>
            <TensorDataTypeBadge tensor={onnx_tensor} />
          </FormControl>
          <GraphNodeInitializerValueEditor value={onnxTensorValue} />
        </VStack>
      </ModalBody>
    </>
  );
}

type GraphNodeInitializerModalProps = {
  initializer: ServerONNXGraphInitializer | null;
  onClose: () => void;
};

export function GraphNodeInitializerModal(
  props: GraphNodeInitializerModalProps,
) {
  const { initializer, onClose } = props;
  const focusElRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      isOpen={initializer !== null}
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {initializer && (
          <InitializerModalContentHelper initializer={initializer} />
        )}
        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          {/* <Button variant="outline" mr={3} onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              form="newModelForm"
              colorScheme="teal"
              isLoading={isModelUploadProcessing}
              loadingText="Uploading model"
              onSubmit={async () => {}}
            >
              <HStack>
                <Icon as={Icons.CloudArrowUp} w={6} h={6} />
                <Text>{t('Upload model')}</Text>
              </HStack>
            </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
