import { useEffect, useState } from 'react';

import {
  logError,
  useFetch,
  useFetchCore,
  useMutation,
} from '../support/Fetch';
import type {
  ServerEnvironmentOrganizationRoleAdd,
  ServerEnvironmentOrganizationRoleRemove,
  ServerOrganization,
  ServerOrganizationEnvironment,
  ServerOrganizationEnvironmentCreate,
  ServerOrganizationCreate,
  ServerOrganizationMember,
  ServerOrganizationMemberCreate,
  ServerOrganizationRole,
  ServerOrganizationRoleCreate,
  ServerOrganizationRoleUpdate,
  ServerOrganizationUpdate,
  ServerUser,
} from '../types/Api';
import { endpoints } from '../generated/endpoints';

import { resolve } from './helpers/resolve';
import type { FetchState } from './helpers/types';
import { fromServerUser } from './userApi';

export function useFetchOrganizations() {
  return useFetch<Array<ServerOrganization>>(endpoints.get_organizations);
}

/**
 * Fetches an organization based on a given org id.
 */
export function useFetchOrganization(id: string) {
  type Result = {
    organization: ServerOrganization;
    orgRoles: Map<string, ServerOrganizationRole>;
    orgMembers: Array<ServerUser>;
    roleMembers: Map<string, Array<ServerUser>>;
    orgApiKeys: Array<ServerOrganizationEnvironment>;
  };
  const [fetchState, setFetchState] = useState<FetchState<Result>>({
    isLoading: true,
    data: null,
    error: null,
  });
  const fetchOrganization = useFetchCore({
    url: resolve(endpoints.get_organization, { org_id: id }),
    responseTransform: (data) =>
      fromServerOrganization(data as ServerOrganization),
  });
  const fetchOrganizationRole = useFetchCore((role: any) => {
    return {
      url: resolve(endpoints.get_organization_role, { id: role.id }),
      responseTransform: (data) =>
        fromServerOrganizationRole(data as ServerOrganizationRole),
    };
  });
  const fetchUser = useFetchCore((id: string) => {
    return {
      url: resolve(endpoints.get_user, { id }),
      responseTransform: (data) => fromServerUser(data as ServerUser),
    };
  });
  const fetchOrganizationApiKeys = useFetchCore((orgId: string) => {
    return {
      url: resolve(endpoints.get_organization_api_keys, { org_id: orgId }),
      responseTransform: (data) =>
        fromServerOrganizationAPIKeys(
          data as Array<ServerOrganizationEnvironment>,
        ),
    };
  });

  const fetchAll = async () => {
    const result = await fetchOrganization();
    if (result.isError || !result.ok || !result.data) {
      throw result.error || new Error(`Status: ${result.status}`);
    }

    const organization = result.data;
    const cachedUsers = new Map<string, ServerUser>();

    // TODO: There are ways to reduce memory further, such as not having this array altogether,
    //       but we can address this in the future.
    const orgMembers = Array<ServerUser>();
    for (const orgMemberId of organization.members) {
      const result = await fetchUser(orgMemberId);
      if (result.isError || !result.ok || !result.data) {
        throw result.error || new Error(`Status: ${result.status}`);
      }
      const user = result.data;
      orgMembers.push(user);
      cachedUsers.set(user.id, user);
    }

    const orgRoles = new Map<string, ServerOrganizationRole>();
    const roleMembers = new Map<string, Array<ServerUser>>();

    for (const role of organization.roles) {
      const result = await fetchOrganizationRole(role);
      if (result.isError || !result.ok || !result.data) {
        throw result.error || new Error(`Status: ${result.status}`);
      }
      const orgRole = result.data;
      orgRoles.set(orgRole.id, orgRole);

      const orgRoleMemberList = new Array<ServerUser>();
      for (const memberId of orgRole.members) {
        const cachedUser = cachedUsers.get(memberId);
        if (cachedUser !== undefined) {
          orgRoleMemberList.push(cachedUser);
        } else {
          const result = await fetchUser(memberId);
          if (result.isError || !result.ok || !result.data) {
            throw result.error || new Error(`Status: ${result.status}`);
          }
          const user = result.data;
          orgRoleMemberList.push(user);
        }
      }
      roleMembers.set(orgRole.id, orgRoleMemberList);
    }

    const orgApiKeyResult = await fetchOrganizationApiKeys(organization.id);
    if (
      orgApiKeyResult.isError ||
      !orgApiKeyResult.ok ||
      !orgApiKeyResult.data
    ) {
      throw (
        orgApiKeyResult.error || new Error(`Status: ${orgApiKeyResult.status}`)
      );
    }
    const orgApiKeys = orgApiKeyResult.data;

    return {
      organization,
      orgRoles,
      orgMembers,
      roleMembers,
      orgApiKeys,
    };
  };
  const fetchAndUpdateState = async () => {
    try {
      const data = await fetchAll();
      setFetchState({ isLoading: false, data, error: null });
    } catch (error) {
      logError(error);
      setFetchState({ isLoading: false, data: null, error: String(error) });
    }
  };
  useEffect(
    () => {
      fetchAndUpdateState();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { data, isLoading, error } = fetchState;
  // Returning in this way to match the way we do it in useFetch()
  return [data, { isLoading, error, refetch: fetchAndUpdateState }] as const;
}

export function useCreateOrganization() {
  return useMutation((org: ServerOrganizationCreate) => {
    return {
      url: endpoints.create_organization,
      method: 'post',
      body: {
        name: org.name,
        description: org.description ?? '',
      },
      responseTransform: (data) => fromServerOrganization(data as any),
    };
  });
}

export function useUpdateOrganization() {
  return useMutation(
    (input: { id: string; updates: ServerOrganizationUpdate }) => {
      const { id, updates } = input;
      return {
        url: resolve(endpoints.update_organization, { org_id: id }),
        method: 'put',
        body: updates,
        responseTransform: (data) =>
          fromServerOrganization(data as ServerOrganization),
      };
    },
  );
}

export function useDeleteOrganization() {
  return useMutation((org: ServerOrganization) => {
    return {
      url: resolve(endpoints.delete_organization, { org_id: org.id }),
      method: 'delete',
    };
  });
}

export function useInviteOrganizationMemberByEmail() {
  return useMutation(
    (org_id: string, orgMemberCreate: ServerOrganizationMemberCreate) => {
      return {
        url: resolve(endpoints.invite_member_to_organization_by_email, {
          org_id,
        }),
        method: 'put',
        body: orgMemberCreate,
      };
    },
  );
}

export function useRemoveOrganizationMember() {
  return useMutation((org_id: string, user_id: string) => {
    return {
      url: resolve(endpoints.remove_member_from_organization, {
        org_id,
        user_id,
      }),
      method: 'delete',
      responseTransform: (data) =>
        fromServerOrganizationMember(data as ServerOrganizationMember),
    };
  });
}

export function useCreateOrganizationRole() {
  return useMutation(
    (input: { orgId: string; orgRole: ServerOrganizationRoleCreate }) => {
      const { orgId, orgRole } = input;
      return {
        url: resolve(endpoints.create_organization_role, { org_id: orgId }),
        method: 'post',
        body: {
          description: orgRole.description ?? '',
          ...orgRole,
        },
        responseTransform: (data) =>
          fromServerOrganizationRole(data as ServerOrganizationRole),
      };
    },
  );
}

export function useUpdateOrganizationRole() {
  return useMutation(
    (input: { id: string; updates: ServerOrganizationRoleUpdate }) => {
      const { id, updates } = input;
      return {
        url: resolve(endpoints.update_organization_role, { id }),
        method: 'put',
        body: updates,
        responseTransform: (data) =>
          fromServerOrganizationRole(data as ServerOrganizationRole),
      };
    },
  );
}

export function useDeleteOrganizationRole() {
  return useMutation((id: string) => {
    return {
      url: resolve(endpoints.delete_organization_role, { id }),
      method: 'delete',
    };
  });
}

export function useCreateOrganizationAPIKey() {
  return useMutation(
    (org_id: string, apiKey: ServerOrganizationEnvironmentCreate) => {
      return {
        url: resolve(endpoints.create_organization_api_key, { org_id }),
        method: 'post',
        body: {
          ...apiKey,
        },
        responseTransform: (data) =>
          fromServerOrganizationAPIKey(data as ServerOrganizationEnvironment),
      };
    },
  );
}

export function useAddEnvironmentOrganizationRoles() {
  return useMutation(
    (
      org_id: string,
      environment: string,
      orgRoleIds: ServerEnvironmentOrganizationRoleAdd,
    ) => {
      return {
        url: resolve(endpoints.add_organization_role_to_environment, {
          org_id,
          environment,
        }),
        method: 'put',
        body: {
          ...orgRoleIds,
        },
        responseTransform: (data) =>
          fromServerOrganizationAPIKey(data as ServerOrganizationEnvironment),
      };
    },
  );
}

export function useRemoveEnvironmentOrganizationRoles() {
  return useMutation(
    (
      org_id: string,
      environment: string,
      orgRoleIds: ServerEnvironmentOrganizationRoleRemove,
    ) => {
      return {
        url: resolve(endpoints.remove_organization_roles_from_environment, {
          org_id,
          environment,
        }),
        method: 'put',
        body: {
          ...orgRoleIds,
        },
        responseTransform: (data) =>
          fromServerOrganizationAPIKey(data as ServerOrganizationEnvironment),
      };
    },
  );
}

function fromServerOrganization(org: ServerOrganization) {
  return org;
}

function fromServerOrganizationRole(orgRole: ServerOrganizationRole) {
  return orgRole;
}

function fromServerOrganizationMember(orgMember: ServerOrganizationMember) {
  return orgMember;
}

function fromServerOrganizationAPIKey(
  orgAPIKey: ServerOrganizationEnvironment,
) {
  return orgAPIKey;
}

function fromServerOrganizationAPIKeys(
  orgAPIKeys: Array<ServerOrganizationEnvironment>,
) {
  return orgAPIKeys;
}
