import {
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { FlexFill } from './FlexFill';
import { Icons } from './Icons';

type EmptyTableViewProps = {
  primaryMsg: string;
  secondaryMsg?: string | undefined;
};

export function EmptyTableView(props: EmptyTableViewProps) {
  const { primaryMsg, secondaryMsg } = props;
  const bg = useColorModeValue('gray.50', 'gray.700');
  return (
    <>
      <Flex direction="column" h="30vh" align="stretch">
        <Spacer bg={bg} />
        <FlexFill direction="row" align="stretch" bg={bg}>
          <Spacer />
          <VStack>
            <HStack>
              <Icon as={Icons.Beer} w={4} h={4} />
              <Text fontSize="lg">{primaryMsg}</Text>
              <Icon as={Icons.Beer} w={4} h={4} />
            </HStack>
            <Text fontSize="lg">{secondaryMsg}</Text>
          </VStack>
          <Spacer />
        </FlexFill>
        <Spacer bg={bg} />
      </Flex>
    </>
  );
}
