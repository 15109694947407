import type { ServerONNXAttributeProto } from '../../types/Api';
import type { ServerONNXGraphNodeAttributeType } from '../../types/Models/ONNX';

/**
 * This function takes in an attribute and based on the AttributeProto
 * AttributeType enum, formats and returns the right text representation
 * of the numerals.
 * @param attribute
 * @returns
 */
export function formatGraphNodeAttributeTypeVal(
  attribute: ServerONNXAttributeProto,
): string | undefined {
  const { type, f, i, s, floats, ints, strings } = attribute;

  const AttributeTypeToValueFields: Record<
    ServerONNXGraphNodeAttributeType,
    any
  > = {
    UNDEFINED: undefined,
    INT: i,
    FLOAT: f,
    STRING: s,
    TENSOR: undefined,
    GRAPH: undefined,
    SPARSE_TENSOR: undefined,
    TYPE_PROTO: undefined,

    FLOATS: floats?.toString(),
    INTS: ints?.toString(),
    STRINGS: strings?.toString(),
    TENSORS: undefined,
    GRAPHS: undefined,
    SPARSE_TENSORS: undefined,
    TYPE_PROTOS: undefined,
  };

  return AttributeTypeToValueFields[type as ServerONNXGraphNodeAttributeType];
}
