import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icons, type IconName } from './Icons';
import { NavLink } from './NavLink';

type Props = {
  title: string;
  icon: IconName;
};

export function ModelsNav(props: Props) {
  const { title, icon } = props;
  // const [organizations, { refetch }] = useFetchOrganizations();
  // TODO: Just a hack.
  const models = [
    { id: 1, type: 'ONNX', enabled: true },
    { id: 2, type: 'PyTorch', enabled: false },
    { id: 3, type: 'TensorFlow', enabled: false },
  ];
  const basePath = '/models';
  const location = useLocation();
  const navigate = useNavigate();
  const isActive =
    location.pathname === basePath ||
    location.pathname.startsWith(basePath + '/');

  const color = useColorModeValue('gray.700', 'gray.300');
  return (
    <Accordion flex={1} allowToggle defaultIndex={isActive ? 0 : -1}>
      <AccordionItem borderY="none">
        {/* TODO(RUL-331): This brute force approach refetches all organizations just to render
          updated names. */}
        <AccordionButton
          onClick={() => {
            navigate(basePath);
            // refetch();
          }}
        >
          <Tooltip label="List of all models" placement="top" hasArrow={true}>
            <HStack flex={1}>
              <Icon as={Icons[icon]} w={6} h={6} color={color} />
              <Text fontWeight="medium" color={color}>
                {title}
              </Text>
            </HStack>
          </Tooltip>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <VStack align="stretch">
            {(models ?? []).map((modelType) => {
              const { id, type, enabled } = modelType;
              const tooltip = enabled
                ? `List all ${type} models`
                : `${type} support coming soon!`;
              return (
                <NavLink
                  key={id}
                  to={`/models/types/${type.toLowerCase()}`}
                  title={type ?? ''}
                  tooltip={tooltip}
                />
              );
            })}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
