type ExtractTokens<T extends string> = string extends T
  ? never
  : T extends `${infer _Start}{${infer Name}}${infer Rest}`
  ? Name | ExtractTokens<Rest>
  : never;

const TOKEN = /\{(\w+)\}/g;

/**
 * Resolve an endpoint by interpolating parameters, for example:
 * resolve('/foo/{id}', { id: 1 }) => '/foo/1'
 */
export function resolve<S extends string>(
  url: S,
  params: { [K in ExtractTokens<S>]: unknown },
) {
  const values: Record<string, unknown> = params;
  return url.replace(TOKEN, (match: string, word: string) => {
    return values[word] != null ? String(values[word]) : match;
  });
}
