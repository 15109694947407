import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useAuth } from './support/Auth';
import {
  BillingSettings,
  ForgotPassword,
  Login,
  NotFoundWithHeaderAndFooter,
  OrganizationEdit,
  OrganizationList,
  OrganizationMemberInvite,
  OrganizationNew,
  ResetPassword,
  ModelEdit,
  ModelList,
  ModelVersionComparison,
  ModelVersionList,
  ProfileSettings,
  Signup,
  VerifyEmail,
  AppearanceSettings,
  PersonalApiKeySettings,
  NotificationSettings,
  SecuritySettings,
  ComingSoonWithHeaderAndFooterMain,
  ComingSoonWithHeaderAndFooterSettings,
} from './pages';

export function Router() {
  const { user } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/organizations/:id/members/invite"
          element={<OrganizationMemberInvite />}
        />
        {user ? (
          // Only render application routes if user is logged in
          <>
            {/* MODELS */}
            <Route path="/" element={<ModelList />} />
            <Route path="/models" element={<ModelList />} />
            <Route path="/models/:id" element={<ModelEdit />} />
            <Route path="/models/:id/versions" element={<ModelVersionList />} />
            {/* TODO(zheng): Route "type" back to the full list for now */}
            <Route path="/models/types/onnx" element={<ModelList />} />
            <Route
              path="/models/types/pytorch"
              element={<ComingSoonWithHeaderAndFooterMain />}
            />
            <Route
              path="/models/types/tensorflow"
              element={<ComingSoonWithHeaderAndFooterMain />}
            />
            <Route
              path="/models/:model_id/versions/:base_model_id/:feature_model_id/compare"
              element={<ModelVersionComparison />}
            />
            {/* INTEGRATIONS */}
            <Route
              path="/settings/integrations"
              element={<ComingSoonWithHeaderAndFooterSettings />}
            />
            <Route
              path="/settings/integrations/github"
              element={<ComingSoonWithHeaderAndFooterSettings />}
            />
            <Route
              path="/settings/integrations/gitlab"
              element={<ComingSoonWithHeaderAndFooterSettings />}
            />
            <Route
              path="/settings/integrations/jira"
              element={<ComingSoonWithHeaderAndFooterSettings />}
            />
            <Route
              path="/settings/integrations/linear"
              element={<ComingSoonWithHeaderAndFooterSettings />}
            />
            {/* ORGANIZATIONS */}
            <Route path="/organizations" element={<OrganizationList />} />
            <Route path="/organizations/new" element={<OrganizationNew />} />
            <Route path="/organizations/:id" element={<OrganizationEdit />} />
            {/* SETTINGS */}
            <Route path="/settings" element={<ProfileSettings />} />
            <Route path="/settings/profile" element={<ProfileSettings />} />
            <Route
              path="/settings/appearance"
              element={<AppearanceSettings />}
            />
            <Route
              path="/settings/notifications"
              element={<NotificationSettings />}
            />
            <Route
              path="/settings/personalapikeys"
              element={<PersonalApiKeySettings />}
            />
            <Route path="/settings/security" element={<SecuritySettings />} />
            <Route path="/settings/billing" element={<BillingSettings />} />
          </>
        ) : null}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email/verification" element={<VerifyEmail />} />
        <Route path="*" element={<NotFoundWithHeaderAndFooter />} />
      </Routes>
    </BrowserRouter>
  );
}
