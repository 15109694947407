/**
 * TODO: This file should be automatically generated
 */
import {
  Array,
  Dictionary,
  Lazy,
  Literal,
  Never,
  Record,
  String,
  Union,
  type Runtype,
} from 'runtypes';

import type { JSONSchemaEntity as JSONSchemaEntityType } from './types';

const JSONSchemaBoolean = Record({
  type: Literal('boolean'),
});

const JSONSchemaNumber = Record({
  type: Literal('number'),
});

const JSONSchemaString = Record({
  type: Literal('string'),
});

const JSONSchemaAny = Dictionary(Never, String);

const JSONSchemaEntity: Runtype<JSONSchemaEntityType> = Lazy(() => {
  return Union(
    JSONSchemaBoolean,
    JSONSchemaNumber,
    JSONSchemaString,
    JSONSchemaArray,
    JSONSchemaObject,
    JSONSchemaAny,
  );
});

export const JSONSchemaArray = Record({
  type: Literal('array'),
  items: JSONSchemaEntity,
});

export const JSONSchemaObject = Record({
  type: Literal('object'),
  properties: Dictionary(JSONSchemaEntity, String),
  required: Array(String).optional(),
});
