import { Badge, Tooltip, Link, Icon, Text, HStack } from '@chakra-ui/react';

import type { PRStateType } from '../../types/Pr';
import { Icons } from '../Icons';

export const GitHubBadgeColors: Record<PRStateType, string> = {
  open: 'green',
  closed: 'red',
  merged: 'purple',
  draft: 'gray',
};

export function GitHubBadge(props: {
  prState: PRStateType;
  prId: string;
  prLink?: string;
  prefix?: string;
}) {
  const { prState, prId, prLink, prefix } = props;

  const navigateToPRLink = 'Navigate to PR - coming soon!';

  const text = (prefix ?? '') + prId;
  return (
    <Tooltip label={navigateToPRLink} placement="top" hasArrow={true}>
      <Link href={prLink} isExternal>
        <Badge
          colorScheme={GitHubBadgeColors[prState as PRStateType] ?? 'gray'}
        >
          <HStack>
            <Text>{text}</Text>
            <Icon as={Icons.ArrowTopRightOnSquare} />
          </HStack>
        </Badge>
      </Link>
    </Tooltip>
  );
}
