import {
  ArrowDownIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  CheckBadgeIcon,
  CalculatorIcon,
  BanknotesIcon,
  BellIcon,
  BuildingOffice2Icon,
  CheckCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleStackIcon,
  ClipboardDocumentCheckIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CogIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FaceFrownIcon,
  EyeIcon,
  EyeSlashIcon,
  HomeIcon,
  HeartIcon,
  KeyIcon,
  LightBulbIcon,
  ListBulletIcon,
  MapPinIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  PhoneIcon,
  PlayIcon,
  PlusIcon,
  PlusSmallIcon,
  PuzzlePieceIcon,
  PrinterIcon,
  MagnifyingGlassIcon,
  ServerIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  SunIcon,
  MoonIcon,
  TrashIcon,
  UserIcon,
  UserGroupIcon,
  UserMinusIcon,
  UserPlusIcon,
  WrenchIcon,
  XMarkIcon,
  CheckIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import {
  FaBeer,
  FaGithub,
  FaGitlab,
  FaJira,
  FaWineBottle,
} from 'react-icons/fa';

export const Icons = {
  Beer: FaBeer,
  Github: FaGithub,
  Gitlab: FaGitlab,
  Jira: FaJira,
  WineBottle: FaWineBottle,
  ArrowDown: ArrowDownIcon,
  ArrowDownTray: ArrowDownTrayIcon,
  ArrowPath: ArrowPathIcon,
  ArrowRightOnRectangle: ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquare: ArrowTopRightOnSquareIcon,
  Banknotes: BanknotesIcon,
  Bell: BellIcon,
  BuildingOffice2: BuildingOffice2Icon,
  CheckBadge: CheckBadgeIcon,
  Calculator: CalculatorIcon,
  Check: CheckIcon,
  CheckCircle: CheckCircleIcon,
  ChevronUp: ChevronUpIcon,
  ChevronDown: ChevronDownIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  CircleStack: CircleStackIcon,
  ClipboardDocumentCheck: ClipboardDocumentCheckIcon,
  CloudArrowDown: CloudArrowDownIcon,
  CloudArrowUp: CloudArrowUpIcon,
  DocumentDuplicate: DocumentDuplicateIcon,
  Duplicate: DocumentDuplicateIcon, // Duplicate icon will just be document duplicate for now.
  EllipsisVertical: EllipsisVerticalIcon,
  Envelope: EnvelopeIcon,
  ExclamationCircle: ExclamationCircleIcon,
  ExclamationTriangle: ExclamationTriangleIcon,
  FaceFrown: FaceFrownIcon,
  Eye: EyeIcon,
  EyeSlash: EyeSlashIcon,
  Home: HomeIcon,
  Heart: HeartIcon,
  Key: KeyIcon,
  LightBulb: LightBulbIcon,
  ListBullet: ListBulletIcon,
  MagnifyingGlass: MagnifyingGlassIcon,
  MapPin: MapPinIcon,
  PaintBrush: PaintBrushIcon,
  Pencil: PencilSquareIcon,
  Phone: PhoneIcon,
  Play: PlayIcon,
  Plus: PlusIcon,
  PlusSmall: PlusSmallIcon,
  Printer: PrinterIcon,
  PuzzlePiece: PuzzlePieceIcon,
  Server: ServerIcon,
  Settings: CogIcon,
  ShieldCheck: ShieldCheckIcon,
  ShoppingBag: ShoppingBagIcon,
  Sun: SunIcon,
  Moon: MoonIcon,
  Trash: TrashIcon,
  User: UserIcon,
  UserGroup: UserGroupIcon,
  UserMinus: UserMinusIcon,
  UserPlus: UserPlusIcon,
  Wrench: WrenchIcon,
  XCircle: XCircleIcon,
  XMark: XMarkIcon,
};

export type IconName = keyof typeof Icons;
