import { type RefObject, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
} from '@chakra-ui/react';

import type { ServerOrganization } from '../../types/Api';

export function OrganizationEditForm(props: {
  formId: string;
  organization: ServerOrganization;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onSave: (organization: ServerOrganization) => void;
}) {
  const {
    formId,
    organization: initialOrganization,
    focusElRef,
    onSave,
  } = props;

  const [organization, setOrganization] = useState(initialOrganization);

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(organization);
      }}
    >
      <FormControl>
        <FormLabel>{t('Organization Name')}</FormLabel>
        <Input
          type="text"
          ref={focusElRef}
          placeholder={t('Enter a name for this organization')}
          value={organization.name}
          onChange={(event) => {
            const name = event.target.value;
            setOrganization((organization) => ({ ...organization, name }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Organization Description')}</FormLabel>
        <Textarea
          placeholder={t('Enter a description')}
          value={organization.description}
          onChange={(event) => {
            const description = event.target.value;
            setOrganization((organization) => ({
              ...organization,
              description,
            }));
          }}
        />
      </FormControl>
    </VStack>
  );
}
