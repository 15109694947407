import { Divider, VStack } from '@chakra-ui/react';

import { ModelsNav } from './ModelsNav';
import { OrganizationsNav } from './OrganizationsNav';

export function MainNav() {
  return (
    <VStack as="nav" align="stretch">
      <ModelsNav title="Models" icon="CircleStack" />
      <Divider />
      <OrganizationsNav title="Organizations" icon="BuildingOffice2" />
    </VStack>
  );
}
