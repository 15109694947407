import { Select, type SelectProps } from '@chakra-ui/react';

type Props<T> = Omit<SelectProps, 'value' | 'onChange'> & {
  options: Array<T>;
  selected: [T | undefined] | ((item: T, index: number) => boolean);
  onChange: (item: T, index: number) => void;
  getLabel?: (item: T) => string;
  getKey?: (item: T) => string;
};

export function Dropdown<T>(props: Props<T>) {
  const defaultLabel = (item: T) => String(item);
  const defaultKey = (item: T, index: number) => index.toString();
  const {
    options,
    selected,
    onChange,
    getLabel = defaultLabel,
    getKey = defaultKey,
    ...otherProps
  } = props;
  const isSelected = Array.isArray(selected)
    ? (item: T) => item === selected[0]
    : selected;
  let selectedKey: string | undefined;
  const renderedOptions = options.map((item, index) => {
    const key = getKey(item, index);
    if (isSelected(item, index)) {
      selectedKey = key;
    }
    return (
      <option key={key} value={key}>
        {getLabel(item)}
      </option>
    );
  });
  return (
    <Select
      value={selectedKey}
      onChange={(event) => {
        const selectedKey = event.target.value;
        const selectedIndex = options.findIndex(
          (item, index) => getKey(item, index) === selectedKey,
        );
        const selectedItem = options[selectedIndex];
        if (selectedItem) {
          onChange(selectedItem, selectedIndex);
        }
      }}
      cursor="pointer"
      {...otherProps}
    >
      {renderedOptions}
    </Select>
  );
}
