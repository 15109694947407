import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

type Props = FlexProps & {
  contentContainerProps?: FlexProps;
  scrollDirection?: 'vertical' | 'horizontal';
};

export function ScrollView(props: Props) {
  const {
    contentContainerProps,
    scrollDirection = 'vertical',
    children,
    ...otherProps
  } = props;
  const flexDirection = scrollDirection === 'vertical' ? 'column' : 'row';
  return (
    <Flex
      direction={flexDirection}
      flex="1"
      overflowY={scrollDirection === 'vertical' ? 'auto' : 'hidden'}
      overflowX={scrollDirection === 'vertical' ? 'hidden' : 'auto'}
      {...otherProps}
    >
      <Flex direction={flexDirection} {...contentContainerProps}>
        {children}
      </Flex>
    </Flex>
  );
}
