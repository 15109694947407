import type { RefObject } from 'react';
import { useRef, useState } from 'react';
import {
  Button,
  ModalCloseButton,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormControl,
  FormLabel,
  VStack,
  Input,
  Textarea,
} from '@chakra-ui/react';

import type {
  ServerOrganization,
  ServerOrganizationRole,
  ServerOrganizationRoleCreate,
} from '../../types/Api';
import { useCreateOrganizationRole } from '../../api/organizationApi';
import { useShowMessage } from '../../components/useShowMessage';
import { Dropdown } from '../../components/Dropdown';

const emptyOrgRole: ServerOrganizationRoleCreate = {
  name: '',
  description: '',
  can_read: true,
  can_update: true,
  can_run: true,
};

type NewOrgRoleModalFormProps = {
  formId: string;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onSave: (newOrgRole: ServerOrganizationRoleCreate) => void;
};

function OrganizationRoleNewModalForm(props: NewOrgRoleModalFormProps) {
  const { formId, focusElRef, onSave } = props;

  const [organizationRole, setOrganizationRole] = useState(emptyOrgRole);
  const { can_read, can_update, can_run } = organizationRole;

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(organizationRole);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('Organization Role Name')}</FormLabel>
        <Input
          type="text"
          ref={focusElRef}
          placeholder={t('Enter a name for this organization role')}
          value={organizationRole.name}
          onChange={(event) => {
            const name = event.target.value;
            setOrganizationRole((organizationRole) => ({
              ...organizationRole,
              name,
            }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Organization Role Description')}</FormLabel>
        <Textarea
          placeholder={t('Enter a description for this organization role')}
          value={organizationRole.description}
          onChange={(event) => {
            const description = event.target.value;
            setOrganizationRole((organizationRole) => ({
              ...organizationRole,
              description,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Read Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_read}
          onChange={(item) => {
            setOrganizationRole((organizationRole) => ({
              ...organizationRole,
              can_read: item.value,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Update Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_update}
          onChange={(item) => {
            setOrganizationRole((organizationRole) => ({
              ...organizationRole,
              can_update: item.value,
            }));
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Has Run Permissions?')}</FormLabel>
        <Dropdown
          options={[
            { value: true, label: 'True' },
            { value: false, label: 'False' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.value === can_run}
          onChange={(item) => {
            setOrganizationRole((organizationRole) => ({
              ...organizationRole,
              can_run: item.value,
            }));
          }}
        />
      </FormControl>
    </VStack>
  );
}

type OrgRoleNewModalProps = {
  organization: ServerOrganization | null;
  onClose: () => void;
};

export function OrganizationRoleNewModal(props: OrgRoleNewModalProps) {
  const showMessage = useShowMessage();
  const focusElRef = useRef<HTMLInputElement>(null);

  const { organization, onClose } = props;

  const [createOrganizationRole] = useCreateOrganizationRole();

  return (
    <Modal isOpen={organization !== null} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {t(`Add new role to {name}`, {
            name: organization !== null ? organization.name : 'organization',
          })}
        </ModalHeader>

        <ModalBody>
          {organization && (
            <OrganizationRoleNewModalForm
              formId="newOrganizationRoleForm"
              focusElRef={focusElRef}
              onSave={async (newOrgRole) => {
                const { id } = organization;
                // Create an empty org role, then pull out the drawer for the user
                // to fill in the rest of the org role details.
                const response = await createOrganizationRole({
                  orgId: id,
                  orgRole: newOrgRole,
                });

                const role = response.data as ServerOrganizationRole;
                if (!response.ok || !response.data) {
                  if (response.status === 404 || response.status === 422) {
                    showMessage({
                      type: 'error',
                      message: t(
                        'Invalid organization - unable to create organization role',
                      ),
                    });
                  } else {
                    showMessage({
                      type: 'error',
                      message: t('Unexpected response status: {response}', {
                        response,
                      }),
                    });
                  }
                } else {
                  onClose();
                  showMessage({
                    type: 'success',
                    message: t(
                      `Successfully created the "{orgRoleName}" role!`,
                      {
                        orgRoleName: role.name,
                      },
                    ),
                  });
                }
              }}
            />
          )}
        </ModalBody>

        <ModalFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form="newOrganizationRoleForm"
            colorScheme="teal"
          >
            {t('Add Role')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
