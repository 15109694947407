/**
 * This module contains some React hooks for fetching and mutations.
 *
 * Key features of this implementation:
 * - Handles data, loading and error state
 * - Provides `refetch` helper
 * - Transparently handles aborting requests on unmount (except for mutations)
 * - For local API calls will automatically include auth token
 * - Accepts function to dynamically determine url, method, headers, body, etc
 */

import { useCallback, useEffect, useRef, useState } from 'react';

import { fetch, type FetchOptions, type FetchResult } from './Fetch';
import { useAuth } from './Auth';

type FetchInit<R> = FetchOptions & {
  url: string;
  responseTransform?: (data: unknown) => R;
};

type FetchState<T> =
  | { state: 'init' }
  | { state: 'loading' }
  | { state: 'success'; data: T | null }
  | { state: 'aborted' }
  | { state: 'error'; error: string };

export function useFetch<T = JSONObject>(init: string | FetchInit<T>) {
  const [fetch, { data, ...others }] = useFetchLazy<[], T>(init);
  // Initiate a fetch on mount and any time the url changes
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [data, { ...others, refetch: fetch }] as const;
}

/**
 * This is the base fetch hook on which all the others are built. It does not
 * manage component state (useState) as do the others, but it does handle auth,
 * aborting and responseTransform.
 */
export function useFetchCore<A extends Array<any> = [], T = JSONObject>(
  initOpts: string | FetchInit<T> | ((...args: A) => FetchInit<T>),
  otherOpts?: { autoAbort?: boolean },
) {
  const { getAuthToken } = useAuth();
  const initOptsRef = useRef(initOpts);
  useEffect(() => {
    initOptsRef.current = initOpts;
  }, [initOpts]);
  const otherOptsRef = useRef(otherOpts);
  useEffect(() => {
    otherOptsRef.current = otherOpts;
  }, [otherOpts]);
  // The list of all abort controllers that need to be aborted at unmount.
  const controllerListRef = useRef<Array<AbortController>>([]);
  // Abort any in-progress fetch on unmount
  useEffect(
    () => () => {
      for (let abortController of controllerListRef.current) {
        abortController.abort();
      }
    },
    [],
  );
  return useCallback(
    async (...args: A): Promise<FetchResult<T>> => {
      const initOpts = initOptsRef.current;

      const opts =
        typeof initOpts === 'function'
          ? initOpts(...args)
          : typeof initOpts === 'string'
          ? { url: initOpts }
          : initOpts;
      const { url, headers: initHeaders, responseTransform, ...other } = opts;
      // Handle the case where a body is passed in directly
      if (typeof initOpts !== 'function' && args[0] != null) {
        other.body = args[0];
      }
      const autoAbort = otherOptsRef.current?.autoAbort ?? true;
      const abortController = new AbortController();
      if (autoAbort) {
        controllerListRef.current.push(abortController);
      }
      // Add Authentication headers if applicable
      const headers = new Headers(initHeaders);
      const token = getAuthToken();
      if (token && !headers.has('Authorization') && isLocal(url)) {
        headers.append('Authorization', `Bearer ${token}`);
      }
      const result = await fetch<T>(url, {
        headers,
        signal: abortController.signal,
        ...other,
      });
      if (responseTransform && result.ok && result.data) {
        result.data = responseTransform(result.data as unknown);
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/**
 * This provides you a fetch function that is not automatically called on
 * component mount; you are free to initiate the fetch in response to some user
 * action or event. It does provide loading/error state and it will
 * automatically abort the request on unmount or when a newer fetch is invoked.
 */
function useFetchLazy<A extends Array<any> = [], T = JSONObject>(
  initOpts: string | FetchInit<T> | ((...args: A) => FetchInit<T>),
  otherOpts?: { autoAbort?: boolean },
) {
  const [fetchState, setFetchState] = useState<FetchState<T>>({
    state: 'init',
  });
  const fetch = useFetchCore<A, T>(initOpts, otherOpts);
  const send = useCallback(
    async (...args: A): Promise<FetchResult<T>> => {
      setFetchState({ state: 'loading' });
      const result = await fetch(...args);
      setFetchState(getStateFromResult(result));
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return [
    send,
    {
      isLoading: fetchState.state === 'loading',
      data: fetchState.state === 'success' ? fetchState.data : null,
      error: fetchState.state === 'error' ? fetchState.error : null,
    },
  ] as const;
}

/**
 * This is effectively the same as useFetchLazy, but importantly, it won't ever
 * automatically abort a request, which is important for mutations which may not
 * be idempotent.
 */
export function useMutation<
  A extends Array<any> = [body?: unknown],
  T = JSONObject,
>(initOpts: FetchInit<T> | ((...args: A) => FetchInit<T>)) {
  return useFetchLazy<A, T>(initOpts, { autoAbort: false });
}

function getStateFromResult<T>(result: FetchResult<T>): FetchState<T> {
  if (result.isError) {
    const { error } = result;
    return error.name === 'AbortError'
      ? { state: 'aborted' }
      : { state: 'error', error: error.message };
  }
  return { state: 'success', data: result.data };
}

// TODO: Find a better way to determine if we should send auth headers or not.
function isLocal(url: string) {
  return url.startsWith('/');
}
