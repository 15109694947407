import { useRef } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
} from '@chakra-ui/react';

import type { ServerONNXModel } from '../../types/Api';
import { ModelTypeBadge } from '../../components/ModelTypeBadge';

import { ModelEditForm } from './ModelEditForm';

type Props = {
  model: ServerONNXModel | null;
  onClose: () => void;
  onSave: (model: ServerONNXModel) => void;
};

export function ModelEditDrawer(props: Props) {
  const { model, onClose, onSave } = props;
  const isOpen = model !== null;
  const focusElRef = useRef<HTMLInputElement>(null);

  const editModelLabel = model !== null ? `Edit ${model.name}` : null;
  const castedType =
    model !== null && model.type !== undefined ? model.type : 'unknown';

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      initialFocusRef={focusElRef}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <HStack spacing={3}>
            <Text>{editModelLabel}</Text>
            <ModelTypeBadge type={castedType} />
          </HStack>
        </DrawerHeader>

        <DrawerBody>
          {model ? (
            <ModelEditForm
              formId="editModelForm"
              model={model}
              focusElRef={focusElRef}
              onSave={onSave}
            />
          ) : null}
        </DrawerBody>

        <DrawerFooter borderTopWidth={1} borderTopStyle="solid">
          <Button variant="outline" mr={3} onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" form="editModelForm" colorScheme="teal">
            {t('Save')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
