/**
 * This is a convenience wrapper around the browser's built-in `fetch()` to
 * provide some basic error handling, serialization, parsing and types.
 *
 * Key features of this implementation:
 * - Request body can be a plain object or URLSearchParams (the correct
 *   Content-Type will be added)
 * - Will never throw. Returns error if network request fails
 * - Will attempt to parse response JSON but if that fails will return `null`
 *   rather than throwing.
 * - Fully typed: uses tagged union for return type; optionally can specify a
 *   type for the response; if not specified, falls back to JSONObject
 * - Smart defaults; will default to POST if body present, otherwise GET
 */

import { flags } from '../constants/general';

// TODO: Remove this
export * from './FetchHooks';

export type FetchSuccess<T> = {
  /** Indicates there was a network communication error */
  isError: false;
  /** The network communication error; always present if isError is true */
  error?: never;
  /** Indicates the response status was 2xx */
  ok: boolean;
  status: number;
  headers: Headers;
  data: T | null;
};

export type FetchError = {
  /** Indicates there was a network communication error */
  isError: true;
  /** The network communication error; always present if isError is true */
  error: Error;
  ok?: never;
  status?: never;
  headers?: never;
  data?: never;
};

export type FetchResult<T> = FetchSuccess<T> | FetchError;

type Method = 'get' | 'post' | 'put' | 'delete';

export type FetchOptions = Omit<RequestInit, 'method' | 'body'> & {
  method?: Method;
  body?: unknown;
};

const defaultOptions: RequestInit = {
  redirect: 'manual',
};

export async function fetch<T = JSONObject>(
  url: string,
  opts?: FetchOptions,
): Promise<FetchResult<T>> {
  const { method, headers, body, ...otherOpts } = opts ?? {};
  try {
    const response = await global.fetch(url, {
      ...defaultOptions,
      ...normalizeRequest({ method, headers, body }),
      ...otherOpts,
    });
    return await parseResponse<T>(response);
  } catch (e) {
    const error = e instanceof Error ? e : new Error(String(e));
    logError(error);
    return { isError: true, error };
  }
}

function normalizeRequest(opts: {
  method: Method | undefined;
  headers: HeadersInit | undefined;
  body: unknown;
}): { method: Method; headers: Headers; body?: string | null } {
  const defaultMethod = opts.body === undefined ? 'get' : 'post';
  const method = opts.method || defaultMethod;
  const headers = new Headers(opts.headers);
  if (method === 'get' || method === 'delete') {
    return { method, headers };
  }
  if (opts.body instanceof URLSearchParams) {
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return { method, headers, body: opts.body.toString() };
  }
  headers.append('Content-Type', 'application/json');
  return { method, headers, body: JSON.stringify(opts.body) ?? null };
}

async function parseResponse<T>(response: Response): Promise<FetchSuccess<T>> {
  const contentTypeHeader = response.headers.get('Content-Type') ?? '';
  const contentType = contentTypeHeader.toLowerCase().split(';')[0];
  let data: T | null = null;
  if (contentType === 'application/json') {
    try {
      data = await response.json();
    } catch (e) {}
  }
  return {
    isError: false,
    ok: response.ok,
    status: response.status,
    headers: response.headers,
    data,
  };
}

// This helps ensure we only log a given error at most once
const logged = new WeakSet();

export function logError(e: unknown) {
  const error = e instanceof Error ? e : new Error(String(e));
  if (
    logged.has(error) ||
    error.name === 'AbortError' ||
    flags.get('suppressErrorLogging')
  ) {
    return;
  }
  // eslint-disable-next-line no-console
  console.error(error);
}
