export const isTestEnvironment = process.env.NODE_ENV === 'test';
export const isProduction = process.env.NODE_ENV === 'production';
export const isDev = !isTestEnvironment && !isProduction;

const suppressErrorLogging = process.env.SUPPRESS_ERROR_LOGGING === 'true';

// These flags can be mutated to turn things on and off, e.g. during tests
export const flags = new Map(
  Object.entries({
    suppressErrorLogging,
  }),
);
