import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { MainLayout } from '../../components/MainLayout';
import { ErrorView } from '../../components/ErrorView';
import { Icons } from '../../components/Icons';
import { GitHubBadge } from '../../components/Integrations/GitHubBadge';
import { useFetchONNXModel } from '../../api/onnxModelApi';
import type { ServerONNXGraphNode, ServerONNXModel } from '../../types/Api';
import { NotFound } from '../NotFound';
import type { TensorRTPrecisionType } from '../../types/Integrations/TensorRT';

import { ModelEditor } from './ModelEditor';

type CompareModelEditorProps = {
  model: ServerONNXModel | null;
  graphNodes: Map<string, ServerONNXGraphNode>;
  supportedTRTPrecisionTypes: Map<string, Set<TensorRTPrecisionType>>;
  showSourceTray: boolean;
  searchNameQuery: string;
  searchOpTypeQuery: string;
  onSearchBarChange: (searchQuery: string) => void;
  onGraphNodeSourceClick: (graphNodeSource: string) => void;
};

function CompareModelEditor(props: CompareModelEditorProps) {
  const {
    model,
    graphNodes,
    supportedTRTPrecisionTypes,
    showSourceTray,
    searchNameQuery,
    searchOpTypeQuery,
    onSearchBarChange,
    onGraphNodeSourceClick,
  } = props;
  return (
    <>
      <HStack py={300} />
      <ModelEditor
        model={model}
        graphNodes={graphNodes}
        supportedTRTPrecisionTypes={supportedTRTPrecisionTypes}
        isCompare={true}
        showSourceTray={showSourceTray}
        searchNameQuery={searchNameQuery}
        searchOpTypeQuery={searchOpTypeQuery}
        selectedRuntime={null} // hardcode to null for now
        selectedPrecision={null} // hardcode to null for now
        onSearchBarChange={onSearchBarChange}
        onGraphNodeSourceClick={onGraphNodeSourceClick}
      />
    </>
  );
}
function ModelVersionComparisonLoader() {
  const { base_model_id, feature_model_id } = useParams();
  const mockedBasePRLink = 'https://github.com/celluloseai/cellulose/pull/58';
  const mockedFeaturePRLink =
    'https://github.com/celluloseai/cellulose/pull/60';

  const [baseData, { isLoading: baseIsLoading, error: baseError }] =
    useFetchONNXModel(base_model_id ?? '');
  const [featureData, { isLoading: featureIsLoading, error: featureError }] =
    useFetchONNXModel(feature_model_id ?? '');

  const [searchNameQuery, setSearchNameQuery] = useState<string>('');
  const [searchOpTypeQuery, setSearchOpTypeQuery] = useState<string>('');

  if (baseIsLoading || featureIsLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (baseError || featureError) {
    return (
      <>
        <ErrorView error={baseError} />
        <ErrorView error={featureError} />
      </>
    );
  }
  if (!baseData || !featureData) {
    return <NotFound />;
  }

  const {
    model: baseModel,
    graphNodes: baseGraphNodes,
    supportedTRTPrecisionTypes: baseSupportedTRTPrecisionTypes,
  } = baseData;
  const {
    model: featureModel,
    graphNodes: featureGraphNodes,
    supportedTRTPrecisionTypes: featureSupportedTRTPrecisionTypes,
  } = featureData;

  return (
    <>
      <HStack bg="gray.200" align="flex-start" px={6} py={3}>
        <VStack flex={1} align="flex-start">
          <Heading fontWeight="medium" size="xs">
            {baseModel.name}
          </Heading>
          <Text>{baseModel.description}</Text>
        </VStack>
        <VStack flex={0} align="flex-end">
          <HStack>
            <Text as="b" color="green.500">
              {t('+1')}
            </Text>
            <Text as="b" color="red.500">
              {t('-1')}
            </Text>
            <Box px={5} />
            <InputGroup w={300}>
              <InputLeftElement pointerEvents="none">
                <Icon as={Icons.MagnifyingGlass} w={5} h={5} color="gray.500" />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search by name / output"
                value={searchNameQuery}
                onChange={(event) => setSearchNameQuery(event.target.value)}
              />
            </InputGroup>
          </HStack>
        </VStack>
      </HStack>
      <Flex alignItems="center" py={6} gap={2}>
        <Spacer />
        <GitHubBadge
          prState="merged"
          prId="58"
          prLink={mockedBasePRLink}
          prefix="PR #"
        />
        <Spacer />
        <GitHubBadge
          prState="open"
          prId="60"
          prLink={mockedFeaturePRLink}
          prefix="PR #"
        />
        <Spacer />
      </Flex>
      <Flex>
        <CompareModelEditor
          model={baseModel}
          graphNodes={baseGraphNodes}
          supportedTRTPrecisionTypes={baseSupportedTRTPrecisionTypes}
          showSourceTray={true}
          searchNameQuery={searchNameQuery}
          searchOpTypeQuery={searchOpTypeQuery}
          onSearchBarChange={(newSearchQuery: string) =>
            setSearchNameQuery(newSearchQuery)
          }
          onGraphNodeSourceClick={(newSearchQuery: string) =>
            setSearchOpTypeQuery(newSearchQuery)
          }
        />
        <Divider orientation="vertical" padding={3} />
        <CompareModelEditor
          model={featureModel}
          graphNodes={featureGraphNodes}
          supportedTRTPrecisionTypes={featureSupportedTRTPrecisionTypes}
          showSourceTray={false}
          searchNameQuery={searchNameQuery}
          searchOpTypeQuery={searchOpTypeQuery}
          onSearchBarChange={(newSearchQuery: string) =>
            setSearchNameQuery(newSearchQuery)
          }
          onGraphNodeSourceClick={(newSearchQuery: string) =>
            setSearchOpTypeQuery(newSearchQuery)
          }
        />
      </Flex>
    </>
  );
}

export function ModelVersionComparison() {
  return (
    <MainLayout contentPadding={0}>
      <ModelVersionComparisonLoader />
    </MainLayout>
  );
}
