import {
  Button,
  Text,
  Tooltip,
  Spacer,
  Icon,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Flex,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

import { Icons } from '../../../components/Icons';
import { useShowMessage } from '../../../components/useShowMessage';
import {
  copiedToClipboardLabel,
  copyToClipboardLabel,
} from '../../../constants/clipboard';
import type { ServerONNXGraphInitializer } from '../../../types/Api';

import { GraphNodeInitializerModal } from './GraphNodeInitializerModal';

function GraphNodeIOTableHeaders() {
  return (
    <Thead>
      <Tr>
        <Th textAlign="center">{t('Name')}</Th>
        <Th textAlign="center">{t('Initializer')}</Th>
      </Tr>
    </Thead>
  );
}
export function GraphNodeIOTable(props: {
  names: Array<any>;
  initializers: Map<string, ServerONNXGraphInitializer>;
}) {
  const { names, initializers } = props;
  const showMessage = useShowMessage();
  const color = useColorModeValue('gray.700', 'gray.300');

  const [editInitializer, setEditInitializer] =
    useState<ServerONNXGraphInitializer | null>(null);
  const renderIONames = names.map((name) => {
    const currInitializer = initializers.get(name);
    return (
      <Tr key={name}>
        <Td>
          <Flex>
            <Text as="i">{name}</Text>
            <Spacer />
            <Tooltip
              label={copyToClipboardLabel}
              placement="top"
              hasArrow={true}
            >
              <Button
                variant="link"
                onClick={() => {
                  copy(name);
                  showMessage({
                    type: 'success',
                    message: copiedToClipboardLabel,
                  });
                }}
              >
                <Icon as={Icons.DocumentDuplicate} w={4} h={4} color={color} />
              </Button>
            </Tooltip>
          </Flex>
        </Td>
        <Td textAlign="center">
          {currInitializer ? (
            <Button
              variant="link"
              _hover={{ color, transform: 'scale(1.1)' }}
              onClick={() => {
                setEditInitializer(currInitializer);
              }}
            >
              <Text fontWeight="medium">{t('Show Values')}</Text>
            </Button>
          ) : (
            <Text as="i">{t('N/A')}</Text>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <>
      <TableContainer>
        <Table variant="striped">
          <GraphNodeIOTableHeaders />
          <Tbody>{renderIONames}</Tbody>
        </Table>
      </TableContainer>
      <GraphNodeInitializerModal
        initializer={editInitializer}
        onClose={() => {
          setEditInitializer(null);
        }}
      />
    </>
  );
}
