import {
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { Link } from '../../components/Link';
import {
  useDeleteOrganization,
  useFetchOrganizations,
} from '../../api/organizationApi';
import { Icons } from '../../components/Icons';
import { MainLayout } from '../../components/MainLayout';
import { formatDateTime } from '../../support/formatDateTime';
import type { ServerOrganization } from '../../types/Api';
import { Button } from '../../components/Button';
import { formatTooltip } from '../../support/formatTooltip';
import { useShowConfirmation } from '../../components/ConfirmationDialog';
import { EmptyTableView } from '../../components/EmptyTableView';

export function OrganizationList() {
  const showConfirmationDialog = useShowConfirmation();
  const [organizations, { refetch }] = useFetchOrganizations();
  const [deleteOrganization] = useDeleteOrganization();

  const renderOrganizations = (organizations: Array<ServerOrganization>) => {
    return organizations.map((organization) => {
      const { id, name, created_at, description } = organization;
      const editOrganizationLabel = t(`Edit "{name}"`, { name });
      const deleteOrganizationLabel = t(`Delete "{name}"`, { name });
      return (
        <Tr key={id}>
          <Td>
            <Center>
              <Tooltip
                label={formatTooltip(description)}
                placement="top"
                hasArrow={true}
              >
                <Link
                  _hover={{ transform: 'scale(1.1)' }}
                  to={`/organizations/${id}`}
                >
                  {name ?? 'generated'}
                </Link>
              </Tooltip>
            </Center>
          </Td>
          <Td textAlign="center">{formatDateTime(created_at)}</Td>
          <Td>
            <Center>
              <Link _hover={{ transform: 'scale(1.1)' }} to="" mx={2}>
                {t('View Changes')}
              </Link>
            </Center>
          </Td>
          <Td>
            <Center>
              <Tooltip
                label={editOrganizationLabel}
                placement="top"
                hasArrow={true}
              >
                <Link
                  _hover={{ transform: 'scale(1.1)' }}
                  to={`/organizations/${id}`}
                  mx={2}
                  onClick={() => {
                    // setSelectedDataSource(dataSource);
                  }}
                >
                  <Icon as={Icons.Pencil} w={6} h={6} color="gray.500" />
                </Link>
              </Tooltip>
              <Tooltip
                label={deleteOrganizationLabel}
                placement="top"
                hasArrow={true}
              >
                <Link
                  _hover={{ transform: 'scale(1.1)' }}
                  to=""
                  mx={2}
                  onClick={async () => {
                    showConfirmationDialog({
                      title: deleteOrganizationLabel + '?',
                      body: t(
                        `Are you sure you would like to delete the "{name}" organization? This cannot be recovered.`,
                        { name },
                      ),
                      onConfirm: async () => {
                        await deleteOrganization(organization);
                        refetch();
                      },
                    });
                  }}
                >
                  <Icon as={Icons.Trash} w={6} h={6} color="gray.500" />
                </Link>
              </Tooltip>
            </Center>
          </Td>
        </Tr>
      );
    });
  };

  const renderOrganizationTable = (
    organizations: Array<ServerOrganization>,
  ) => {
    return (
      <TableContainer px={6}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th textAlign="center">{t('Organization Name')}</Th>
              <Th textAlign="center">{t('Created At')}</Th>
              <Th textAlign="center">{t('Change History')}</Th>
              <Th textAlign="center">{t('Actions')}</Th>
            </Tr>
          </Thead>
          <Tbody>{renderOrganizations(organizations)}</Tbody>
        </Table>
      </TableContainer>
    );
  };
  const bg = useColorModeValue('white', 'gray.600');

  return (
    <MainLayout scrollable={true}>
      <VStack align="stretch" spacing={6}>
        <VStack
          bg={bg}
          shadow="sm"
          borderRadius="lg"
          py={5}
          spacing={5}
          align="stretch"
        >
          <HStack px={6}>
            <Heading fontWeight="medium" size="xs">
              {t('Organizations')}
            </Heading>
            <Spacer />
            <InputGroup w={300}>
              <InputLeftElement pointerEvents="none">
                <Icon as={Icons.MagnifyingGlass} w={5} h={5} color="gray.500" />
              </InputLeftElement>
              <Input type="search" placeholder={t('Search')} />
            </InputGroup>
          </HStack>
          <VStack align="left">
            {organizations && organizations.length > 0 ? (
              renderOrganizationTable(organizations)
            ) : (
              <EmptyTableView
                primaryMsg="You are not a member of an organization yet"
                secondaryMsg="Create your first organization by clicking the button below"
              />
            )}
            <HStack paddingTop={4} px={6}>
              <Spacer />
              <Button
                to="/organizations/new"
                variant="solid"
                colorScheme="teal"
              >
                <HStack>
                  <Icon as={Icons.Plus} w={4} h={4} />
                  <Text>{t('Create New Organization')}</Text>
                </HStack>
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </MainLayout>
  );
}
