// PR states
export type TensorRTCompatibilityType = 'full' | 'partial' | 'none';

// create a record of TensorRTRuntimes
export const TensorRTRuntimes = {
  'TensorRT 8.6.1': 'TensorRT 8.6.1',
  'TensorRT 8.0.1': 'TensorRT 8.0.1',
} as const;

export type TensorRTRuntimeType = keyof typeof TensorRTRuntimes;

// Write and export a type for DOUBLE, FLOAT32, FLOAT16, INT8, BOOL, INT32, INT64, and DOUBLE
export type TensorRTONNXPrecisionType =
  | 'DOUBLE'
  | 'FLOAT32'
  | 'FLOAT16'
  | 'INT8'
  | 'BOOL'
  | 'INT32'
  | 'INT64';

// Write and export TensorRT types for FP32, FP16, INT8, INT32, BOOL
export type TensorRTPrecisionType = 'FP32' | 'FP16' | 'INT8' | 'INT32' | 'BOOL';
