import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type CSSProperties,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  type EdgeChange,
  type NodeChange,
} from 'reactflow';

import { createContext } from '../../support/createContext';
import { useNetworkError } from '../../support/useNetworkError';
import type { ServerONNXGraphNode, ServerONNXModel } from '../../types/Api';
import { useUpdateONNXGraphNode } from '../../api/onnxModelApi';
import { reactFlowProOptions } from '../../support/reactFlowProOptions';
import type { TensorRTPrecisionType } from '../../types/Integrations/TensorRT';
import type {
  RuntimePrecisionType,
  RuntimeType,
} from '../../types/Integrations/Runtimes';

import { getGraphNodeLayout } from './GraphNodes/GraphNodeLayout';
import { ONNXGraphNodeWithHandles } from './GraphNodes/GraphNodeWithHandles';
import { GraphNodeTray } from './GraphNodeTray';
import { ModelEditorDrawer } from './ModelEditorDrawer';

const graphNodeFlowStyle: CSSProperties = {
  zIndex: 1,
};

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { graphNode: ONNXGraphNodeWithHandles };

type ONNXModelContext = {
  onEditPress: (onnxGraphNode: ServerONNXGraphNode) => void;
  getSearchNameQuery: () => string;
  getSearchOpTypeQuery: () => string;
  getRuntimeSelection: () => RuntimeType;
  getPrecisionSelection: () => RuntimePrecisionType;
  model: ServerONNXModel | null;
  isCompare: boolean;
  supportedTRTPrecisionTypes: Map<string, Set<TensorRTPrecisionType>>;
};
const [ONNXModelContextProvider, useONNXModelContext] =
  createContext<ONNXModelContext>('ONNXModelContext');
export { useONNXModelContext };
// const [RuleTreeContextProvider, useRuleTreeContext] =
//   createContext<Tree<ServerONNXGraphNode>>('RuleTreeContext');
// export { useRuleTreeContext };

type ModelEditorProps = {
  model: ServerONNXModel | null;
  onModelSaved?: (model: ServerONNXModel) => void;
  graphNodes: Map<string, ServerONNXGraphNode>;
  supportedTRTPrecisionTypes: Map<string, Set<TensorRTPrecisionType>>;
  showSourceTray: boolean;
  isCompare: boolean;
  searchNameQuery: string;
  searchOpTypeQuery: string;
  selectedRuntime: RuntimeType;
  selectedPrecision: RuntimePrecisionType;
  onSearchBarChange: (searchQuery: string) => void;
  onGraphNodeSourceClick: (graphNodeSource: string) => void;
};

export function ModelEditor(props: ModelEditorProps) {
  const {
    model,
    graphNodes,
    supportedTRTPrecisionTypes,
    showSourceTray,
    isCompare,
    onModelSaved,
    searchNameQuery,
    searchOpTypeQuery,
    selectedRuntime,
    selectedPrecision,
    onGraphNodeSourceClick,
  } = props;
  // const [nodeTree, setRuleTree] = useState(() => {
  //   if (model?.definition) {
  //     const { component_type: type, component_id: id } = model.definition;
  //     const rootRuleId = toLocalId(type, id);
  //     return new Tree<ServerONNXGraphNode>(rootRuleId, graphNodes);
  //   } else {
  //     return new Tree<ServerONNXGraphNode>(null);
  //   }
  // });
  // const nodeTreeRef = useRef(nodeTree);
  // useEffect(() => {
  //   nodeTreeRef.current = nodeTree;
  //   setGraph(getGraphNodeLayout(nodeTree.getNodes()));
  // }, [nodeTree]);

  const [graph, setGraph] = useState(() =>
    getGraphNodeLayout(Array.from(graphNodes.values())),
  );
  const [selectedGraphNode, setSelectedGraphNode] =
    useState<ServerONNXGraphNode | null>(null);
  const closeEditor = () => setSelectedGraphNode(null);
  // Note: If we change createONNXGraphNode to always create an empty rule, taking just
  // the type as a param, then we can remove the dependency on nodeTreeRef here.
  // const [createONNXGraphNode] = useCreateONNXGraphNode();
  const [updateONNXGraphNode] = useUpdateONNXGraphNode();
  // const [createONNXModel] = useCreateONNXModel();
  // const [updateONNXModel] = useUpdateONNXModel();
  const [handleNetworkError] = useNetworkError();

  // Here we create some refs for use in the context below. It might be a good
  // idea to try and simplify this, if possible.
  const modelRef = useRef(model);
  useEffect(() => {
    modelRef.current = model;
  }, [model]);

  const onModelSavedRef = useRef(onModelSaved);
  useEffect(() => {
    onModelSavedRef.current = onModelSaved;
  }, [onModelSaved]);

  const [nodes, edges] = graph;

  const onNodesChange = useCallback(
    (changes: Array<NodeChange>) =>
      setGraph(([nodes, edges]) => [applyNodeChanges(changes, nodes), edges]),
    [setGraph],
  );
  const onEdgesChange = useCallback(
    (changes: Array<EdgeChange>) =>
      setGraph(([nodes, edges]) => [nodes, applyEdgeChanges(changes, edges)]),
    [setGraph],
  );

  const modelContext = useMemo<ONNXModelContext>(
    () => ({
      onEditPress: (onnxGraphNode: ServerONNXGraphNode) => {
        setSelectedGraphNode(onnxGraphNode);
      },
      getSearchNameQuery: () => {
        return searchNameQuery;
      },
      getSearchOpTypeQuery: () => {
        return searchOpTypeQuery;
      },
      getRuntimeSelection: () => {
        return selectedRuntime;
      },
      getPrecisionSelection: () => {
        return selectedPrecision;
      },
      model,
      isCompare,
      supportedTRTPrecisionTypes,
    }),
    [
      searchNameQuery,
      searchOpTypeQuery,
      selectedRuntime,
      selectedPrecision,
      model,
      isCompare,
      supportedTRTPrecisionTypes,
    ],
  );

  return (
    // <RuleTreeContextProvider value={nodeTree}>
    <ONNXModelContextProvider value={modelContext}>
      <Flex flex={1} position="relative" overflowY="auto">
        <Box position="absolute" left={10} top={10} zIndex={3}>
          {showSourceTray === true ? (
            <GraphNodeTray onGraphNodeClick={onGraphNodeSourceClick} />
          ) : null}
        </Box>
        {/* <Box position="absolute" right={10} top={10} zIndex={3}>
            {isDirty ? (
              <Button
                variant="solid"
                colorScheme="brand"
                onClick={() => saveModel()}
              >
                {t('Save Changes')}
              </Button>
            ) : null}
          </Box>
          {nodeTree.isEmpty() ? (
            <Box
              position="absolute"
              left="50%"
              top="30%"
              transform="translateX(-50%) translateY(-50%)"
              zIndex={2}
            >
              <EmptyStateDropTarget
                onRuleDropped={async (data) => {
                  const { type } = data;
                  const newRule = await createAndSaveGraphNode(type);
                  const newTree = new Tree(newRule.id, [newRule]);
                  setRuleTree(newTree);
                  setDirty(true);
                }}
              />
            </Box>
          ) : null} */}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
          style={graphNodeFlowStyle}
          nodesConnectable={false}
          panOnDrag={true}
          panOnScroll={true}
          proOptions={reactFlowProOptions}
        />
      </Flex>
      <ModelEditorDrawer
        modelId={model ? model.id : ''}
        node={selectedGraphNode}
        supportedTRTPrecisionTypes={
          selectedGraphNode
            ? supportedTRTPrecisionTypes.get(selectedGraphNode.id) ?? new Set()
            : new Set()
        }
        isOpen={selectedGraphNode !== null}
        onClose={closeEditor}
        onSave={async (node, newNode) => {
          const id = model ? model.id : '';
          const response = await updateONNXGraphNode({
            model_id: id,
            id: node.id,
            updates: newNode,
          });
          if (!response.ok || !response.data) {
            throw handleNetworkError(response.error);
          }
          // setRuleTree((tree) => tree.updateNode(newNode));
          closeEditor();
        }}
      />
      {/* <RuleHistoryDrawer
          node={selectedGraphNodeHistory}
          isOpen={selectedGraphNodeHistory !== null}
          onClose={closeHistoryDrawer}
        /> */}
    </ONNXModelContextProvider>
    // </RuleTreeContextProvider>
  );
}
