import {
  Th,
  Td,
  Tr,
  Table,
  Thead,
  Tbody,
  TableContainer,
  HStack,
} from '@chakra-ui/react';

import { TensorRTPrecisionBadge } from '../../../components/Integrations/TensorRT/TensorRTPrecisionBadge';
import type { TensorRTPrecisionType } from '../../../types/Integrations/TensorRT';

export function GraphNodeRuntimeSupportTable(props: {
  supportedTRTPrecisionTypes: Set<TensorRTPrecisionType>;
}) {
  const { supportedTRTPrecisionTypes } = props;

  const renderSupportedTrtPrecisionTypes = (
    supportedTRTPrecisionTypeSet: Set<TensorRTPrecisionType>,
  ) => {
    const supportedTRTPrecisionTypesArray = Array.from(
      supportedTRTPrecisionTypeSet,
    );
    return supportedTRTPrecisionTypesArray.map((precision) => {
      return <TensorRTPrecisionBadge key={precision} type={precision} />;
    });
  };
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t('Runtime')}</Th>
            <Th textAlign="center">{t('Version')}</Th>
            <Th textAlign="center">{t('Precisions')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td textAlign="center">{t('TensorRT')}</Td>
            <Td textAlign="center">{t('8.6.1')}</Td>
            <Td textAlign="center">
              <HStack alignItems="center">
                {renderSupportedTrtPrecisionTypes(supportedTRTPrecisionTypes)}
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}
