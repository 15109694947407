import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';

import { Icons } from '../../../components/Icons';
import { TensorRTBadge } from '../../../components/Integrations/TensorRT/TensorRTBadge';
import {
  ONNXGraphNodeColors,
  ONNXGraphNodeIcons,
} from '../../../constants/GraphNodes';
import { getTensorRTCompatibilityMode } from '../../../support/integrations/getTensorRTCompatibilityMode';
import type { ServerONNXGraphNode } from '../../../types/Api';
import type { ServerONNXGraphNodeOpType } from '../../../types/Models/ONNX';
import { useONNXModelContext } from '../ModelEditor';

type Props = {
  node: ServerONNXGraphNode;
};

export function GraphNode(props: Props) {
  const { node } = props;
  const { name, op_type } = node;
  const {
    onEditPress,
    getSearchNameQuery,
    getSearchOpTypeQuery,
    getRuntimeSelection,
    getPrecisionSelection,
    supportedTRTPrecisionTypes,
    isCompare,
  } = useONNXModelContext();
  const supportedTRTPrecisionTypesSet =
    supportedTRTPrecisionTypes.get(node.id) ?? new Set();

  const searchNameQuery = getSearchNameQuery().toLowerCase();
  const searchOpTypeQuery = getSearchOpTypeQuery().toLowerCase();
  const nodeBoxWidth = 380;
  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.700', 'gray.200');

  const isSearchNameQueryMatching: boolean =
    name !== null &&
    name !== undefined &&
    name.toLowerCase().startsWith(searchNameQuery) &&
    searchNameQuery !== '';

  const isSearchOpTypeQueryMatching: boolean =
    op_type.toLowerCase() === searchOpTypeQuery && searchOpTypeQuery !== '';

  const isSearchQueryMatching: boolean =
    isSearchNameQueryMatching || isSearchOpTypeQueryMatching;

  const runtime = getRuntimeSelection();
  const precision = getPrecisionSelection();

  //   TODO(LEX-151): Handle how colors are being selected here (for comparisons).
  const getBackgroundColor = (name: string | undefined) => {
    if (isCompare === true && name === 'resnetv24_stage1_conv1_fwd') {
      return 'red.200';
    } else if (isCompare === true && name === 'add_10') {
      return 'green.200';
    } else {
      return bg;
    }
  };

  return (
    <Flex
      direction="column"
      bg={getBackgroundColor(name)}
      borderRadius="lg"
      borderWidth={isSearchQueryMatching ? 5 : 1}
      borderColor={isSearchQueryMatching ? 'red.300' : 'transparent'}
      padding={5}
      w={nodeBoxWidth}
    >
      <HStack alignSelf="stretch">
        <Flex
          bg={
            ONNXGraphNodeColors[op_type as ServerONNXGraphNodeOpType] ??
            'transparent'
          }
          padding={1}
          borderRadius={4}
        >
          <Icon
            as={ONNXGraphNodeIcons[op_type as ServerONNXGraphNodeOpType]}
            w={5}
            h={5}
            color="blackAlpha.500"
          />
        </Flex>
        <Tooltip label={name ?? ''} placement="top" hasArrow={true}>
          <Text
            fontWeight="medium"
            py={2}
            pr={2}
            cursor="pointer"
            color={color}
            _hover={{ textDecoration: 'underline', transform: 'scale(1.01)' }}
            onClick={() => {
              onEditPress(node);
            }}
          >
            {op_type}
          </Text>
        </Tooltip>
        <Spacer />
        {runtime !== null && precision !== null && (
          <TensorRTBadge
            runtimeVersion={runtime ?? 'Unknown runtime'}
            compatibility={getTensorRTCompatibilityMode(
              runtime,
              precision,
              supportedTRTPrecisionTypesSet,
            )}
          />
        )}
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t('Rule Options')}
              icon={
                <Icon as={Icons.EllipsisVertical} w={5} h={5} color={color} />
              }
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={() => onEditPress(node)}>{t('Edit')}</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </Flex>
  );
}
