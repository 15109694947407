import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Spacer,
  Textarea,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

// import { LinkButton } from '../../../components/LinkButton';
// import { ModalForm } from '../../../components/ModalForm';
// import { run } from '../../../support/wasm/json_typegen';
import { parseJson } from '../../../api/helpers/parsers';
import { Icons } from '../../../components/Icons';
import { useShowMessage } from '../../../components/useShowMessage';
import {
  copiedToClipboardLabel,
  copyToClipboardLabel,
} from '../../../constants/clipboard';

function toString(value: JSONValue | undefined) {
  return JSON.stringify(value, null, 2) ?? '';
}

function parse(input: string): {
  isValid: boolean;
  value: JSONValue | undefined;
} {
  if (input === '') {
    return { isValid: true, value: undefined };
  }
  const parsed = parseJson(input);
  //   const result = JSONSchemaObjectRuntype.validate(parsed);
  //   if (result.success) {
  if (true) {
    // clean(result.value);
    return { isValid: true, value: parsed };
  } else {
    // disabling this for now.
    return { isValid: false, value: undefined };
  }
}

type GraphNodeInitializerValueEditorProps = {
  value: string | undefined;
};

export function GraphNodeInitializerValueEditor(
  props: GraphNodeInitializerValueEditorProps,
) {
  const { value } = props;
  const color = useColorModeValue('gray.700', 'gray.300');
  const [currentValue, setCurrentValue] = useState(() => toString(value));
  const showMessage = useShowMessage();

  return (
    <FormControl>
      <FormLabel>{t('Values: ')}</FormLabel>
      <HStack>
        <Textarea
          fontFamily="mono"
          minHeight="sm"
          value={currentValue}
          onChange={(event) => {
            const input = event.target.value;
            setCurrentValue(input);
            // const parsed = parse(input);
            // setIsValid(parsed.isValid);
          }}
          onBlur={() => {
            const parsed = parse(currentValue);
            if (parsed.isValid) {
              // setCurrentValue(toString(parsed.value));
            }
          }}
        />
        <Spacer />
        <Tooltip label={copyToClipboardLabel} placement="top" hasArrow={true}>
          <Button
            variant="link"
            onClick={() => {
              copy(value ?? '');
              showMessage({
                type: 'success',
                message: copiedToClipboardLabel,
              });
            }}
          >
            <Icon as={Icons.DocumentDuplicate} w={4} h={4} color={color} />
          </Button>
        </Tooltip>
      </HStack>
    </FormControl>
  );
}
