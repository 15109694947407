import { Badge } from '@chakra-ui/react';

export const EnvironmentBadgeColors: { [environment: string]: string } = {
  Development: 'purple',
  Staging: 'blue',
  Production: 'green',
};

export function EnvironmentBadge(props: {
  environment: string;
  prefix?: string;
}) {
  const { environment, prefix } = props;
  const text = (prefix ?? '') + environment;
  return (
    <Badge colorScheme={EnvironmentBadgeColors[environment] ?? 'gray'}>
      {text}
    </Badge>
  );
}
