import { Badge } from '@chakra-ui/react';

import type { ServerModelType } from '../types/Api';

export const ModelTypeBadgeColors: Record<ServerModelType, string> = {
  onnx_model: 'blue',
  pytorch_model: 'red',
};

export const ModelTypeName: Record<ServerModelType, string> = {
  onnx_model: 'ONNX',
  pytorch_model: 'PyTorch',
};

export function ModelTypeBadge(props: {
  type: string;
  prefix?: string;
  postfix?: string;
}) {
  const { type, prefix, postfix } = props;
  const text =
    (prefix ?? '') +
    (ModelTypeName[type as ServerModelType] ?? 'Custom') +
    (postfix ?? '');
  return (
    <Badge
      colorScheme={ModelTypeBadgeColors[type as ServerModelType] ?? 'gray'}
    >
      {text}
    </Badge>
  );
}
