import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  HStack,
  Center,
  Spinner,
  Spacer,
  Divider,
} from '@chakra-ui/react';

import type { ServerONNXGraphNode } from '../../../types/Api';
import { Tab, TabView } from '../../../components/TabView';
import { FusedNodeEditor } from '../FusedNodes/FusedNodeEditor';
import { useFetchONNXGraphInitializer } from '../../../api/onnxModelApi';
import { NotFound } from '../../NotFound';
import type { TensorRTPrecisionType } from '../../../types/Integrations/TensorRT';
import { subscribeToPaidPlanLabel } from '../../../constants/paidPlanMessages';
import { isPaidUser } from '../../../support/billing/isPaidUser';
import { useFetchMyUserBillingInfo } from '../../../api/billingApi';

import { GraphNodeAttributesTable } from './GraphNodeAttributesTable';
import { GraphNodeIOTable } from './GraphNodeIOTable';
import { GraphNodeRuntimeSupportTable } from './GraphNodeRuntimeSupportTable';

export function GraphNodeEditor(props: {
  formId: string;
  modelId: string;
  node: ServerONNXGraphNode;
  supportedTRTPrecisionTypes: Set<TensorRTPrecisionType>;
  onSave: (oldNode: ServerONNXGraphNode, newNode: ServerONNXGraphNode) => void;
}) {
  const {
    formId,
    modelId,
    node: initialNode,
    supportedTRTPrecisionTypes,
    onSave,
  } = props;
  const [node, setNode] = useState(initialNode);
  let [data, { isLoading }] = useFetchONNXGraphInitializer(modelId, node);
  let [myBillingInfoData, { isLoading: myBillingInfoIsLoading }] =
    useFetchMyUserBillingInfo();

  if (isLoading || myBillingInfoIsLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (!data || !myBillingInfoData) {
    return <NotFound />;
  }
  const { inputInitializers, outputInitializers } = data;
  const { userBillingInfo } = myBillingInfoData;

  const displaySupportedRuntimes = isPaidUser(userBillingInfo);

  // TODO: Hardcoded!
  const isFused = false;

  return (
    <VStack
      as="form"
      id={formId}
      onSubmit={(event) => {
        event.preventDefault();
        onSave(initialNode, node);
      }}
    >
      <FormControl isRequired>
        <FormLabel>{t('Name')}</FormLabel>
        <Input
          type="text"
          placeholder={t('Enter a name for this node')}
          value={node.name}
          onChange={(event) => {
            const name = event.target.value;
            setNode({ ...node, name });
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>{t('Type')}</FormLabel>
        <Input
          type="text"
          placeholder={t('Enter an op type for this node')}
          value={node.op_type}
          onChange={(event) => {
            const op_type = event.target.value;
            setNode({ ...node, op_type });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Domain')}</FormLabel>
        <Input
          type="text"
          placeholder={t('Enter a domain for this node')}
          value={node.domain}
          onChange={(event) => {
            const domain = event.target.value;
            setNode({ ...node, domain });
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Doc String')}</FormLabel>
        <Input
          type="text"
          placeholder={t('Enter a doc_string for this node')}
          value={node.doc_string}
          onChange={(event) => {
            const doc_string = event.target.value;
            setNode({ ...node, doc_string });
          }}
        />
      </FormControl>
      <Divider />
      <HStack alignSelf="stretch">
        <TabView tabListProps={{ px: 6 }}>
          <Tab title="Inputs">
            {node.inputs && node.inputs.length > 0 ? (
              <GraphNodeIOTable
                names={node.inputs}
                initializers={inputInitializers}
              />
            ) : (
              <Text as="i">{t('No inputs in this node')}</Text>
            )}
          </Tab>
          <Tab title="Outputs">
            {node.outputs && node.outputs.length > 0 ? (
              <GraphNodeIOTable
                names={node.outputs}
                initializers={outputInitializers}
              />
            ) : (
              <HStack>
                <Spacer />
                <Text as="i">{t('No outputs in this node')}</Text>
                <Spacer />
              </HStack>
            )}{' '}
          </Tab>
          <Tab title="Attributes">
            {node.attributes && node.attributes.length > 0 ? (
              <GraphNodeAttributesTable attributes={node.attributes} />
            ) : (
              <HStack>
                <Spacer />
                <Text as="i">{t('No attributes in this node')}</Text>
                <Spacer />
              </HStack>
            )}
          </Tab>
          <Tab title="Supported Runtimes">
            {displaySupportedRuntimes ? (
              <GraphNodeRuntimeSupportTable
                supportedTRTPrecisionTypes={supportedTRTPrecisionTypes}
              />
            ) : (
              <HStack>
                <Spacer />
                <Text as="i">{subscribeToPaidPlanLabel}</Text>
                <Spacer />
              </HStack>
            )}
          </Tab>
          <Tab title="Sample Output Values">
            <HStack>
              <Spacer />
              <Text as="i">{t('Coming Soon!')}</Text>
              <Spacer />
            </HStack>
          </Tab>
          <Tab title="Fused">
            {isFused ? (
              <FusedNodeEditor
                node={node}
                showSourceTray={false}
                isCompare={false}
              />
            ) : (
              <HStack>
                <Spacer />
                <Text as="i">{t('Coming Soon!')}</Text>
                <Spacer />
              </HStack>
            )}
          </Tab>
        </TabView>
      </HStack>
    </VStack>
  );
}
