import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';

import {
  useCreateCheckoutSession,
  useCreateCustomerPortalSession,
  useFetchPlans,
} from '../../api/billingApi';
import { Icons } from '../../components/Icons';
import { SettingsLayout } from '../../components/SettingsLayout';
import { formatCurrency } from '../../support/formatCurrency';
import { formatTooltip } from '../../support/formatTooltip';
import { useNetworkError } from '../../support/useNetworkError';
import type { ServerPlan, ServerUserBillingInfo } from '../../types/Api';

function CurrentPlanEditor(props: {
  checkedPlans: Array<boolean>;
  index: number;
  plan: ServerPlan;
  onChange: () => void;
}) {
  const { checkedPlans, index, plan, onChange } = props;

  const { product } = plan;
  const { name } = product;

  const planType: string = String(name).toLowerCase().replace('plan', '');

  return (
    <HStack>
      <Spacer />
      <Checkbox
        isDisabled={planType.includes('enterprise')}
        isChecked={checkedPlans[index] ?? false}
        onChange={() => {
          // Only call this if a new plan goes from unchecked -> checked.
          if (!checkedPlans[index]) {
            onChange();
          }
        }}
      />
      <Spacer />
    </HStack>
  );
}

function PlanDescription(props: { plan: ServerPlan }) {
  const { plan } = props;

  const { product } = plan;
  const { name: planName } = product;

  const planType: string = String(planName).toLowerCase().replace('plan', '');

  if (planType.includes('enterprise')) {
    return (
      <List spacing={3}>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('Unlimited tracked / uploaded models')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t(
                'Unlimited total releases / updates to all tracked / uploaded models',
              )}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">{t('Volume Discount Pricing')}</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">{t('Priority Support')}</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('Enterprise service level agreements')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t(
                'Options for integrating within your environment for data security and privacy',
              )}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm" fontStyle="italic">
              {t('Reach out to sales@cellulose.ai for details')}
            </Text>
          </HStack>
        </ListItem>
      </List>
    );
  } else if (planType.includes('professional')) {
    return (
      <List spacing={3}>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('Unlimited tracked / uploaded models (billed by usage)')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t(
                'Unlimited total releases / updates to all tracked / uploaded models (billed by usage)',
              )}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('First 10 tracked models included free')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('$0.20 for every next 10 tracked models')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('First 10 total releases / updates included free')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('$0.20 for every next 10 releases / updates')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">{t('Unlimited teams and collaborators')}</Text>
          </HStack>
        </ListItem>
      </List>
    );
  } else if (planType.includes('hobby')) {
    return (
      <List spacing={3}>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">{t('3 tracked / uploaded models')}</Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">
              {t('3 total releases / updates to all tracked / uploaded models')}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <HStack>
            <ListIcon as={Icons.Check} w={4} h={4} color="green.500" />
            <Text fontSize="sm">{t('Unlimited teams and collaborators')}</Text>
          </HStack>
        </ListItem>
      </List>
    );
  } else {
    return (
      <HStack>
        <Spacer />
        <Text fontSize="sm">{t('Unknown Plan')}</Text>
        <Spacer />
      </HStack>
    );
  }
}

function PricingDetails(props: { prices: Array<any> }) {
  const { prices } = props;

  const renderUsageType = (usageType: string, interval: string) => {
    // Stripe usage type is essentially an Enum of {"metered", "licensed"}
    let unitLabel = t(`/ user / {interval}`, { interval: String(interval) });
    if (usageType === 'metered') {
      unitLabel = '/ 10 tracked models ' + unitLabel;
    }
    return <Text>{unitLabel}</Text>;
  };

  const renderPricingOverview = (prices: Array<any>) => {
    return prices.map((price) => {
      const { id, currency, recurring, lookup_key, unit_amount: cents } = price;

      const dollarsAndCents = formatCurrency(cents, currency);

      // We render Enterprise pricing differently.
      if (lookup_key === 'enterprise_plan_fee') {
        return (
          <HStack key={id}>
            <Spacer />
            <Text>{t('Custom Pricing')}</Text>
            <Spacer />
          </HStack>
        );
      }

      // skipping metered usage above the bar for now.
      if (recurring.usage_type === 'metered') {
        return null;
      }
      return (
        <HStack key={id}>
          <Spacer />
          <Text>{dollarsAndCents}</Text>
          {renderUsageType(recurring.usage_type, recurring.interval)}
          <Spacer />
        </HStack>
      );
    });
  };

  return <>{renderPricingOverview(prices)}</>;
}

function BillingSettingsHelper(props: {
  userBillingInfo: ServerUserBillingInfo;
  availablePlans: Array<ServerPlan>;
  selectedPlan: ServerPlan;
  checkedPlans: Array<boolean>;
}) {
  const {
    userBillingInfo,
    availablePlans,
    selectedPlan: initialSelectedPlan,
    checkedPlans: initialCheckedPlans,
  } = props;

  const [handleNetworkError] = useNetworkError();
  const [createCheckoutSession] = useCreateCheckoutSession();
  const [createCustomerPortalSession] = useCreateCustomerPortalSession();

  const [selectedPlan, setSelectedPlan] = useState(initialSelectedPlan);

  const [checkedPlans, setCheckedPlans] = useState(initialCheckedPlans);

  const toServerCheckoutSessionCreatePlan = (plan: ServerPlan | null) => {
    if (!plan) {
      return 'hobby';
    }
    const { product } = plan;
    const { name } = product;
    const planType: string = String(name).toLowerCase().replace('plan', '');

    if (planType.includes('enterprise')) {
      return 'enterprise';
    } else if (planType.includes('professional')) {
      return 'professional';
    } else {
      return 'hobby';
    }
  };

  const hasActivePlan = userBillingInfo.plan !== 'hobby';
  const bg = useColorModeValue('white', 'gray.600');

  const renderPlans = (plans: Array<ServerPlan>) => {
    return plans.map((plan, index) => {
      const { product, prices } = plan;
      const { id, name, description } = product;

      return (
        <GridItem bg={bg} shadow="sm" borderRadius="lg" py={5} key={String(id)}>
          <VStack align="stretch">
            <HStack px={6}>
              <Spacer />
              <Heading fontWeight="medium" size="xs" noOfLines={1}>
                <Tooltip
                  label={formatTooltip(String(description))}
                  placement="top"
                  hasArrow={true}
                >
                  <HStack>
                    <Icon as={Icons.UserGroup} w={5} h={5} />
                    <Text>{String(name)}</Text>
                  </HStack>
                </Tooltip>
              </Heading>
              <Spacer />
            </HStack>
            <Divider />
            <PricingDetails prices={prices ?? []} />
            <Divider />
            <HStack px={6}>
              <PlanDescription plan={plan} />
            </HStack>
            <Divider />
            <CurrentPlanEditor
              checkedPlans={checkedPlans ?? []}
              index={index}
              plan={plan}
              onChange={() => {
                if (!checkedPlans) {
                  return;
                }
                const newCheckedPlans = checkedPlans.map((_, currIndex) => {
                  if (currIndex === index) {
                    return true;
                  } else {
                    return false;
                  }
                });
                setCheckedPlans(newCheckedPlans);
                setSelectedPlan(plans[index] as ServerPlan);
              }}
            />
          </VStack>
        </GridItem>
      );
    });
  };

  return (
    <VStack align="stretch" spacing={6}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="xs">
            <HStack>
              <Icon as={Icons.Banknotes} w={5} h={5} />
              <Text>{t('Billing')}</Text>
            </HStack>
          </Heading>
        </HStack>
      </VStack>
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" gap={6} py={6}>
          {renderPlans(availablePlans ?? [])}
        </Grid>
      </Box>
      <HStack justify="end" px={10}>
        <Spacer />
        <Tooltip
          label={
            hasActivePlan
              ? t('Already have a subscription with us?')
              : t(
                  'You do not have an active subscription. Create one to get started',
                )
          }
          placement="top"
          hasArrow={true}
        >
          <Button
            isDisabled={!hasActivePlan}
            variant="outline"
            mr={3}
            onClick={async () => {
              const response = await createCustomerPortalSession();
              if (!response.ok || !response.data) {
                throw handleNetworkError(response.error);
              }

              const responseData = response.data[1] as {
                [key: string]: JSONValue;
              };
              const url = responseData.url;
              // TODO(RUL-589): Remove this cast and have it done in the underlying
              // useMutation call instead.
              // redirect to Stripe's provided customer portal.
              window.location.replace(url as string);
            }}
          >
            {t('Manage Your Subscription')}
          </Button>
        </Tooltip>
        <Button
          isDisabled={hasActivePlan}
          colorScheme="teal"
          onClick={async () => {
            const response = await createCheckoutSession(
              toServerCheckoutSessionCreatePlan(selectedPlan),
            );
            if (!response.ok || !response.data) {
              throw handleNetworkError(response.error);
            }
            const responseData = response.data[1] as {
              [key: string]: JSONValue;
            };
            const url = responseData.url;
            // TODO(RUL-589): Remove this cast and have it done in the underlying
            // useMutation call instead.
            // redirect to Stripe's provided checkout page.
            window.location.replace(url as string);
          }}
        >
          <Tooltip
            label={
              hasActivePlan
                ? t('You already have an existing subscription')
                : t('Start a new subscription')
            }
            placement="top"
            hasArrow={true}
          >
            <HStack>
              <Icon as={Icons.ShoppingBag} w={5} h={5} />
              <Text>{t('Subscribe')}</Text>
            </HStack>
          </Tooltip>
        </Button>
      </HStack>
    </VStack>
  );
}

export function BillingSettings() {
  let [data, { isLoading }] = useFetchPlans();

  if (isLoading || !data) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }

  const { userBillingInfo, availablePlans, selectedPlan, checkedPlans } = data;
  return (
    <SettingsLayout scrollable={true}>
      <BillingSettingsHelper
        userBillingInfo={userBillingInfo}
        availablePlans={availablePlans}
        selectedPlan={selectedPlan}
        checkedPlans={checkedPlans}
      />
    </SettingsLayout>
  );
}
