import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateOrganization } from '../../api/organizationApi';
import { Icons } from '../../components/Icons';
import { MainLayout } from '../../components/MainLayout';
import { useShowMessage } from '../../components/useShowMessage';
import { useNetworkError } from '../../support/useNetworkError';
import type {
  ServerOrganization,
  ServerOrganizationCreate,
} from '../../types/Api';

type Props = {
  onSave: (org: ServerOrganization) => void;
  onClose: () => void;
};

function OrganizationNewForm(props: Props) {
  const { onSave, onClose } = props;
  const initialOrg: ServerOrganizationCreate = {
    name: '',
    description: '',
  };
  const [handleNetworkError] = useNetworkError();
  const showMessage = useShowMessage();
  const [createOrganization] = useCreateOrganization();
  const [orgCreate, setOrgCreate] =
    useState<ServerOrganizationCreate>(initialOrg);

  const namePlaceholder = t('Example: The Boring Company etc.');
  const nameErrorMsg = t('Organization name is required.');
  const [nameInput, setNameInput] = useState<string | null>(null);
  const isNameError = nameInput === '';
  const bg = useColorModeValue('gray.50', 'gray.700');

  const descriptionPlaceholder = t('Example: We dig holes etc.');

  const handleSubmit = async () => {
    const response = await createOrganization(orgCreate);
    if (!response.ok || !response.data) {
      throw handleNetworkError(response.error);
    }
    const newOrg = response.data;
    showMessage({
      type: 'success',
      message: t(`"{name}" created successfully!`, {
        name: orgCreate.name,
      }),
    });
    onSave(newOrg);
  };

  return (
    <VStack
      bg={bg}
      align="stretch"
      spacing={5}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit();
        }
      }}
    >
      <FormControl isRequired isInvalid={isNameError}>
        <FormLabel>{t('Name')}</FormLabel>
        <Input
          type="text"
          placeholder={namePlaceholder}
          onChange={(event) => {
            const name = event.target.value;
            setNameInput(name);
            setOrgCreate((orgCreate) => ({
              ...orgCreate,
              name,
            }));
          }}
        />
        {isNameError ? (
          <FormErrorMessage>{nameErrorMsg}</FormErrorMessage>
        ) : null}
      </FormControl>
      <FormControl>
        <FormLabel>{t('Description')}</FormLabel>
        <Input
          type="text"
          placeholder={descriptionPlaceholder}
          onChange={(event) => {
            const description = event.target.value;
            setOrgCreate((orgCreate) => ({
              ...orgCreate,
              description,
            }));
          }}
        />
      </FormControl>
      <HStack justify="flex-end">
        <Button variant="outline" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          isDisabled={isNameError || nameInput === null}
          type="submit"
          colorScheme="teal"
          onClick={handleSubmit}
        >
          <HStack>
            <Icon as={Icons.Plus} w={4} h={4} />
            <Text>{t('Create New Organization')}</Text>
          </HStack>
        </Button>
      </HStack>
    </VStack>
  );
}

export function OrganizationNew() {
  const navigate = useNavigate();
  const bg = useColorModeValue('gray.50', 'gray.700');
  return (
    <MainLayout scrollable={true}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="xs">
            {t('Create Organization')}
          </Heading>
        </HStack>
        <VStack align="left" px={6}>
          <OrganizationNewForm
            onSave={(org) => {
              navigate(`/organizations/${org.id}`, { replace: true });
            }}
            onClose={() => {
              navigate('/organizations');
            }}
          />
        </VStack>
      </VStack>
    </MainLayout>
  );
}
