import { useCallback } from 'react';

import { useShowMessage } from '../components/useShowMessage';

import { logError } from './Fetch';

/**
 * Error handling helpers for network errors. For now, we provide a helper only
 * for displaying an error to the user, there is no retry logic or reporting to
 * a logging service.
 */
export function useNetworkError() {
  const showMessage = useShowMessage();
  const fallbackMessage = t('Invalid response from server');
  const handleNetworkError = useCallback(
    (error = new Error(fallbackMessage)) => {
      logError(error);
      showMessage.closeAll();
      showMessage({
        type: 'error',
        message: t('Network error: {message}', { message: error.message }),
      });
      return error;
    },
    [showMessage, fallbackMessage],
  );
  return [handleNetworkError] as const;
}
