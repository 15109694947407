import {
  Box,
  Flex,
  Tooltip,
  useColorModeValue,
  type ChakraProps,
} from '@chakra-ui/react';
import { type CSSProperties } from 'react';
import {
  Handle as ReactFlowHandle,
  Position,
  type HandleType,
  type HandleProps as ReactFlowHandleProps,
} from 'reactflow';
// default styling
import 'reactflow/dist/style.css';

import type { ServerONNXGraphNode } from '../../../types/Api';

type GraphNodeConnectionHandleProps = ChakraProps & {
  type: HandleType;
  node: ServerONNXGraphNode;
  index: number;
  total: number;
  tooltip?: string | undefined;
};

const handleStyle: CSSProperties = {
  width: 8,
  height: 8,
  borderWidth: 0,
  backgroundColor: 'transparent',
  cursor: 'default',
};

export function GraphNodeConnectionHandle(
  props: GraphNodeConnectionHandleProps,
) {
  const { type, node, index, total, tooltip, ...otherProps } = props;
  const fromLeft = (100 / (total + 1)) * (index + 1);
  const nodeName = node.name ?? '';
  return (
    <Box
      as={WrappedReactFlowHandle}
      type={type}
      id={`${nodeName}`}
      left={`${fromLeft.toFixed(1)}%`}
      style={handleStyle}
      {...otherProps}
    >
      <DropTargetHandle
        node={node}
        type={type}
        index={index}
        tooltip={tooltip}
      />
    </Box>
  );
}

function WrappedReactFlowHandle(props: Omit<ReactFlowHandleProps, 'position'>) {
  const { type } = props;
  return (
    <ReactFlowHandle
      position={type === 'source' ? Position.Bottom : Position.Top}
      {...props}
    />
  );
}

type DropTargetHandleProps = {
  node: ServerONNXGraphNode;
  type: HandleType;
  index: number;
  tooltip: string | undefined;
};

function DropTargetHandle(props: DropTargetHandleProps) {
  const { tooltip } = props;
  // const { onRuleDropped } = useONNXModelContext();
  // const hasChild = node.outputs !== undefined && node.outputs.length >= 1;
  // const isEmpty = type === 'source' && !hasChild;

  // const [{ canDrop, isOver }, dropRef] = useDrop(
  //   () => ({
  //     accept: 'RULE',
  //     collect: (monitor) => ({
  //       isOver: monitor.isOver(),
  //       canDrop: monitor.canDrop(),
  //     }),
  //     canDrop: (item) => item.inputs !== 0 && !hasChild,
  //     drop: (data: DraggableRuleData) => {
  //       onRuleDropped(rule, data, index);
  //     },
  //   }),
  //   [rule, index],
  // );

  const droppable = false;
  const hovered = false;
  const color = useColorModeValue('gray.500', 'gray.200');
  let innerContent = <Box w="5px" h="5px" borderRadius="50%" bg={color} />;

  if (tooltip) {
    innerContent = (
      <Tooltip label={tooltip} placement="top" hasArrow={true}>
        {innerContent}
      </Tooltip>
    );
  }

  return (
    <Box w={0} h={0} left="50%" top="50%" position="relative">
      <Flex
        // ref={dropRef}
        transform="translateX(-50%) translateY(-50%)"
        w="64px"
        h="64px"
        borderRadius="50%"
        sx={{
          bg: hovered ? 'green.200' : droppable ? 'gray.50' : 'transparent',
          outlineWidth: 1,
          outlineStyle: 'solid',
          outlineColor: hovered
            ? 'black'
            : droppable
            ? 'gray.200'
            : 'transparent',
        }}
        justify="center"
        align="center"
      >
        {innerContent}
      </Flex>
    </Box>
  );
}
