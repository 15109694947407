import { Button, Flex, Spacer, Text, ButtonGroup } from '@chakra-ui/react';

export function FooterBar() {
  const color = 'white';
  return (
    <>
      <Flex direction="row" align="stretch" h="60px" bg="gray.700">
        <Flex flex={1} direction="row" px={6} align="center">
          <Text color={color}>{t('Made with ❤️️ in SF by Cellulose ©')}</Text>
          <Spacer />
          <Text color={color}>{t('success@cellulose.ai')}</Text>
          <ButtonGroup gap="4" px={4}>
            <Button
              color={color}
              _hover={{ transform: 'scale(1.1)' }}
              variant="link"
              onClick={() => {
                window.location.replace(
                  'https://www.cellulose.ai/privacy-policy',
                );
              }}
            >
              <Text>{t('Privacy Policy')}</Text>
            </Button>
            <Button
              color={color}
              _hover={{ transform: 'scale(1.1)' }}
              variant="link"
              onClick={() => {
                window.location.replace('https://www.cellulose.ai/tos');
              }}
            >
              <Text>{t('Terms of Service')}</Text>
            </Button>
            <Button
              color={color}
              _hover={{ transform: 'scale(1.1)' }}
              variant="link"
              onClick={() => {
                window.location.replace('https://www.cellulose.ai/security');
              }}
            >
              <Text>{t('Security')}</Text>
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </>
  );
}
