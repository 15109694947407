import { Button as ChakraButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import type { ButtonProps } from '@chakra-ui/react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';

type Props = ButtonProps & {
  to: RouterLinkProps['to'];
};

export function Button(props: Props) {
  return <ChakraButton as={RouterLink} {...props} />;
}
