import { Badge } from '@chakra-ui/react';

import type { TensorRTPrecisionType } from '../../../types/Integrations/TensorRT';

const TensorRTPrecisionTypeColors: Record<TensorRTPrecisionType, string> = {
  INT8: 'blue',
  INT32: 'blue',
  FP16: 'orange',
  FP32: 'orange',
  BOOL: 'green',
};

/**
 * This function returns a TensorRTPrecisionBadge with the color corresponding
 * to the type.
 * @param props type: TensorRTPrecisionType
 * @returns a badge with the color corresponding to the type
 */
export function TensorRTPrecisionBadge(props: { type: TensorRTPrecisionType }) {
  const { type } = props;
  return (
    <Badge
      colorScheme={
        TensorRTPrecisionTypeColors[type as TensorRTPrecisionType] ?? 'gray'
      }
    >
      {type}
    </Badge>
  );
}
