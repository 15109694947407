import './init';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { theme } from './theme';
import { Router } from './Router';
import { AuthProvider } from './support/Auth';
import { ConfirmationDialogProvider } from './components/ConfirmationDialog';

const container = document.createElement('div');
document.body.appendChild(container);

const queryClient = new QueryClient();

ReactDOM.createRoot(container).render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <AuthProvider>
            <ConfirmationDialogProvider>
              <Router />
            </ConfirmationDialogProvider>
          </AuthProvider>
        </DndProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
