import {
  Button,
  Heading,
  HStack,
  Icon,
  VStack,
  Text,
  Tooltip,
  useColorModeValue,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
  TableContainer,
  Spacer,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

import {
  useDeletePersonalAPIKey,
  useFetchPersonalAPIKeys,
} from '../../../api/apiKeyApi';
import { useShowConfirmation } from '../../../components/ConfirmationDialog';
import { EmptyTableView } from '../../../components/EmptyTableView';
import { Icons } from '../../../components/Icons';
import { SettingsLayout } from '../../../components/SettingsLayout';
import { useShowMessage } from '../../../components/useShowMessage';
import { useAuth } from '../../../support/Auth';
import { formatDateTime } from '../../../support/formatDateTime';
import type {
  ServerPersonalAPIKey,
  ServerPersonalAPIKeyCreate,
} from '../../../types/Api';

import { PersonalAPIKeyNewModal } from './PersonalAPIKeyNewModal';

export function PersonalAPIKeyEdit(props: {
  personalApiKey: ServerPersonalAPIKey;
  onChange: () => void;
}) {
  const showConfirmationDialog = useShowConfirmation();
  const showMessage = useShowMessage();
  const color = useColorModeValue('gray.600', 'gray.300');

  const { personalApiKey, onChange } = props;

  const [deletePersonalAPIKey] = useDeletePersonalAPIKey();
  // Organization API keys.
  const [showAPIKey, setShowAPIKey] = useState(false);
  const toggleShowAPIKey = () => setShowAPIKey(!showAPIKey);

  const { id, name, created_at, api_key } = personalApiKey;
  const showHideLabel = t(`Show/Hide "{name}" API key`, {
    name,
  });
  const copyToClipboardLabel = t(`Copy "{name}" API key to clipboard`, {
    name,
  });
  const copiedToClipboardLabel = t(`Copied "{name}" API key to clipboard!`, {
    name,
  });

  const deleteApiKeyLabel = t(`Delete "{name}" API key`, {
    name,
  });

  const deletedApiKeyLabel = t(`Successfully deleted "{name}" API key!`, {
    name,
  });

  return (
    <Tr key={id}>
      <Td textAlign="center">{name ?? 'N/A'}</Td>
      <Td textAlign="center">
        <Text>
          {showAPIKey ? api_key : api_key.substring(0, 5) + '﹡'.repeat(15)}
        </Text>
      </Td>
      <Td textAlign="center">{formatDateTime(created_at)}</Td>
      <Td textAlign="center">
        <Tooltip label={showHideLabel} placement="top" hasArrow={true}>
          <Button variant="link">
            <Icon
              as={showAPIKey ? Icons.EyeSlash : Icons.Eye}
              w={5}
              h={5}
              color={color}
              onClick={toggleShowAPIKey}
            />
          </Button>
        </Tooltip>
        <Tooltip label={copyToClipboardLabel} placement="top" hasArrow={true}>
          <Button
            variant="link"
            onClick={() => {
              copy(api_key);
              showMessage({
                type: 'success',
                message: copiedToClipboardLabel,
              });
            }}
          >
            <Icon as={Icons.DocumentDuplicate} w={5} h={5} color={color} />
          </Button>
        </Tooltip>
        <Tooltip label={deleteApiKeyLabel} placement="top" hasArrow={true}>
          <Button
            variant="link"
            onClick={async () => {
              showConfirmationDialog({
                title: deleteApiKeyLabel + '?',
                body: t(
                  `Are you sure you would like to delete the "{name}" API key? This cannot be recovered.`,
                  { name },
                ),
                onConfirm: async () => {
                  await deletePersonalAPIKey(personalApiKey);
                  showMessage({
                    type: 'success',
                    message: deletedApiKeyLabel,
                  });
                  onChange();
                },
              });
            }}
          >
            <Icon as={Icons.Trash} w={5} h={5} color={color} />
          </Button>
        </Tooltip>
      </Td>
    </Tr>
  );
}

export function PersonalApiKeySettings() {
  const { user } = useAuth();
  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.600', 'gray.300');

  const [newPersonalAPIKey, setNewPersonalAPIKey] =
    useState<ServerPersonalAPIKeyCreate | null>(null);
  const [personalApiKeys, { refetch }] = useFetchPersonalAPIKeys();
  const renderAPIKeys = (personalApiKeys: Array<ServerPersonalAPIKey>) => {
    return personalApiKeys.map((personalApiKey) => {
      return (
        <PersonalAPIKeyEdit
          key={personalApiKey.id}
          personalApiKey={personalApiKey}
          onChange={() => {
            refetch();
          }}
        />
      );
    });
  };

  const renderAPIKeysTable = (personalApiKeys: Array<ServerPersonalAPIKey>) => {
    return (
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th textAlign="center">{t('Name')}</Th>
              <Th textAlign="center">{t('API Key')}</Th>
              <Th textAlign="center">{t('Created At')}</Th>
              <Th textAlign="center">{t('Actions')}</Th>
            </Tr>
          </Thead>
          <Tbody>{renderAPIKeys(personalApiKeys)}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  if (!user) {
    return null;
  }

  return (
    <SettingsLayout scrollable={true}>
      <VStack
        bg={bg}
        shadow="sm"
        borderRadius="lg"
        py={5}
        spacing={5}
        align="stretch"
      >
        <HStack px={6}>
          <Heading fontWeight="medium" size="sm">
            <HStack>
              <Icon as={Icons.Key} w={6} h={6} color={color} />
              <Text>{t('Personal API Keys')}</Text>
            </HStack>
          </Heading>
        </HStack>
        <VStack
          bg={bg}
          shadow="sm"
          borderRadius="lg"
          py={5}
          spacing={5}
          align="stretch"
        >
          {personalApiKeys && personalApiKeys.length > 0 ? (
            renderAPIKeysTable(personalApiKeys)
          ) : (
            <EmptyTableView
              primaryMsg="You do not have any personal API keys"
              secondaryMsg="Create your first API key by clicking the button below"
            />
          )}
          <HStack paddingTop={4} px={6}>
            <Spacer />
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={() =>
                setNewPersonalAPIKey({
                  name: '',
                })
              }
            >
              <HStack>
                <Icon as={Icons.Plus} w={4} h={4} />
                <Text>{t('Create New Personal API Key')}</Text>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      </VStack>
      <PersonalAPIKeyNewModal
        newPersonalAPIKey={newPersonalAPIKey}
        onSave={() => {
          setNewPersonalAPIKey(null);
          refetch();
        }}
        onClose={() => setNewPersonalAPIKey(null)}
      />
    </SettingsLayout>
  );
}
