import { Button, Icon, Tooltip } from '@chakra-ui/react';

import { Icons } from '../Icons';

type NotificationHeaderProps = {
  tooltip?: string | undefined;
  onClick: () => void;
};

export function NotificationHeader(props: NotificationHeaderProps) {
  const { tooltip, onClick } = props;

  let innerContent = (
    <Button
      variant="ghost"
      _hover={{ color: 'white', transform: 'scale(1.1)' }}
      _active={{ color: 'white' }}
      onClick={async () => {
        // const response = await createCustomerPortalSession();
        // if (!response.ok || !response.data) {
        //   throw handleNetworkError(response.error);
        // }
        // const responseData = response.data[1] as {
        //   [key: string]: JSONValue;
        // };
        // const url = responseData.url;
        // // TODO(RUL-589): Remove this cast and have it done in the underlying
        // // useMutation call instead.
        // // redirect to Stripe's provided customer portal.
        // window.location.replace(url as string);
        onClick();
      }}
    >
      <Icon as={Icons.Bell} w={6} h={6} color="whiteAlpha.800" />
    </Button>
  );

  if (tooltip) {
    innerContent = (
      <Tooltip label={tooltip} placement="bottom" hasArrow={true}>
        {innerContent}
      </Tooltip>
    );
  }
  return innerContent;
}
