import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFetchOrganizations } from '../api/organizationApi';

import { Icons, type IconName } from './Icons';
import { NavLink } from './NavLink';

type Props = {
  title: string;
  icon: IconName;
};

export function OrganizationsNav(props: Props) {
  const { title, icon } = props;
  const [organizations, { refetch }] = useFetchOrganizations();
  const basePath = '/organizations';
  const location = useLocation();
  const navigate = useNavigate();
  const isActive =
    location.pathname === basePath ||
    location.pathname.startsWith(basePath + '/');
  const color = useColorModeValue('gray.700', 'gray.300');

  return (
    <Accordion flex={1} allowToggle defaultIndex={isActive ? 0 : -1}>
      <AccordionItem borderY="none">
        {/* TODO(RUL-331): This brute force approach refetches all organizations just to render
        updated names. */}
        <AccordionButton
          onClick={() => {
            navigate(basePath);
            refetch();
          }}
        >
          <HStack flex={1}>
            <Icon as={Icons[icon]} w={6} h={6} color={color} />
            <Text fontWeight="medium" color={color}>
              {title}
            </Text>
          </HStack>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <VStack align="stretch">
            {(organizations ?? []).map((organization) => {
              const { id, name } = organization;
              return (
                <NavLink
                  key={id}
                  to={`/organizations/${id}`}
                  title={name ?? ''}
                />
              );
            })}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
