import type { CSSProperties } from 'react';
import { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { Center, Spinner, Flex, Box } from '@chakra-ui/react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  type EdgeChange,
  type NodeChange,
} from 'reactflow';

import type { ServerONNXGraphNode } from '../../../types/Api';
import { NotFound } from '../../NotFound';
import { ErrorView } from '../../../components/ErrorView';
import { createContext } from '../../../support/createContext';
import { useFetchFusedONNXGraphNode } from '../../../api/onnxModelApi';
import { GraphNodeTray } from '../GraphNodeTray';
import { getGraphNodeLayout } from '../GraphNodes/GraphNodeLayout';
import { ONNXGraphNodeWithHandles } from '../GraphNodes/GraphNodeWithHandles';
import { reactFlowProOptions } from '../../../support/reactFlowProOptions';
const graphNodeFlowStyle: CSSProperties = {
  zIndex: 1,
};

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { graphNode: ONNXGraphNodeWithHandles };

type FusedNodeContext = {
  onEditPress: (onnxGraphNode: ServerONNXGraphNode) => void;
  getSearchNameQuery: () => string;
  getSearchOpTypeQuery: () => string;
  parentNode: ServerONNXGraphNode | null;
  isCompare: boolean;
};
const [FusedNodeContextProvider, useFusedNodeContext] =
  createContext<FusedNodeContext>('FusedNodeContext');
export { useFusedNodeContext };

type FusedNodeCanvasEditorProps = {
  parentNode: ServerONNXGraphNode | null;
  childNodes: Array<ServerONNXGraphNode>;
  showSourceTray: boolean;
  isCompare: boolean;
  searchNameQuery: string;
  searchOpTypeQuery: string;
  onNodeSaved?: (node: ServerONNXGraphNode) => void;
  onSearchBarChange: (searchQuery: string) => void;
  onGraphNodeSourceClick: (graphNodeSource: string) => void;
};

function FusedNodeCanvasEditor(props: FusedNodeCanvasEditorProps) {
  const {
    parentNode,
    childNodes,
    showSourceTray,
    isCompare,
    searchNameQuery,
    searchOpTypeQuery,
    onNodeSaved,
    onGraphNodeSourceClick,
  } = props;

  const [graph, setGraph] = useState(() => getGraphNodeLayout(childNodes));
  const [, setSelectedGraphNode] = useState<ServerONNXGraphNode | null>(null);
  // const closeEditor = () => setSelectedGraphNode(null);
  // Note: If we change createONNXGraphNode to always create an empty rule, taking just
  // the type as a param, then we can remove the dependency on nodeTreeRef here.
  // const [createONNXGraphNode] = useCreateONNXGraphNode();
  // const [updateONNXGraphNode] = useUpdateONNXGraphNode();
  // const [createONNXModel] = useCreateONNXModel();
  // const [updateONNXModel] = useUpdateONNXModel();
  // const [handleNetworkError] = useNetworkError();

  // Here we create some refs for use in the context below. It might be a good
  // idea to try and simplify this, if possible.
  const parentNodeRef = useRef(parentNode);
  useEffect(() => {
    parentNodeRef.current = parentNode;
  }, [parentNode]);

  const onNodeSavedRef = useRef(onNodeSaved);
  useEffect(() => {
    onNodeSavedRef.current = onNodeSaved;
  }, [onNodeSaved]);

  const [nodes, edges] = graph;

  const onNodesChange = useCallback(
    (changes: Array<NodeChange>) =>
      setGraph(([nodes, edges]) => [applyNodeChanges(changes, nodes), edges]),
    [setGraph],
  );
  const onEdgesChange = useCallback(
    (changes: Array<EdgeChange>) =>
      setGraph(([nodes, edges]) => [nodes, applyEdgeChanges(changes, edges)]),
    [setGraph],
  );

  const fusedNodeContext = useMemo<FusedNodeContext>(
    () => ({
      onEditPress: (onnxGraphNode: ServerONNXGraphNode) => {
        setSelectedGraphNode(onnxGraphNode);
      },
      getSearchNameQuery: () => {
        return searchNameQuery;
      },
      getSearchOpTypeQuery: () => {
        return searchOpTypeQuery;
      },
      parentNode,
      isCompare,
    }),
    [searchNameQuery, searchOpTypeQuery, parentNode, isCompare],
  );
  return (
    <FusedNodeContextProvider value={fusedNodeContext}>
      <Flex flex={1} position="relative">
        <Box position="absolute" left={10} top={10} zIndex={3}>
          {showSourceTray === true ? (
            <GraphNodeTray onGraphNodeClick={onGraphNodeSourceClick} />
          ) : null}
        </Box>
        {/* <Box position="absolute" right={10} top={10} zIndex={3}>
          {isDirty ? (
            <Button
              variant="solid"
              colorScheme="brand"
              onClick={() => saveModel()}
            >
              {t('Save Changes')}
            </Button>
          ) : null}
        </Box>
        {nodeTree.isEmpty() ? (
          <Box
            position="absolute"
            left="50%"
            top="30%"
            transform="translateX(-50%) translateY(-50%)"
            zIndex={2}
          >
            <EmptyStateDropTarget
              onRuleDropped={async (data) => {
                const { type } = data;
                const newRule = await createAndSaveGraphNode(type);
                const newTree = new Tree(newRule.id, [newRule]);
                setRuleTree(newTree);
                setDirty(true);
              }}
            />
          </Box>
        ) : null} */}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
          style={graphNodeFlowStyle}
          nodesConnectable={false}
          panOnDrag={true}
          panOnScroll={true}
          proOptions={reactFlowProOptions}
        />
      </Flex>
    </FusedNodeContextProvider>
  );
}

type FusedNodeEditorProps = {
  node: ServerONNXGraphNode;
  showSourceTray: boolean;
  isCompare: boolean;
};

export function FusedNodeEditor(props: FusedNodeEditorProps) {
  const { node, showSourceTray, isCompare } = props;
  const [searchNameQuery, setSearchNameQuery] = useState<string>('');
  const [searchOpTypeQuery, setSearchOpTypeQuery] = useState<string>('');

  const [data, { isLoading, error }] = useFetchFusedONNXGraphNode(node);

  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }
  if (error) {
    return <ErrorView error={error} />;
  }
  if (!data) {
    return <NotFound />;
  }
  const { parentNode, childNodes } = data;

  return (
    <FusedNodeCanvasEditor
      parentNode={parentNode}
      childNodes={childNodes}
      showSourceTray={showSourceTray}
      isCompare={isCompare}
      searchNameQuery={searchNameQuery}
      searchOpTypeQuery={searchOpTypeQuery}
      onSearchBarChange={(newSearchQuery: string) =>
        setSearchNameQuery(newSearchQuery)
      }
      onGraphNodeSourceClick={(newSearchQuery: string) =>
        setSearchOpTypeQuery(newSearchQuery)
      }
    />
  );
}
