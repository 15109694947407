import { forwardRef, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import type { LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';

type Props = Omit<ChakraLinkProps, 'href'> & {
  to: RouterLinkProps['to'];
};

export const Link = forwardRef<Props, 'a'>((props, ref) => (
  <ChakraLink as={RouterLink} ref={ref} {...props} />
));
