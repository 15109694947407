/**
 * This function formats a currency amount based on the provided currency.
 * @param cents
 * @param currency
 * @returns the formatted amount in the desired currency.
 */
export function formatCurrency(cents: number, currency: string): string {
  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
}
