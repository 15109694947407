import { useMutation } from '../support/Fetch';
import { endpoints } from '../generated/endpoints';
import { useAuth, type User } from '../support/Auth';
import type { ServerUser } from '../types/Api';

export function useUpdateUserMe() {
  const { getCurrentUser } = useAuth();
  return useMutation((user: User) => {
    return {
      url: endpoints.update_user_me,
      method: 'put',
      body: toServerUser(user),
      responseTransform: (data) => {
        const currentUser = getCurrentUser();
        const config = currentUser ? currentUser.config : {};
        return fromServerUser(data as ServerUser, config);
      },
    };
  });
}

export function useUpdateUserPassword() {
  const { getCurrentUser } = useAuth();
  const currentUser = getCurrentUser();
  return useMutation((currentPassword: string, newPassword: string) => {
    return {
      url: endpoints.update_user_me_password,
      method: 'post',
      body: {
        current_password: currentPassword,
        new_password: newPassword,
      },
      responseTransform: (data) => {
        const config = currentUser ? currentUser.config : {};
        return fromServerUser(data as ServerUser, config);
      },
    };
  });
}

export function useResendVerificationEmail() {
  const { getCurrentUser } = useAuth();
  return useMutation(() => {
    return {
      url: endpoints.resend_email_verify_email,
      method: 'post',
      responseTransform: (data) => {
        const currentUser = getCurrentUser();
        const config = currentUser ? currentUser.config : {};
        return fromServerUser(data as ServerUser, config);
      },
    };
  });
}

function fromServerUser(
  user: ServerUser,
  config: Record<string, string>,
): User {
  const {
    id,
    email,
    full_name,
    phone_number,
    address,
    is_superuser,
    email_verified,
  } = user;
  return {
    id,
    name: full_name ?? '',
    email: email ?? '',
    phoneNumber: phone_number ?? '',
    address: address ?? '',
    isSuperUser: is_superuser ?? false,
    emailVerified: email_verified ?? false,
    config,
  };
}

function toServerUser(user: User) {
  const { id, name, email, phoneNumber, address, isSuperUser, emailVerified } =
    user;
  return {
    id,
    email,
    phone_number: phoneNumber,
    address,
    full_name: name ?? '',
    is_active: true,
    is_superuser: isSuperUser,
    email_verified: emailVerified,
  };
}
