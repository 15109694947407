import {
  Button,
  Icon,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';

import type {
  ServerOrganization,
  ServerOrganizationMemberCreate,
  ServerUser,
} from '../../types/Api';
import { Icons } from '../../components/Icons';
import { useRemoveOrganizationMember } from '../../api/organizationApi';
import { useShowMessage } from '../../components/useShowMessage';
import { useShowConfirmation } from '../../components/ConfirmationDialog';

import { OrganizationMemberNewModal } from './OrganizationMemberNewModal';

export function OrganizationMembersEdit(props: {
  organization: ServerOrganization;
  orgMembers: Array<ServerUser>;
  onChange: () => void;
}) {
  const showMessage = useShowMessage();
  const showConfirmationDialog = useShowConfirmation();

  const { organization, orgMembers, onChange } = props;

  const [newOrganizationMember, setNewOrganizationMember] =
    useState<ServerOrganizationMemberCreate | null>(null);
  const [removeOrganizationMember] = useRemoveOrganizationMember();

  const addMemberToOrgLabel = t(`Add new member to "{orgName}"`, {
    orgName: organization.name,
  });

  const renderOrgMembers = (
    organization: ServerOrganization,
    orgMembers: Array<ServerUser>,
  ) => {
    return orgMembers.map((orgMember) => {
      const { id, full_name, email } = orgMember;
      const removeMemberFromOrgLabel = t(`Remove from "{orgName}"`, {
        orgName: organization.name,
      });
      const memberName = full_name ? full_name : 'N/A';
      return (
        <Tr key={id}>
          <Td>{memberName}</Td>
          <Td>{email}</Td>
          <Td>
            <Tooltip
              label={removeMemberFromOrgLabel}
              placement="top"
              hasArrow={true}
            >
              <Button
                variant="link"
                onClick={async () => {
                  showConfirmationDialog({
                    title: t(`Remove "{name}"?`, {
                      name: full_name,
                    }),
                    body: t(
                      `Are you sure you would like to remove "{name}" from the organization? This cannot be recovered.`,
                      { name: full_name },
                    ),
                    onConfirm: async () => {
                      await removeOrganizationMember(organization.id, id);
                      onChange();
                      showMessage({
                        type: 'success',
                        message: t(
                          `Successfully removed "{full_name}" from organization`,
                          {
                            full_name,
                          },
                        ),
                      });
                    },
                  });
                }}
              >
                <Icon as={Icons.UserMinus} color="gray.500" />
              </Button>
            </Tooltip>
          </Td>
        </Tr>
      );
    });
  };
  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              <Th>{t('Email')}</Th>
              <Th>{t('Actions')}</Th>
            </Tr>
          </Thead>
          <Tbody>{renderOrgMembers(organization, orgMembers)}</Tbody>
          <TableCaption textAlign="right">
            <Tooltip
              label={addMemberToOrgLabel}
              placement="top"
              hasArrow={true}
            >
              <Button
                variant="solid"
                onClick={() => {
                  const initialOrganizationMemberCreate: ServerOrganizationMemberCreate =
                    {
                      email: '',
                    };
                  setNewOrganizationMember(initialOrganizationMemberCreate);
                }}
              >
                <Icon as={Icons.UserPlus} w={6} h={6} color="gray.500" />
              </Button>
            </Tooltip>
          </TableCaption>
        </Table>
      </TableContainer>
      <OrganizationMemberNewModal
        organization={organization}
        newOrganizationMember={newOrganizationMember}
        onSave={() => {
          onChange();
          setNewOrganizationMember(null);
        }}
        onClose={() => setNewOrganizationMember(null)}
      />
    </>
  );
}
