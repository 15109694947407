import { Center, Text } from '@chakra-ui/react';

import { ResponseStatusError } from '../api/helpers/ResponseStatusError';

import { RedirectToLogin } from './RedirectToLogin';

type Props = {
  error: unknown;
};

export function ErrorView(props: Props) {
  const { error } = props;
  if (error instanceof ResponseStatusError && error.status === 403) {
    return <RedirectToLogin />;
  }
  return (
    <Center h="100%">
      <Text>{String(error)}</Text>
    </Center>
  );
}
