import { type RefObject, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Textarea,
} from '@chakra-ui/react';

import type { ServerONNXModel } from '../../types/Api';

export function ModelEditForm(props: {
  formId: string;
  model: ServerONNXModel;
  /** A ref for the form element that should receive focus */
  focusElRef: RefObject<HTMLInputElement>;
  onSave: (model: ServerONNXModel) => void;
}) {
  const { formId, model: initialModel, focusElRef, onSave } = props;

  const [model, setModel] = useState(initialModel);

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    onSave(model);
  };

  return (
    <VStack
      as="form"
      id={formId}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit(event);
        }
      }}
      onSubmit={handleSubmit}
    >
      <FormControl>
        <FormLabel>{t('Model Name')}</FormLabel>
        <Input
          type="text"
          ref={focusElRef}
          placeholder={t('Enter a name for this model')}
          value={model.name}
          onChange={(event) => {
            const name = event.target.value;
            setModel((model) => ({ ...model, name }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Description')}</FormLabel>
        <Textarea
          placeholder={t('Enter a description')}
          value={model.description}
          onChange={(event) => {
            const description = event.target.value;
            setModel((model) => ({ ...model, description }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('IR version')}</FormLabel>
        <Input
          isDisabled={true}
          type="number"
          ref={focusElRef}
          placeholder={t('Current IR version')}
          value={model.ir_version}
          onChange={(event) => {
            const ir_version = Number(event.target.value);
            setModel((model) => ({ ...model, ir_version }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Producer name')}</FormLabel>
        <Input
          isDisabled={true}
          type="text"
          ref={focusElRef}
          placeholder={t('Producer name')}
          value={
            model.producer_name === undefined || model.producer_name === ''
              ? 'N/A'
              : model.producer_name
          }
          onChange={(event) => {
            const producer_name = event.target.value;
            setModel((model) => ({ ...model, producer_name }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Producer version')}</FormLabel>
        <Input
          isDisabled={true}
          type="text"
          ref={focusElRef}
          placeholder={t('Current Producer version')}
          value={
            model.producer_version === undefined ||
            model.producer_version === ''
              ? 'N/A'
              : model.producer_version
          }
          onChange={(event) => {
            const producer_version = event.target.value;
            setModel((model) => ({ ...model, producer_version }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Domain')}</FormLabel>
        <Input
          isDisabled={true}
          type="text"
          ref={focusElRef}
          placeholder={t('Current domain')}
          value={
            model.domain === undefined || model.domain === ''
              ? 'N/A'
              : model.domain
          }
          onChange={(event) => {
            const domain = event.target.value;
            setModel((model) => ({ ...model, domain }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Model version')}</FormLabel>
        <Input
          isDisabled={true}
          type="number"
          ref={focusElRef}
          placeholder={t('Current model version')}
          value={model.model_version}
          onChange={(event) => {
            const model_version = Number(event.target.value);
            setModel((model) => ({ ...model, model_version }));
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Docstring')}</FormLabel>
        <Textarea
          isDisabled={true}
          placeholder={t('Current docstring')}
          value={
            model.doc_string === undefined || model.doc_string === ''
              ? 'N/A'
              : model.doc_string
          }
          onChange={(event) => {
            const doc_string = event.target.value;
            setModel((model) => ({ ...model, doc_string }));
          }}
        />
      </FormControl>
      {/* <FormControl isDisabled={!model.has_environment_edit_permission}>
        <FormLabel>{t('Environment')}</FormLabel>
        <Dropdown
          options={[
            { type: '' as const, label: '' },
            { type: 'Development' as const, label: 'Development' },
            { type: 'Staging' as const, label: 'Staging' },
            { type: 'Production' as const, label: 'Production' },
          ]}
          getLabel={({ label }) => label}
          selected={(item) => item.type === model.environment}
          onChange={(event) => {
            const environment = event.type;
            setModel((model) => ({ ...model, environment }));
          }}
        />
      </FormControl> */}
    </VStack>
  );
}
