import {
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Text,
  Tooltip,
  Tr,
  VStack,
  useColorModeValue,
  TableCaption,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from '@ajna/pagination';

import { useShowConfirmation } from '../../components/ConfirmationDialog';
import { ErrorView } from '../../components/ErrorView';
import { Icons } from '../../components/Icons';
import { Link } from '../../components/Link';
import { MainLayout } from '../../components/MainLayout';
import { useShowMessage } from '../../components/useShowMessage';
import { formatDateTime } from '../../support/formatDateTime';
import { formatTooltip } from '../../support/formatTooltip';
import type { ServerONNXModel, ServerUser } from '../../types/Api';
import {
  itemsPerPageLimit,
  useDeleteONNXModel,
  useFetchONNXModels,
  useUpdateONNXModel,
  useVerifyTotalModelUploadsWithinBillingQuota,
} from '../../api/onnxModelApi';
import { ModelTypeBadge } from '../../components/ModelTypeBadge';
import { GitHubBadge } from '../../components/Integrations/GitHubBadge';
import { JiraBadge } from '../../components/Integrations/JiraBadge';
import { NotFound } from '../NotFound';
import { EmptyTableView } from '../../components/EmptyTableView';

import { ModelEditDrawer } from './ModelEditDrawer';
import { ModelNewModal } from './ModelNewModal';

function ModelListTableHeaders() {
  // Tooltip labels.
  const typeLabel = 'Model type';
  const prLabel =
    'Link to PR if associated with the tracked model - coming soon!';
  const ticketLabel =
    'Link to ticket (if associated with the tracked model - coming soon!)';
  const versionsLabel = 'View all versions of this tracked model';
  const updatedByLabel = 'Author who last updated this tracked model';

  return (
    <Thead>
      <Tr>
        <Th textAlign="center">{t('Name')}</Th>
        <Tooltip label={typeLabel} placement="top" hasArrow={true}>
          <Th textAlign="center">{t('Type')}</Th>
        </Tooltip>
        <Tooltip label={prLabel} placement="top" hasArrow={true}>
          <Th textAlign="center">{t('PR #')}</Th>
        </Tooltip>
        <Tooltip label={ticketLabel} placement="top" hasArrow={true}>
          <Th textAlign="center">{t('Ticket #')}</Th>
        </Tooltip>
        <Th textAlign="center">{t('Created By')}</Th>
        <Tooltip label={updatedByLabel} placement="top" hasArrow={true}>
          <Th textAlign="center">{t('Updated By')}</Th>
        </Tooltip>
        <Tooltip label={versionsLabel} placement="top" hasArrow={true}>
          <Th textAlign="center">{t('Versions')}</Th>
        </Tooltip>
        <Th textAlign="center">{t('Actions')}</Th>
      </Tr>
    </Thead>
  );
}

function ModelListTableBody(props: {
  models: Array<ServerONNXModel>;
  modelUsers: Map<string, ServerUser>;
  currentPage: number;
  itemsPerPageLimit: number;
  onEditModelClick: (model: ServerONNXModel) => void;
  onSave: () => void;
}) {
  const {
    models: initialModels,
    modelUsers,
    currentPage,
    itemsPerPageLimit,
    onEditModelClick,
    onSave,
  } = props;
  const showConfirmationDialog = useShowConfirmation();
  const [deleteONNXModel] = useDeleteONNXModel();
  const showMessage = useShowMessage();
  const offset = (currentPage - 1) * itemsPerPageLimit;
  const models = useMemo(
    () => initialModels.slice(offset, offset + itemsPerPageLimit),
    [initialModels, offset, itemsPerPageLimit],
  );
  const color = useColorModeValue('gray.500', 'gray.200');

  return (
    <Tbody>
      {models.map((model: ServerONNXModel) => {
        const {
          name,
          type,
          description,
          id,
          owner_id,
          created_at,
          updated_at,
        } = model;
        const exportModelLabel = t(
          `Download / Export "{name}" - coming soon!`,
          {
            name,
          },
        );
        const duplicateModelLabel = t(`Duplicate "{name}" - coming soon!`, {
          name,
        });
        const editModelLabel = t(`Edit "{name}"`, { name });
        const deleteModelLabel = t(`Delete "{name}"`, { name });
        const user = modelUsers.get(owner_id);

        // Hardcoded for now.
        const prState = 'draft';
        const prId = '123';
        const ticketState = 'done';
        const ticketId = 'CELL-123';

        return (
          <Tr key={id}>
            <Td>
              <Center>
                <Tooltip
                  label={formatTooltip(description)}
                  placement="top"
                  hasArrow={true}
                >
                  <Link
                    _hover={{ transform: 'scale(1.1)' }}
                    to={`/models/${id}`}
                  >
                    {name}
                  </Link>
                </Tooltip>
              </Center>
            </Td>
            <Td textAlign="center">
              <ModelTypeBadge type={type ?? 'unknown'} />
            </Td>
            <Td textAlign="center">
              <GitHubBadge
                prState={prState}
                prId={prId}
                // prLink={mockedPRLink}
                prefix="PR #"
              />
            </Td>
            <Td textAlign="center">
              <JiraBadge
                ticketState={ticketState}
                ticketId={ticketId}
                // ticketLink={mockedTicketLink}
              />
            </Td>
            <Td textAlign="center">
              <Tooltip
                label={formatDateTime(created_at)}
                placement="top"
                hasArrow={true}
              >
                {user
                  ? user.full_name
                    ? user.full_name
                    : user.email
                  : 'unknown'}
              </Tooltip>
            </Td>
            <Td textAlign="center">
              <Tooltip
                label={formatDateTime(updated_at)}
                placement="top"
                hasArrow={true}
              >
                {user
                  ? user.full_name
                    ? user.full_name
                    : user.email
                  : 'unknown'}
              </Tooltip>
            </Td>
            <Td>
              <Center whiteSpace="nowrap">
                <Link
                  _hover={{ transform: 'scale(1.1)' }}
                  to={`/models/${id}/versions`}
                  mx={2}
                >
                  {t('View versions')}
                </Link>
              </Center>
            </Td>
            <Td>
              <Center whiteSpace="nowrap">
                <Tooltip
                  label={exportModelLabel}
                  placement="top"
                  hasArrow={true}
                >
                  <Link
                    _hover={{ transform: 'scale(1.1)' }}
                    to=""
                    mx={2}
                    onClick={async () => {
                      // const response = await exportONNXModel(model);
                      // if (!response.ok || !response.data) {
                      //   if (response.status === 400) {
                      //     showMessage({
                      //       type: 'error',
                      //       message: response.data
                      //         ? String(response.data.detail)
                      //         : 'Failed to duplicate model',
                      //     });
                      //   } else {
                      //     showMessage({
                      //       type: 'error',
                      //       message: t('Unexpected response status: {response}', {
                      //         response,
                      //       }),
                      //     });
                      //   }
                      // } else {
                      //   showMessage({
                      //     type: 'success',
                      //     message: t(`Successfully duplicated "{name}"!`, {
                      //       name,
                      //     }),
                      //   });
                      //   refetch();
                      // }
                    }}
                  >
                    <Icon as={Icons.CloudArrowDown} w={6} h={6} color={color} />
                  </Link>
                </Tooltip>
                <Tooltip
                  label={duplicateModelLabel}
                  placement="top"
                  hasArrow={true}
                >
                  <Link
                    _hover={{ transform: 'scale(1.1)' }}
                    to=""
                    mx={2}
                    onClick={async () => {
                      // const response = await duplicateONNXModel(model);
                      // if (!response.ok || !response.data) {
                      //   if (response.status === 400) {
                      //     showMessage({
                      //       type: 'error',
                      //       message: response.data
                      //         ? String(response.data.detail)
                      //         : 'Failed to duplicate model',
                      //     });
                      //   } else {
                      //     showMessage({
                      //       type: 'error',
                      //       message: t('Unexpected response status: {response}', {
                      //         response,
                      //       }),
                      //     });
                      //   }
                      // } else {
                      //   showMessage({
                      //     type: 'success',
                      //     message: t(`Successfully duplicated "{name}"!`, {
                      //       name,
                      //     }),
                      //   });
                      //   refetch();
                      // }
                    }}
                  >
                    <Icon as={Icons.Duplicate} w={6} h={6} color={color} />
                  </Link>
                </Tooltip>
                <Tooltip label={editModelLabel} placement="top" hasArrow={true}>
                  <Link
                    _hover={{ transform: 'scale(1.1)' }}
                    to=""
                    mx={2}
                    onClick={() => {
                      onEditModelClick(model);
                    }}
                  >
                    <Icon as={Icons.Pencil} w={6} h={6} color={color} />
                  </Link>
                </Tooltip>
                <Tooltip
                  label={deleteModelLabel}
                  placement="top"
                  hasArrow={true}
                >
                  <Link
                    _hover={{ transform: 'scale(1.1)' }}
                    to=""
                    mx={2}
                    onClick={async () => {
                      showConfirmationDialog({
                        title: t(`Delete "{name}"?`, {
                          name: model.name ?? 'unknown',
                        }),
                        body: t(
                          `Are you sure you would like to delete "{name}"? This cannot be recovered.`,
                          { name: model.name ?? 'unknown' },
                        ),
                        onConfirm: async () => {
                          await deleteONNXModel(model);
                          onSave();
                          showMessage({
                            type: 'success',
                            message: t(
                              'Your model will be deleted and removed from this list in a few moments',
                            ),
                          });
                        },
                      });
                    }}
                  >
                    <Icon as={Icons.Trash} w={6} h={6} color={color} />
                  </Link>
                </Tooltip>
              </Center>
            </Td>
          </Tr>
        );
      })}
    </Tbody>
  );
}

function ModelListTableCaption(props: {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pagesCount: number;
  pages: Array<number>;
}) {
  const { currentPage, setCurrentPage, pagesCount, pages } = props;
  return (
    <TableCaption>
      <VStack align="stretch">
        {pagesCount > 1 ? (
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer>
              <HStack flex={1} justify="flex-end">
                <PaginationPrevious>
                  <Icon as={Icons.ChevronLeft} w={5} h={5} color="gray.500" />
                </PaginationPrevious>
                <PaginationPageGroup>
                  {pages.map((page) => (
                    <PaginationPage
                      w={7}
                      key={`paginator_page_${page}`}
                      page={page}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext>
                  <Icon as={Icons.ChevronRight} w={5} h={5} color="gray.500" />
                </PaginationNext>
              </HStack>
            </PaginationContainer>
          </Pagination>
        ) : null}
      </VStack>
    </TableCaption>
  );
}
function ModelListTable(props: {
  models: Array<ServerONNXModel>;
  modelUsers: Map<string, ServerUser>;
  totalPages: number;
  onEditModelClick: (model: ServerONNXModel) => void;
  onSave: () => void;
}) {
  const { models, modelUsers, totalPages, onEditModelClick, onSave } = props;
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: totalPages,
    initialState: { currentPage: 1 },
  });

  return (
    <TableContainer>
      <Table variant="simple">
        <ModelListTableHeaders />
        <ModelListTableBody
          models={models}
          modelUsers={modelUsers}
          currentPage={currentPage}
          itemsPerPageLimit={itemsPerPageLimit}
          onEditModelClick={onEditModelClick}
          onSave={onSave}
        />
        <ModelListTableCaption
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesCount={pagesCount}
          pages={pages}
        />
      </Table>
    </TableContainer>
  );
}

export function ModelList() {
  const showMessage = useShowMessage();
  const { data, isLoading, error, refetch } = useFetchONNXModels();
  const [verifyTotalModelUploadsWithinBillingQuota] =
    useVerifyTotalModelUploadsWithinBillingQuota();

  const [newONNXModelFile, setNewONNXModelFile] = useState<Blob | null>(null);
  const [editModel, setEditModel] = useState<ServerONNXModel | null>(null);
  const [updateONNXModel] = useUpdateONNXModel();

  const bg = useColorModeValue('white', 'gray.600');
  const color = useColorModeValue('gray.500', 'gray.200');

  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner color="brand" size="xl" />
      </Center>
    );
  }

  if (error) {
    return <ErrorView error={error} />;
  }

  if (!data) {
    return <NotFound />;
  }

  const { models, modelUsers, pagesQuantity } = data;

  return (
    <MainLayout scrollable={true}>
      <VStack align="stretch" spacing={6}>
        <VStack
          bg={bg}
          shadow="sm"
          borderRadius="lg"
          py={5}
          spacing={5}
          align="stretch"
        >
          <HStack px={6}>
            <Heading fontWeight="medium" size="xs">
              {t('ONNX Models')}
            </Heading>
            <Spacer />
            <Button
              variant="ghost"
              onClick={async () => {
                refetch();
              }}
            >
              <Tooltip
                label={t('Refresh models')}
                placement="top"
                hasArrow={true}
              >
                <Icon as={Icons.ArrowPath} w={5} h={5} color={color} />
              </Tooltip>
            </Button>
            <InputGroup w={300}>
              <InputLeftElement pointerEvents="none">
                <Icon as={Icons.MagnifyingGlass} w={5} h={5} color={color} />
              </InputLeftElement>
              <Input type="search" placeholder="Search" />
            </InputGroup>
          </HStack>
          {models.length > 0 ? (
            <ModelListTable
              models={models}
              modelUsers={modelUsers}
              totalPages={pagesQuantity}
              onEditModelClick={(model: ServerONNXModel) => {
                setEditModel(model);
              }}
              onSave={() => {
                refetch();
              }}
            />
          ) : (
            <EmptyTableView
              primaryMsg="You currently do not have uploaded / tracked models"
              secondaryMsg="Upload your first model by clicking the button below"
            />
          )}
          <HStack paddingTop={4} px={6}>
            <Spacer />
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={async () => {
                const response =
                  await verifyTotalModelUploadsWithinBillingQuota();
                if (!response.ok || !response.data) {
                  if (response.status === 400) {
                    showMessage({
                      type: 'error',
                      message: response.data
                        ? String(response.data.detail)
                        : t('Unable to create new model'),
                    });
                  } else {
                    showMessage({
                      type: 'error',
                      message: t('Unexpected response status: {response}', {
                        response,
                      }),
                    });
                  }
                } else {
                  const initialONNXModelFileCreate = new Blob();
                  setNewONNXModelFile(initialONNXModelFileCreate);
                }
              }}
            >
              <HStack>
                <Icon as={Icons.Plus} w={4} h={4} />
                <Text>{t('New Model')}</Text>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      </VStack>
      <ModelEditDrawer
        model={editModel}
        onSave={async (model) => {
          const { id, name, description } = model;
          await updateONNXModel({
            id,
            updates: { name: name ?? '', description: description ?? '' },
          });
          setEditModel(null);
          refetch();
        }}
        onClose={() => setEditModel(null)}
      />
      <ModelNewModal
        newONNXModelFile={newONNXModelFile}
        onSave={() => {
          setNewONNXModelFile(null);
          refetch();
        }}
        onClose={() => setNewONNXModelFile(null)}
      />
    </MainLayout>
  );
}
