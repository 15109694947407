import {
  Button,
  Text,
  Th,
  Td,
  Tr,
  Table,
  Thead,
  Tbody,
  TableContainer,
  Tooltip,
  Spacer,
  Icon,
  Flex,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';

import type { ServerONNXAttributeProto } from '../../../types/Api';
import { Icons } from '../../../components/Icons';
import { useShowMessage } from '../../../components/useShowMessage';
import { GraphNodeAttributeTypeBadge } from '../../../components/Models/GraphNodeAttributeTypeBadge';
import { formatGraphNodeAttributeTypeVal } from '../../../support/models/formatGraphNodeAttributeTypeVal';
import {
  copiedToClipboardLabel,
  copyToClipboardLabel,
} from '../../../constants/clipboard';

export function GraphNodeAttributesTable(props: {
  attributes: Array<ServerONNXAttributeProto>;
}) {
  const { attributes } = props;
  const showMessage = useShowMessage();
  const renderAttributesList = (
    attributeList: Array<ServerONNXAttributeProto>,
  ) => {
    return attributeList.map((attribute) => {
      const { name } = attribute;

      const attributeValue = formatGraphNodeAttributeTypeVal(attribute);
      return (
        <Tr key={name}>
          <Td>
            <Flex>
              <Text as="i">{name}</Text>
              <Spacer />
              <Tooltip
                label={copyToClipboardLabel}
                placement="top"
                hasArrow={true}
              >
                <Button
                  variant="link"
                  onClick={() => {
                    copy(name);
                    showMessage({
                      type: 'success',
                      message: copiedToClipboardLabel,
                    });
                  }}
                >
                  <Icon
                    as={Icons.DocumentDuplicate}
                    w={4}
                    h={4}
                    color="gray.500"
                  />
                </Button>
              </Tooltip>
            </Flex>
          </Td>
          <Td textAlign="center">
            <GraphNodeAttributeTypeBadge attribute={attribute} />
          </Td>
          <Td>
            <Flex>
              <Text>{attributeValue}</Text>
              <Spacer />
              <Tooltip
                label={copyToClipboardLabel}
                placement="top"
                hasArrow={true}
              >
                <Button
                  variant="link"
                  onClick={() => {
                    copy(attributeValue ?? '');
                    showMessage({
                      type: 'success',
                      message: copiedToClipboardLabel,
                    });
                  }}
                >
                  <Icon
                    as={Icons.DocumentDuplicate}
                    w={4}
                    h={4}
                    color="gray.500"
                  />
                </Button>
              </Tooltip>
            </Flex>
          </Td>
        </Tr>
      );
    });
  };
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t('Name')}</Th>
            <Th textAlign="center">{t('Type')}</Th>
            <Th textAlign="center">{t('Value')}</Th>
          </Tr>
        </Thead>
        <Tbody>{renderAttributesList(attributes ?? [])}</Tbody>
      </Table>
    </TableContainer>
  );
}
